<template>
	<div>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-12">
	            <div class="card">
	                <div class="card-header pb-0">
	                    <div class="setting-list">
	                        <ul class="list-unstyled setting-option">
	                            <li>
	                                <div class="setting-primary"><i class="icon-settings"></i></div>
	                            </li>
	                            <li><i class="view-html fa fa-code font-primary"></i></li>
	                            <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
	                            <li><i class="icofont icofont-minus minimize-card font-primary"></i></li>
	                            <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
	                            <li><i class="icofont icofont-error close-card font-primary"></i></li>
	                        </ul>
	                    </div>
	                </div>
	                <div class="card-body">
	                    <div class="table-responsive">
	                    	<table class="table table-bordered">
	                    		<thead>
	                    			<tr>
	                    				<th>#</th>
	                    				<th>Nom</th>
	                    				<th>Type de service</th>
	                    				<th>Creation</th>
	                    				<th>...</th>
	                    			</tr>
	                    		</thead>
	                    		<tbody>
	                    			<tr v-for="(item, index) in services.data" :key="index">
	                    				<td>{{ item.id }}</td>
	                    				<td>{{ item.service_name }}</td>
	                    				<td>{{ item.service_type }}</td>
	                    				<td>{{ item.created_at | moment("from", "now")}}</td>
	                    				<td>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-service', item)" v-if="$can('edit-option')">
	                    						<feather type="edit" size="13"></feather>
	                    					</span>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteService(item)" v-if="$can('delete-option')">
	                    						<feather type="trash-2" size="13"></feather>
	                    					</span>
	                    				</td>
	                    			</tr>
	                    		</tbody>
	                    	</table>
	                    	
	                    </div>
	                    <div class="mt-3">
	                    	<pagination :data="services" @pagination-change-page="loadServices">
	                    		<span slot="prev-nav">&lt; Precedent</span>
								<span slot="next-nav">Suivant &gt;</span>
	                    	</pagination>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
		<modal name="edit-service" :width="1000" :height="630" :clickToClose="false">
			<div slot="top-right">
		      <button @click="closeModal('edit-service')">
		        ❌
		      </button>
		    </div>
	        <form @submit.prevent="UpdateService('edit-service')" class="p-4" style="overflow-y:auto;">
	        	<div class="row">
					<div class="col-sm-8">
			            <div class="card" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Nom du service</label>
			                    	<input v-model='service.service_name' class="form-control" type="text">
			                    	<HasError :form="service" field="service_name" />
			                    </div>
			                    <div class="form-group">
			                    	<label for="name">Description</label>
			                    	<ckeditor v-model="service.service_description" :config="editorConfig"></ckeditor>
			                    </div>
			            	</div>
			            </div>
			        </div>
			        <div class="col-sm-4">
			            <div class="card mb-3" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Type de service</label>
			                    	<select v-model="service.service_type" class="form-select digits" id="exampleFormControlSelect9">
		                              <option value="">Choisissez le type</option>
		                              <option v-for="(item, index) in types" :key="index" :value="item.key"> {{ item.value }} </option>
		                            </select>
		                            <HasError :form="service" field="service_type" />
			                    </div>
								<div class="form-group">
									<label for="name">image de la categorie</label>
									<picture-input 
										ref="pictureInput"
										width="400" 
										height="200" 
										margin="0" 
										accept="image/jpeg,image/jpg,image/png,image/webp" 
										size="10"
										z-index="0" 
										:prefill="getFilePath(service.service_image)"
										:prefill-options="{fileName: service.service_image, fileType:getFileType(service.service_image), mediaType: getMediaType(service.service_image)}"
										button-class="btn"
										:removable="true"
										:custom-strings="{
											upload: '<h1>Bummer!</h1>',
											drag: 'Selectionner une image',
											change: 'Changer',
											remove: 'Supprimer', 
										}"
										@change="onChange">
									</picture-input>
									<HasError :form="service" field="serviceImage" />
								</div>
			            	</div>
			            </div>
			        </div>
			        <div class="col-12">
			        	<div class="card border-0" style="border: 0px;">
			            	<div class="card-footer border-0 p-0 b-0 text-center">
			            		<button class="btn btn-secondary" type="button" @click="closeModal('edit-service')">Close</button>
	                			<button type="submit" :disabled="service.busy" class="btn btn-primary">Mettre a jours</button>
			            	</div>
			            </div>
			        </div>
				</div>
	        </form>
	    </modal>
    	<v-dialog />
	</div>
</template>
<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	import Permissions from '../mixins/permissions.vue';
	import PictureInput from 'vue-picture-input';

	export default {
		mixins: [Permissions],
		components:{
			Button, HasError, AlertError, PictureInput
		},
		data: function() {
			 return {
			 	services:[],
				basePath: Laravel.baseUrl+'medias/service_image/',
				service: new Form({
				 	id:'',
				 	service_name:'',
				 	service_type:'',
				 	service_description:'',
					service_image:'',
					imageEdited: false,
				}),
				types:[
					{key: 'assistance', value:'Assistance'},
					{key: 'distribution', value:'Distribution'},
					{key: 'livraison', value:'Livraison'},
					{key: 'geolocalisation', value:'Geolocalisation'},
				],

				editorConfig:{}

			 }
		},

		methods: {
			getFilePath: function(file) {
				console.log(file);
				return !this.service.imageEdited? this.basePath+file : file;
			},
			getFileType: function(file) {
				if(!this.service.imageEdited) {
					var array = file.split('.');
					return array[array.length-1];
				} else {
					var firstpart = file.split(';')[0];
					var mediaType = firstpart.split(':')[1];
					var ext = mediaType.split('/')[1];
					return ext;
				}
			},
			getMediaType: function(file) {
				// console.log(file.name)
				var ext = this.getFileType(file);
				return "image/"+ext;
			},
			onChange (image) {
				this.service.imageEdited = true;
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.')
                    this.service.service_image = image
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            },
			showModal: function(name, datas){
				this.service.reset();
				// datas? this.service.fill(datas): this.service.reset();
				if(datas) {
					this.service.id = datas.id;
					this.service.service_type = datas.service_type;
					this.service.service_name = datas.service_name;
					this.service.service_description = datas.service_description;
					
					if(datas.service_image != null) {
						this.service.service_image = datas.service_image;
					}
				}
				this.$modal.show(name);
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
			loadServices(page = 1) {
	            axios.get('/admin/services/list?page=' + page)
                .then(response => {
                    this.services = response.data;
                });
	        },
	        UpdateService: function(modal) {
            	let uri = "/admin/services/update/"+this.service.id;
				this.service.put(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.$modal.hide(modal):null;
					response.data.success? this.service.reset():null;
					
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
            },
			deleteService: function(item) {

				let uri = "/admin/services/delete";
				this.service.fill(item);

				this.$modal.show('dialog', {
				  title: "Suppression de service",
				  text: 'Êtes-vous sûr de bien vouloir supprimer ce service ?',
				  buttons: [
					    {
					      title: 'Non',
					      handler: () => {
					        this.$modal.hide('dialog')
					      }
					    },
					    {
					      title: 'Oui',
					      handler: () => {
					        this.service.delete(uri).then(response => {
					        	Fire.$emit('AfterAction');
					        	Toast.fire({
				                    icon: response.data.success? 'success':'error',
				                    title: response.data.message
				                });
				                this.$modal.hide('dialog'); 

					        });
					      }
					    }
				  	]
				});
			},
		},

		created: function() {
			this.loadServices();
			Fire.$on("AfterAction", () => {
				this.loadServices();
			});
		}
	}
</script>