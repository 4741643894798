<template>
	<div style="z-index: 0;">
		<slider animation="fade">
		  <slider-item
		    v-for="(i, index) in list"
		    :key="index"
		    :style="i"
		    @click="hello">
		    <p style="line-height: 280px; font-size: 5rem; text-align:;">Page{{ index + 1 }}
		    </p>
		    
		  </slider-item>
		</slider>
	</div>
</template>

<script>
	
	export default {

		data: function() {
			return {
		      list: [
		        { backgroundColor: 'none', width: '100%', height: '100%' },
		        { backgroundColor: 'none', width: '100%', height: '100%' },
		        { backgroundColor: 'none', width: '100%', height: '100%' },
		      ],
		    }
		},
		methods:{
			hello($event) {
		      console.log(`hello index: ${$event}`)
		    },
		}
	}
</script>