<template>
	<div>
		<div class="row starter-main">
			<div class="col-sm-4">
	            <div class="card">
	                <form @submit.prevent="createOptions">
	                	<div class="card-body" style="height: 350px; overflow-y: auto;">
	                		<div class="m-b-5">
	                			<select v-model="variationId" class="form-select digits" id="exampleFormControlSelect9">
	                              <option value="">Choisissez la variation</option>
	                              <option v-for="(item, index) in variations" :key="index" :value="item.id"> {{ item.variationName }} </option>
	                            </select>
	                		</div>
		                    <div class="table-responsive">
		                    	<table class="table table-bordered">
			                    	<thead>
			                    		<tr>
			                    			<th>Libelle de l'option</th>
			                    			<th class="text-center align-middle">
			                    				<span style="cursor: pointer;" class="addRow text-success" v-on:click="addRow"><feather type="plus-circle" size="16"></feather></span>
			                    			</th>
			                    		</tr>
			                    	</thead>
			                    	<tbody>
			                    		<tr v-for="(addTd, options) in addRows" :key="options">
			                    			<td>
			                    				<input v-model='addTd.name' class="form-control" type="text" placeholder="">
			                    			</td>
			                    			<td class="text-center align-middle">
			                    				<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteRow(options,addTd)"><feather type="x-circle" size="16"></feather></span>
			                    			</td>
			                    		</tr>
			                    	</tbody>
		                    	</table>
		                    </div>
		                </div>
		                <div class="card-footer p-3 bg-light text-center" v-if="$can('create-option')">
		                	<button class="btn btn-outline-primary" type="submit">Enregistrer les options</button>
		                </div>
	                </form>
	            </div>
	        </div>
	        <div class="col-sm-8">
	            <div class="card">
	                <div class="card-header pb-0">
	                    <div class="setting-list">
	                        <ul class="list-unstyled setting-option">
	                            <li>
	                                <div class="setting-primary"><i class="icon-settings"></i></div>
	                            </li>
	                            <li><i class="view-html fa fa-code font-primary"></i></li>
	                            <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
	                            <li><i class="icofont icofont-minus minimize-card font-primary"></i></li>
	                            <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
	                            <li><i class="icofont icofont-error close-card font-primary"></i></li>
	                        </ul>
	                    </div>
	                </div>
	                <div class="card-body">
	                    <div class="table-responsive">
	                    	<table class="table table-bordered">
	                    		<thead>
	                    			<tr>
	                    				<th>#</th>
	                    				<th>Nom</th>
	                    				<th>Variation</th>
	                    				<th>Date Creation</th>
	                    				<th>...</th>
	                    			</tr>
	                    		</thead>
	                    		<tbody>
	                    			<tr v-for="(item, index) in options.data" :key="index">
	                    				<td>{{ item.id }}</td>
	                    				<td>{{ item.variationOptionName }}</td>
	                    				<td>{{ item.variation.variationName }}</td>
	                    				<td>{{ item.created_at | moment("from", "now")}}</td>
	                    				<td>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-option', item)" v-if="$can('edit-option')">
	                    						<feather type="edit" size="13"></feather>
	                    					</span>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteOption(item)" v-if="$can('delete-option')">
	                    						<feather type="trash-2" size="13"></feather>
	                    					</span>
	                    				</td>
	                    			</tr>
	                    		</tbody>
	                    	</table>
	                    	
	                    </div>
	                    <div class="mt-3">
	                    	<pagination :data="options" @pagination-change-page="getResults">
	                    		<span slot="prev-nav">&lt; Precedent</span>
								<span slot="next-nav">Suivant &gt;</span>
	                    	</pagination>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
		<modal name="edit-option" :width="400" :height="250" :adaptive="true">
	        <form @submit.prevent="UpdateOption('edit-option')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<label for="name">Variation</label>
	        				<select v-model="option.variation_id" class="form-select digits" id="exampleFormControlSelect9">
                              <option value="">Choisissez la variation</option>
                              <option v-for="item in variations" :key="item.id" :value="item.id"> {{ item.variationName }} </option>
                            </select>
                            <HasError :form="option" field="variation_id" />
	        			</div>
	        			<div>
	        				<label for="name">Libelle de l'option</label>
	        				<input type="text" v-model="option.variationOptionName" id="name" class="form-control" name="variationOptionName">
	        				<HasError :form="option" field="variationOptionName" />
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-option')">Close</button>
		                <button type="submit" :disabled="option.busy" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <v-dialog />
	</div>
</template>
<script>
import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
import Permissions from '../mixins/permissions.vue';

	export default {
		mixins: [Permissions],
		components:{
			Button, HasError, AlertError
		},
		data(){
			return {
				addRows:[],
				options:{},
				variations:[],
				variationId:'',
				option: new Form({
					id:'',
					variation_id:'',
					variationOptionName:'',
				}),
				total:1,
				pageInfo:null
			}
		},

		methods:{

			addRow() {
		        this.addRows.push({
		            name:null,
		        });
	        },

	        deleteRow(index, addTd) {
	            var idx = this.addRows.indexOf(addTd);
	            if (idx > -1) {
	                this.addRows.splice(idx, 1);
	            }
	        },

	        showModal: function(name, datas){

				datas? this.option.fill(datas): this.option.reset();
				this.$modal.show(name);
			},

			closeModal: function(name){
				this.$modal.hide(name);
			},

	        createOptions: function() {
	        	if(this.variationId != '') {
	        		let uri = "/admin/attributs/variations/options/store";
					var addRows = _.map(this.addRows, function (num) { return _.pick(num,'name')});
					axios.post(uri, {options:addRows, variation_id: this.variationId}).then(response => {
						Fire.$emit('AfterAction');
						response.data.success? this.addRows = [] :null;
						response.data.success? this.variationId = '' :null;
						
						Toast.fire({
		                    icon: response.data.success? 'success':'error',
		                    title: response.data.message
		                 });
					});
	        	}

	        	Toast.fire({
	                icon: 'warning',
	                title: 'Vous devez choisir une variation!'
	            });

			},

			UpdateOption: function(modal) {
				let uri = "/admin/attributs/variations/options/update";
				this.option.put(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.$modal.hide(modal):null;
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
			},

			deleteOption: function(item) {

				let uri = "/admin/attributs/variations/options/delete";
				this.option.fill(item);

				this.$modal.show('dialog', {
				  title: "Suppression d'option de variation",
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette option ?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        this.option.delete(uri).then(response => {
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},

			loadOptions: function() {
				let uri = '/admin/attributs/variations/options/options';
				axios.get(uri).then(response => {
					this.options = response.data;
				});
			},
			loadVariations: function() {
				let uri = '/admin/attributs/variations/variations';
				axios.get(uri).then(response => {
					this.variations = response.data;
				});
			},

			getResults(page = 1) {
	            axios.get('/admin/attributs/variations/options/paginated?page=' + page)
                .then(response => {
                    this.options = response.data;
                    //this.pageInfo = response.data;
                });
	        }
		},

		// mounted(){
		// 	this.getResults();
		// },

		created(){
			this.getResults(); 
			this.loadVariations();
			Fire.$on("AfterAction", () => {
				//console.log('action happened!');
				this.getResults();
			});
		}
	}
</script>