<template>
    <div class="center">
      <vs-dialog overflow-hidden width="550px" not-center v-model="active">
        <template #header>
          <h4 class="not-margin">
            Alerte <b>Information</b>
          </h4>
        </template>
        <div class="con-content">
          <p>
            {{ message }}
          </p>
          <vs-button @click="active2=!active2" v-if="button">
            Laisser un mot
          </vs-button>
        </div>
        <template #footer>
          <div class="con-footer">
            <vs-button @click="active=false" transparent>
              Ok
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <vs-dialog v-model="active2">
        <template #header>
          <h4 class="not-margin">
            Welcome to <b>Vuesax</b>
          </h4>
        </template>


        <div class="con-form">
          <vs-input v-model="input1" placeholder="Email">
            <template #icon>
              @
            </template>
          </vs-input>
          <vs-input type="password" v-model="input2" placeholder="Password">
            <template #icon>
              <i class='bx bxs-lock'></i>
            </template>
          </vs-input>
          <div class="flex">
            <vs-checkbox v-model="checkbox1">Remember me</vs-checkbox>
            <a href="#">Forgot Password?</a>
          </div>
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button block>
              Sign In
            </vs-button>

            <div class="new">
              New Here? <a href="#">Create New Account</a>
            </div>
          </div>
        </template>
      </vs-dialog>
    </div>
  </template>
  <script>
    
    export default {
      props: {
       message: String,
       active: Boolean,
       button: Boolean, 
      },
      data: function () {
        
        return {
          active2: false,
        };
      },
      methods: {
        methodName () {
          
        }
      },

    }
  </script>