<template>
	<div>
		<div class="row starter-main">
			<form @submit.prevent="createService">
				<div class="row">
					<div class="col-sm-8">
			            <div class="card" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Nom du service</label>
			                    	<input v-model='form.service_name' class="form-control" type="text">
			                    	<HasError :form="form" field="service_name" />
			                    </div>
			                    <div class="form-group">
			                    	<label for="name">Description</label>
			                    	<ckeditor v-model="form.service_description" :config="editorConfig"></ckeditor>
			                    </div>
			            	</div>
			            </div>
			        </div>
			        <div class="col-sm-4">
			            <div class="card mb-3" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Type de service</label>
			                    	<select v-model="form.service_type" class="form-select digits" id="exampleFormControlSelect9">
		                              <option value="">Choisissez le type</option>
		                              <option v-for="(item, index) in types" :key="index" :value="item.key"> {{ item.value }} </option>
		                            </select>
		                            <HasError form="form" field="service_type" />
			                    </div>
								<div class="form-group">
									<label for="name">Image illustrative du service</label>
									<picture-input 
										ref="pictureInput"
										width="500" 
										height="200" 
										:margin="0" 
										accept="image/jpeg,image/jpg,image/png,image/webp" 
										:size="2"
										:z-index="0" 
										button-class="btn"
										:removable="true"
										:custom-strings="{
											upload: '<h1>Bummer!</h1>',
											drag: 'Selectionner une image',
											change: 'Changer',
											remove: 'Supprimer', 
										}"
										@change="onChange">
									</picture-input>
									<HasError :form="category" field="categoryImage" />
								</div>
			            	</div>
			            </div>
			            <div class="card border-0" style="border-radius: 10px; background-color:transparent;">
			            	<div class="card-footer border-0 p-0 b-0 text-center">
			            		<button class="btn btn-primary" type="submit">Ajouter le service</button>
			            	</div>
			            </div>
			        </div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
	
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	import PictureInput from 'vue-picture-input';

	export default {
		components:{
			Button, HasError, AlertError,PictureInput
		},
		data: function() {
			return {
				basePath: Laravel.baseUrl+'medias/service_image/',
				form: new Form({
					service_name: '',
					service_type:'',
					service_description:'',
					service_image:''
				}),

				types:[
					{key: 'assistance', value:'Assistance'},
					{key: 'distribution', value:'Distribution'},
					{key: 'livraison', value:'Livraison'},
					{key: 'geolocalisation', value:'Geolocalisation'},
				],

				editorConfig:{}
			}
		},
		methods: {
			onChange (image) {
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.')
                    this.form.service_image = image
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            },
		  createService: function() {

		  	let uri = "/admin/services/store";

		  	this.form.post(uri).then(response => {
		  		response.data.success? this.form.reset():null;
					
				Toast.fire({
                    icon: response.data.success? 'success':'error',
                    title: response.data.message
                 });
		  	});

		  }
		},
		created: function() {

		}
	}
</script>