<template>
    <div class="row">
        <div class="col-xl-2 col-md-2 col-lg-2 col-sm-6 col-6" v-for="(Product, index) in Products.data" :key="index">
            <div class="prooduct-details-box bg-white">                                 
                <div class="media">
                    <div class="ribbon ribbon-clip ribbon-primary" v-if="showParter"><span style="font-size: 10px;">Ribbon</span></div>
                    <a href="#">
                        <img class="align-self-center w-100 img-fluid" :src="baseUrl+'medias/partner_product_image/frontsize/200x130/'+Product.partner_product_image" alt="#" style="border-top-left-radius: 5px; border-top-right-radius: 5px;">
                    </a>
                    <div class="media-body">
                        <div class="product-name">
                            <a href="#">
                                <h6 style="overflow:hidden; white-space: nowrap; text-overflow: ellipsis;">{{ Product.product }}</h6>
                            </a>
                        </div>
                        <!-- <ul class="rating">
                            <li><i class="fa fa-star"></i></li>
                            <li><i class="fa fa-star"></i></li>
                            <li><i class="fa fa-star"></i></li>
                            <li><i class="fa fa-star"></i></li>
                            <li><i class="fa fa-star"></i></li>
                        </ul> -->
                        <div class="price"><span>{{ Product.subcategory }}</span></div>
                        <div class="avaiabilty mt-3">
                            <div class="text-warning f-w-600">{{ Product.price }} F</div>
                        </div>
                        <button class="btn btn-primary btn-xs mt-3 d-block d-sm-block d-md-none d-lg-none d-xl-none"  @click="checkProductBeforeAddToCart(Product)"><i class="fa fa-shopping-basket"></i></button>
                        <button class="btn btn-primary btn-xs mt-3 d-none d-sm-none d-md-block d-lg-block d-xl-block"  @click="checkProductBeforeAddToCart(Product)">AJouter <i class="fa fa-shopping-basket"></i></button>

                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <pagination :data="Products" @pagination-change-page="!byCategory? loadProducts : loadCategoryProducts">
                <span slot="prev-nav">&lt; Precedent</span>
                <span slot="next-nav">Suivant &gt;</span>
            </pagination>
        </div>
    </div>
</template>

<script>
    import EventBus from './EventBus.js';
    import LoadCart from '../loadCart.js';
	import ProductMethods from '../../../mixins/productMethods.js';

    export default {
        mixins:[
            LoadCart, 
            ProductMethods
        ],
        props: {
            detail: Object,
            category: {
                type: Object,
                default: []
            },
            byCategory: {
                type: Boolean,
                default: false
            },
            showParter: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                baseUrl:Laravel.baseUrl,
                Products:[],
            }
        },
        methods: {
            showAlert: function(text) {
                alert(text);
            },

            addToCart: function(product) {
                
                this.pushToCart(product);
                let uri = "/partenaires/products/add-to-cart";
                axios.post(uri, {cart:this.carts}).then(response => {
                
                //this.carts = response.data.cart;

                EventBus.emit('new-item-added-to-cart');
                
                var content  = {};
                content.title = "";
                content.message = '<i class="fa fa-bell-o"></i><strong>Information </strong>'+response.data.message+'.';
                
                $("#exampleModalCenter3").modal('hide');

                this.notify(content,null, {from:'top', align:'right'}, {x:30, y:30}, 1000, 'success');
                });
            },
            pushToCart: function(product) {

                var have_variation = product.have_variation;
                this.carts.push({
                partner_product_id: product.id,
                account_id: product.account_id,
                product_id:product.product_id,
                category_id: product.category_id,
                sub_category_id: product.sub_category_id,
                product: product.product,
                slug: product.slug,
                product_type: product.product_type,
                category: product.category,
                subcategory: product.subcategory,
                price: product.price,
                partner_product_image: product.partner_product_image,
                have_variation: have_variation,
                is_under_prescription: product.is_under_prescription,
                product_combination_id: (have_variation)? product.product_combination_id: null,
                combinationName: (have_variation)? product.combinationName: null,
                combinationCode: (have_variation)? product.combinationCode:null,
                combinantionPrice: (have_variation)? product.combinantionPrice:null,
                combination_image:(have_variation)? product.combination_image:null,
                total_quantity: product.total_quantity,
                quantity: product.quantity,
                });
            },
            checkCartContentBeforeAdding: function(product) {
                let exist = false;
                Object.keys(this.carts).forEach(key => {
                if(product.have_variation) {
                    if(this.carts[key].partner_product_id == product.id && this.carts[key].combinationCode == product.combinationCode) {
                    exist = true;
                    }
                } else {
                    if(this.carts[key].partner_product_id == product.id) {
                    exist = true;
                    }
                }
                });

                return exist;
            },
            checkProductBeforeAddToCart: function(product) {
                Vue.set(product, 'quantity', 1);
                if(!this.checkCartContentBeforeAdding(product)){
                
                this.addToCart(product);
                } else {
                console.log(this.checkCartContentBeforeAdding(product));
                var content = {};
                content.title = "";
                content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> Ce produit existe deja dans votre panier. Modifiez sa quantite <a href="/carts/panier-d-achat">ici</a>';

                this.notify(content, 10000, null, null, 1051, 'secondary');
                }
            }
        },
        created: function() {
            this.loadProducts();
        },
        mounted: function() {
        }
    }
</script>