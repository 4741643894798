<template>
	<div>
		<div class="notification-box"><feather type="bell"></feather><span class="dot-animated">{{ notifications.length }}</span></div>
		<ul class="notification-dropdown onhover-show-div">
            <li>
              <p class="f-w-700 mb-0">Vous avez {{ notifications.length }} Notification(s)<span class="pull-right badge badge-primary badge-pill">4</span></p>
            </li>
            <li class="noti-primary" v-for="(item, index) in notifications" :key="index">
              <div class="media">
                <span class="notification-bg bg-light-primary">
                	<!-- <i data-feather="activity"> </i> -->
                	<feather type="activity" size="20"></feather>
                </span>
                <div class="media-body">
                  <p>{{ getTitle(item.data) }} </p>
                  <span>{{ item.created_at | moment("from", "now")}}</span>
                </div>
              </div>
            </li>
        </ul>
	</div>
</template>
<script>
	
	import EventBus from './fronts/details/EventBus.js';
	//import Notify from '../mixins/notification.js';

	export default {
		//mixins: [Notify],
		data: function () {
			return {

				notifications:[],
				//lastItem:{},
			}
		},
		computed: {
		  lastItem () {
		    return (this.notifications.length > 0)? this.notifications.slice(-1):[];
		  }
		},
		methods: {
		  loadNotifications: function () {
		    let uri = "/admin/accounts/notifications";
		    axios.get(uri).then(response => {
		    	this.notifications = response.data;
		    	//this.lastItem = this.notifications.pop();
		    });
		  },
		  getTitle: function(data) {
		  	const obj = null;
		  	if(data) {
		  		obj = JSON.parse(data);
		  		return obj.title;
		  	} else {
		  		return null;
		  	}
		  },
		},
		created: function() {
			this.loadNotifications();
			//this.openNotification('top-right', 'primary');
		},
		mounted: function() {

			// let socket = io(`http://localhost:3000`);

			// socket.on(`new-request-submitted:App\\Events\\RequestSubmitted`, data => {
			// 	console.log(data.data);
			// });
		}
	}
</script>