<template>
	<div>
		<div class="">
			<div class="card b-r-5">
				<div class="card-header pb-0 align-middle b-b-light">
					<div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<!-- <label for="">Pages</label> -->
								<select v-model="filter.per_page" class="form-select digits form-control-lg" @change="loadOrders">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
						<div class="col-sm-12 col-lg-1 col-md-1"></div>
						<div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<!-- <label for="name">Points de vente concerne</label> -->
		                    	<multiselect v-model="filter.account_id" :options="partners" :hide-selected="false" placeholder="Points de vente" label="name" track-by="id" @input="loadOrders"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-2 col-md-2">
							<div class="form-group">
		                    	<!-- <label for="name">Etat Commande</label> -->
		                    	<multiselect v-model="filter.order_state" :options="oStates" :hide-selected="false" placeholder="Etats" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-2 col-md-2">
							<div class="form-group">
		                    	<!-- <label for="name">Statut Commande</label> -->
		                    	<multiselect v-model="filter.order_status" :options="oStatus" :hide-selected="false" placeholder="Statuts" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<!-- <label for="name">Moyen de paiement</label> -->
		                    	<multiselect v-model="filter.payment_method" :options="pMethods" :hide-selected="false" placeholder="Moyens de paiement" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table class="table">
							<thead class="text-dark thead-light f-w-400 f-10">
								<tr>
									<th>#</th>
									<th>CODE</th>
									<th>CLIENT</th>
									<th>MONTANT</th>
									<th>MOYEN DE PAIEMENT</th>
									<th>ETAT</th>
									<th>STATUT</th>
									<th>DATE</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(order, index) in orders.data" :key="index" class="p-5">
									<td>{{ index+1 }}</td>
									<td class="align-middle text-primary">{{ order.order_code }}</td>
									<td class="align-middle">{{ order.name }} {{ order.last_name }}</td>
									<td class="align-middle text-muted">{{ formatTHIS(order.order_amount, 0) }} F</td>
									<td class="align-middle" v-html="checkMethodName(order.payment_method)"></td>
									<td class="align-middle" v-html="checkStateName(order.order_state)" @click="editState(order, 'update-state')"></td>
									<td class="align-middle" v-html="checkStatusName(order.order_status)" @click="editStatus(order, 'update-status')"></td>
									<td class="align-middle">{{ order.created_at | moment("LLL") }}</td>
									<td class="align-middle">
										<span style="cursor: pointer;" class="m-r-1" @click="showModal('#order-item', order)">
											<feather type="shopping-cart" size="13" title="Detail de la commande"></feather>
										</span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
                    						<feather class="text-danger" type="trash-2" size="13" title="Supprimer la commande"></feather>
                    					</span>
										<span style="cursor: pointer;" class="m-l-1" v-if="!order.deliveryboy_assigned && (order.order_state=='paid' || order.payment_method =='COD')" @click="assigning('assign-delivery-boy', order)">
											<feather type="user-plus" size="13" title="Attribuer un livreur"></feather>
										</span>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="9" class="border-0">
										<div class="mt-3">
											<pagination :data="orders" @pagination-change-page="loadOrders">
												<span slot="prev-nav">&lt; Precedent</span>
												<span slot="next-nav">Suivant &gt;</span>
											</pagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="order-item" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Panier d'achat</h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
              </div>
              <div class="modal-body">
                <p>
                	<table class="table table-bordered table-striped">
                		<thead>
                			<tr>
                				<th>#</th>
                				<th>Produit</th>
                				<th>Quantite</th>
                				<th>Prix unitaire</th>
                				<th>Montant total</th>
                			</tr>
                		</thead>
                		<tbody>
                			<tr v-for="(item, index) in orderItems" :key="index">
                				<td>{{ item.id }}</td>
                				<td>{{ item.name }}</td>
                				<td>{{ item.quantity }}</td>
                				<td>{{ item.unit_price }} FCFA</td>
                				<td>{{ item.quantity * item.unit_price }} FCFA</td>
                			</tr>
                		</tbody>
                	</table>
                </p>
              </div>
              <div class="modal-footer justify-content-between bg-primary">
                <span class="f-w-900">Code: <span class="f-w-900">{{ order.order_code }}</span></span>
                <span class="f-w-900">Etat: <span v-html="checkStateName(order.order_state)" class="badge badge-light"></span></span>
                <span class="f-w-900">Statut: <span v-html="checkStatusName(order.order_status)" class="badge badge-light"></span></span>
                <span class="f-w-900">Montant Total: <span class="f-w-900">{{ order.order_amount }} FCFA</span></span>
              </div>
            </div>
          </div>
        </div>
        <modal name="update-state" :width="400" :height="200" :adaptive="true">
	        <form @submit.prevent="UpdateState('update-state')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<div class="">
	        					<label for="currentstate">Etat actuel: </label>
	        					<span id="currentstate" class="badge rounded-pill badge-light" v-html="currentstate"></span>
	        				</div>

	        				<div class="mt-1">
	        					<label for="state"></label>
	        					<multiselect v-model="newState" :options="oStates" :hide-selected="false" placeholder="Tous les etats" label="value" track-by="key"></multiselect>
	        				</div>
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('update-state')">Close</button>
		                	<button type="submit" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <modal name="update-status" :width="400" :height="220" :adaptive="true">
	        <form @submit.prevent="UpdateStatus('update-status')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<div class="">
	        					<label for="currentstatus">Statut actuel: </label>
	        					<span id="currentstatus" class="badge rounded-pill badge-light" v-html="currentstatus"></span>
	        				</div>

	        				<div class="mt-1">
	        					<label for="status"></label>
	        					<multiselect v-model="newStatus" :options="oStatus" :hide-selected="false" placeholder="Tous les statuts" label="value" track-by="key"></multiselect>
	        				</div>
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('update-status')">Close</button>
		                	<button type="submit" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
		<modal name="assign-delivery-boy" :width="400" :height="200" :adaptive="true">
	        <form @submit.prevent="AssignDeliveryBoy('assign-delivery-boy')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<div class="mt-1" v-if="delivers.length !== 0">
	        					<label for="deliver">Choisissez le livreur</label>
	        					<multiselect v-model="deliver" :options="delivers" :hide-selected="false" placeholder="Tous les livreurs" label="name" track-by="id"></multiselect>
	        				</div>
							<div v-else>
								<vs-alert :color="'#ff0000'" shadow style="z-index:0;">
									Il n'ya pas de livreur libre.
								</vs-alert>	 
							</div>
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('assign-delivery-boy')">Close</button>
		                	<button type="submit" class="btn btn-primary" :disabled="isDisabled">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	</div>
</template>
<script>

	import axios from 'axios';
	import Form from 'vform';
	import OrderMethods from '../mixins/orderMethods.js';
	import FormatTHIS from '../mixins/formatter.js';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	export default {
		mixins: [OrderMethods, FormatTHIS],
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				filter: new Form({
					account_id:'',
					order_state:'',
					order_status:'',
					payment_method: '',
					per_page:10
				}),
				oStates:[
					{key: 'paid', value: 'Payee'},
					{key: 'unpaid', value: 'Impayee'},
				],
				oStatus:[
					{key: 'pending', value: 'En attente'},
					{key: 'ongoing', value: 'En cours'},
					{key: 'closed', value: 'Termine'},
				],
				pMethods:[
					{key: 'OM', value: 'Orange Money'},
					{key: 'MM', value: 'Moov Money'},
					{key: 'COD', value: 'Paiement a la livraison'},
				],
				pages: [1,5,10,20,30,40,50,60,70,80,90,100],
				partners:[],
				orders:[],
				orderItems:[],
				order:[],
				currentstate:'',
				newState:'',
				currentstatus:'',
				newStatus:'',
				color: '#168EEA',
				delivers:[],
				deliver:[],
			}
		},
		computed:{
			isDisabled: function(){
				return this.delivers.length === 0;
			}
		},
		methods: {
			assigning: function(modal, data) {
				this.order = data;
				this.loadDeliveryboys();
				this.$modal.show(modal);
			},
			editState: function(data, modal) {
				this.order = data;
				this.currentstate = this.checkStateName(data.order_state);
				this.$modal.show(modal);
			},
			editStatus: function(data, modal) {
				this.order = data;
				this.currentstatus = this.checkStatusName(data.order_status);
				this.$modal.show(modal);
			},
			AssignDeliveryBoy: function(modal) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Assignation en cours...'});
				let uri = "/admin/orders/assign-deveryboy";
				axios.post(uri, {order: this.order, deliver: this.deliver.id}).then(response => {
					loading.close();
					Fire.$emit('AfterAction');

					(response.data.success)? this.order = []:null;
					(response.data.success)? this.deliver = []:null;
					(response.data.success)? this.closeModal(modal):null;

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });

				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			UpdateState: function(modalname) {
				let uri = "/admin/orders/update-state";
				axios.post(uri, {order: this.order.id, state: this.newState}).then(response => {
					Fire.$emit('AfterAction');

					(response.data.success)? this.currentstate = null:null;
					(response.data.success)? this.order = []:null;
					(response.data.success)? this.newState = null:null;
					(response.data.success)? this.closeModal(modalname):null;

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });

				}).catch(err => {
					console.log(err);
				});
			},
			UpdateStatus: function(modalname) {
				let uri = "/admin/orders/update-status";
				axios.post(uri, {order: this.order.id, status: this.newStatus}).then(response => {
					Fire.$emit('AfterAction');

					(response.data.success)? this.currentstatus = null:null;
					(response.data.success)? this.order = []:null;
					(response.data.success)? this.newStatus = null:null;
					(response.data.success)? this.closeModal(modalname):null;

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });

				}).catch(err => {
					console.log(err);
				});
			},
			closeModal: function(name) {
				this.$modal.hide(name);
			},
			showModal: function(name, order) {
				this.loadOrderItems(order);
				this.order = order;
				$(name).modal('show');
			},
			loadOrders: function(page = 1) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement'});
				let uri = "/admin/orders/orders-filtered?page="+page;

				this.filter.post(uri).then(response => {
					loading.close();
					this.orders = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadPartners: function() {
				let uri = "/admin/partners/activated-sellpoints-partners";

				axios.get(uri).then(response => {
					this.partners = response.data;
				}).catch(err => {
					console.log(err);
				});
			},
			loadOrderItems: function(order) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement du panier'});
				let uri ="/admin/orders/order-items/"+order.order_code;

				axios.get(uri).then(response => {
					loading.close();
					this.orderItems = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadDeliveryboys: function() {
				let uri = "/admin/accounts/free-delivery-boys";
				axios.get(uri).then(response => {
					this.delivers = response.data;
				}).catch(err => {
					console.log(err);
				});
			}
		},
		created: function() {
			this.loadOrders();
			this.loadPartners();

			Fire.$on('AfterAction', () => {
				this.loadOrders();
			});
		},
		mounted(){
			console.log('test');
		}
	}
</script>
<style>
	/* .cursor-pointer{
		cursor:pointer;
	}
	.allogo-badge{	
		padding-top: 1.5%;
		padding-bottom: 1.5%;
		padding-left: 4%;
		padding-right: 4%;
		border-radius: 3px;
		font-size: 10px;	
	}
	.allogo-badge.primary {
		background-color: aliceblue;
		color: dodgerblue;
	}

	.allogo-badge.secondary {
		background-color: azure;
		color: #0066B3;
	}

	.allogo-badge.warning {
		background-color: blanchedalmond;
		color: orange;
	}

	.allogo-badge.danger {
		background-color: snow;
		color: orangered;
	}

	.allogo-badge.ombadge {
		background-color: rgb(255, 246, 229);
		color: orange;
	}
	.allogo-badge.mmbadge {
		background-color: #d4edff;
		color: #0066B3;
		/* color: #F36F21; */
	/*} */
</style>