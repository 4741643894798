<template>
	<div>
		<div class="card shadow shadow-showcase bg-white b-r-10">
			<div class="card-body pl-1 pr-2 pt-5 pb-5">
				<form @submit.prevent="launchSearch">
					<div class="row">
						<div class="col-sm-12 col-lg-2 col-xl-2 col-12 text-center">
							<label class="typo__label text-secondary">Type de produit</label>
							<multiselect v-model="form.product_type" :options="types" :hide-selected="false"  placeholder="Type" class="text-primary text-center" @input="loadCategories" label="value" track-by="name"></multiselect>
						</div>
						<div class="col-sm-12 col-lg-3 col-xl-3 col-12 text-center">
							<label class="typo__label text-secondary"> Categories de produit </label>
							<multiselect v-model="form.category" :options="categories" :hide-selected="false"  placeholder="Categories de produit" label="name" track-by="id" @input="loadSubCategories" class="text-primary text-center"></multiselect>
						</div>
						<div class="col-sm-12 col-lg-3 col-xl-3 col-12 text-center">
							<label class="typo__label text-secondary">Sous categories</label>
							<multiselect v-model="form.sub_category" :options="subcategories" :hide-selected="false"  placeholder="Sous categories" @input="setValues" label="name" track-by="id" class="text-primary text-center"></multiselect>
						</div>
						<div class="col-sm-12 col-lg-4 col-xl-4 col-12 text-center">
							<label class="typo__label text-secondary">Votre recherche</label>
							<input type="text" v-model="form.search_text" class="form-control form-control-lg btn-pill text-center text-warning" @change="setValues" placeholder="Entrer votre recherche">	
						</div>
						<div class="col-sm-12 col-lg-12 col-xl-12 text-center">
							<button type="submit" class="mt-3 btn btn-pill btn-outline-light btn-air-light btn-sm txt-dark" :disabled="isDisabled"> <feather type="search" size="16"></feather> Rechercher</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
	
	import Placement from '../placement.js';

	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	export default {
		mixins: [Placement],
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				value: '',
				form: new Form({
					product_type:'',
					category:'',
					sub_category:'',
					search_text:''
				}),
				types: [
					{name: 'restaurant', value: 'Restaurants'},
					{name: 'pharmacy', value: 'Phamacies'},
					{name: 'supermarket', value: 'Supermarches'},
				],
				categories:[],
				subcategories:[],
				result:[],
				color: '#168EEA',
			}
		},
		computed: {
		  isDisabled: function () {
		    return (this.form.product_type !== '')? false:true;
		  }
		},
		methods: {
			setValues: function() {
				$("#search-form #type").val(this.form.product_type.name);
				$("#search-form #category").val(this.form.category.id);
				$("#search-form #subcategory").val(this.form.sub_category.id);
				$("#search-form #search").val(this.form.search_text);
			},
			launchSearch: function() {
				document.getElementById('search-form').submit();
				// let uri = "/api/search";
				// this.form.post(uri).then(response => {
				// });
			},
			loadCategories: function() {
				this.setValues();
				let uri = "/api/product-categories";
				this.form.post(uri).then(({data}) => {
					this.categories = data;
				});
			},
			loadSubCategories: function() {
				this.setValues();
				let uri = "/api/product-sub-categories";
				this.form.post(uri).then(({data}) => {
					this.subcategories = data;
				});
			}
		},
		created: function() {

		},
		mounted: function(){}
	}
</script>
<style>
	.multiselect__option--highlight {
	  background: #168eea;
	  outline: none;
	  color: #fff;
	}
	.multiselect__option--highlight::after {
	  display:none;
	  content: attr(data-select);
	  background: #41b883;
	  color: #fff;
	}
	.multiselect, .multiselect__input, .multiselect__single {
	  font-family: inherit;
	  font-size: 13px;
	  touch-action: manipulation;
	}
</style>