<template>
    <div>
        <div class="row">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Points de vente concerne</label>
		                    	<!-- <multiselect v-model="filter.account_id" :options="partners" :hide-selected="false" placeholder="Tous les points" label="name" track-by="id" @input="loadOrders"></multiselect> -->
		                    </div>
						</div>
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Etat Commande</label>
		                    	<!-- <multiselect v-model="filter.order_state" :options="oStates" :hide-selected="false" placeholder="Tous les etats" label="value" track-by="key" @input="loadOrders"></multiselect> -->
		                    </div>
						</div>
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Statut Commande</label>
		                    	<multiselect v-model="filter.order_status" :options="oStatus" :hide-selected="false" placeholder="Tous les statuts" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Moyen de paiement</label>
		                    	<!-- <multiselect v-model="filter.payment_method" :options="pMethods" :hide-selected="false" placeholder="Tous les moyens" label="value" track-by="key" @input="loadOrders"></multiselect> -->
		                    </div>
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<select v-model="filter.per_page" class="form-select digits" @change="loadDeliveries">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
						<div class=""></div>
					</div>
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Client</th>
                                    <th>Livreur</th>
									<th>Montant</th>
									<th>Moyen de paiement</th>
									<th>Etat</th>
									<th>Statut</th>
									<th>Date</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(delivery, index) in deliveries.data" :key="index">
									<td class="align-middle f-w-900 bg-light">{{ delivery.order_code }}</td>
									<td class="align-middle">
                                        {{ delivery.cusfname }} {{ delivery.cuslname }} <br> <b>{{ delivery.cusnumber }}</b>
                                    </td>
                                    <td class="align-middle">
                                        {{ delivery.deliver }} <br> {{ delivery.delivernumber }}
                                    </td>
									<td class="align-middle bg-light f-w-900">{{ delivery.order_amount }} FCFA</td>
									<td class="align-middle" v-html="checkMethodName(delivery.payment_method)"></td>
									<td class="align-middle" v-html="checkStateName(delivery.order_state)"></td>
									<td class="align-middle" v-html="checkStatusName(delivery.order_status)"></td>
									<td class="align-middle">{{ delivery.created_at | moment("DD-MM-YYYY") }}</td>
									<td class="align-middle">...
										<!-- <span style="cursor: pointer;" class="m-r-1" @click="showModal('#order-item', delivery)">
											<feather type="shopping-cart" size="13" title="Detail de la commande"></feather>
										</span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(delivery)">
                    						<feather class="text-danger" type="trash-2" size="13" title="Supprimer la commande"></feather>
                    					</span> -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-3">
                    	<pagination :data="deliveries" @pagination-change-page="loadDeliveries">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>

import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

import OrderMethods from '../mixins/orderMethods.js';
export default {
    mixins: [OrderMethods],
    components:{
        Button, HasError, AlertError
    },
    data: function() {
        return {
            filter: new Form({
                    per_page: 10,
            }),
           deliveries: [],
            pages: [1,5,10,20,30,40,50,60,70,80,90,100], 
        }
    },
    methods: {
        loadDeliveries: function(page = 1) {
            let uri = "/admin/orders/deliveries/load-filtered-deliveries?page=" + page;
            this.filter.post(uri).then(response => {
                this.deliveries = response.data;
            }).catch(err => {
                console.log(err);
            });
        }
    },
    created: function(){
        this.loadDeliveries();
    }
}
</script>

<style lang="scss" scoped>

</style>