<template>
	<div>
		<div class="row starter-main bg-white pt-5">
			<div class="col-xl-12 col-sm-12 col-lg-12">
				<div class="card pt-4 bg-primary align-middle">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-1 col-sm-12"></div>
							<div class="col-lg-10 col-sm-12">
								<ol class="breadcrumb mt-3 mb-0 p-b-0 pl-5">
					              <li class="breadcrumb-item"><a :href="'/'" class="text-white" data-bs-original-title="" title=""><i class="fa fa-home"></i></a></li>
					              <!-- <li class="breadcrumb-item f-w-800">Partenaires</li> -->
					              <li class="breadcrumb-item active text-white f-w-800">Restaurants</li>
					            </ol>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row bg-white pt-5 pb-5">
			<div class="col-lg-1 col-sm-12"></div>
		    <div class="col-lg-10 col-sm-12">
		      <div class="card border-0">
		        <div class="card-header pb-0">
		          <h5 style="font-style: normal;">Ces restaurants qui nous ont deja rejoints.</h5>
		        </div>
		        <div class="card-body">
		            <partners-view :partners="restaurants"></partners-view>
		        </div>
		      </div>
		    </div>
    	</div>
	</div>
</template>
<script>
	import PartnersView from '../home/partnersCardView.vue';
	
	export default {
		props: {
		  partners: {
		    type: Array, // String, Number, Boolean, Function, Object, Array
		    required: true,
		    default: null
		  },
		},
		components: {
			PartnersView,
		},
		data: function() {
			return {
				restaurants: this.partners,
			}
		},
		mounted: function() {

		}
	}
</script>