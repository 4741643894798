<template>
	<div>
		<div class="row starter-main bg-white pt-5">
			<div class="col-xl-12 col-sm-12 col-lg-12">
				<div class="card pt-4 bg-primary align-middle">
					<div class="card-body">
						<div class="row">
              <div class="col-lg-1 col-sm-12"></div>
              <div class="col-lg-10 col-sm-12">
                <ol class="breadcrumb mt-0 mt-sm-0 mt-md-3 mt-lg-3 mt-xl-3 mb-0 p-b-0 pl-5" style="overflow:auto; white-space: nowrap; text-overflow: ellipsis;">
                  <li class="breadcrumb-item"><a :href="'/'" class="text-white" data-bs-original-title="" title=""><i class="fa fa-home"></i></a></li>
                  <li class="breadcrumb-item active text-white f-w-800">Panier d'achat</li>
                </ol>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
		<div class="row bg-white pt-5 pb-5">
			<div class="col-lg-1 col-sm-12"></div>
      <div class="col-lg-10 col-sm-12">
        <div class="card border-0">
          <div class="card-header pb-0">
            <h5>Votre panier d'achat</h5>
          </div>
          <div class="card-body">
            <vs-alert :color="color" shadow v-if="cartContainUnderPrescriptionProduct" style="z-index:0;">
              <template #title>
                Produit(s) necessitant une ordonnance
              </template>
              Votre panier d'achat contient des produits pharmaceutiques qui ne sont delivrables que sous ordonnance medicale. Assurez-vous d'avoir le scan de l'ordonnance car celui-ci vous sera demander lors de la validation de votre commande.
            </vs-alert>
            <div class="row mt-4">
              <div class="order-history table-responsive wishlist">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Nom du produit</th>
                      <th>Prix</th>
                      <th>Quantite</th>
                      <th>Action</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in carts" :key="index">
                      <td>
                      	<img class="img-fluid img-40" :src="baseUrl+'medias/combination_image/'+item.combination_image" alt="#" v-if="item.have_variation">
                      	<img class="img-fluid img-40" :src="baseUrl+'medias/partner_product_image/'+item.partner_product_image" alt="#" v-else>
                      </td>
                      <td>
                        <div class="product-name">
                        	<a @click="goToDetails(item)" :href="'#'"> 
                              <h6 v-if="item.have_variation">{{ item.combinationName }}</h6>
                              <h6 v-else>{{ item.product }}</h6>
                          </a>
                        </div>
                      </td>
                      <td>
                      	<span v-if="item.have_variation"> {{ item.combinantionPrice }} FCFA</span>
                      	<span v-else> {{ item.price }} FCFA</span>
                      </td>
                      <td>
                        <fieldset class="qty-box">
                          <div class="input-group bootstrap-touchspin">
                            <button class="btn btn-primary btn-square bootstrap-touchspin-down" type="button" @click="decrement(item, item.quantity)"><i class="fa fa-minus"></i></button><span class="input-group-text bootstrap-touchspin-prefix" style="display: none;"></span><input class="touchspin text-center form-control" type="text" :value="item.quantity" style="display: block;" @input="checkvalue(item.quantity, item)"><span class="input-group-text bootstrap-touchspin-postfix" style="display: none;"></span><button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" @click="increment(item, item.quantity)"><i class="fa fa-plus"></i></button>
                          </div>
                        </fieldset>
                      </td>
                      <td>
                      	<span @click="removeFromCart(item)"><feather type="x-circle" size="20"></feather></span>
                      	</td>
                      <td>{{ totalAmount(item) }} FCFA</td>
                    </tr>
                    <tr>
                      <td colspan="4">                                           
                        <div class="input-group">
                          <input class="form-control me-2" type="text" placeholder="Entrer le coupon"><a class="btn btn-primary" href="#">Appliquer</a>
                        </div>
                      </td>
                      <td class="total-amount">
                        <h6 class="m-0 text-end"><span class="f-w-600">Montant Total:</span></h6>
                      </td>
                      <td><span class="f-w-900 text-dark">{{ cartTotalAmount }} Fcfa </span></td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="5"><a class="btn btn-warning cart-btn-transform" :href="'/'">Continuer mes achats</a></td>
                      <td><a class="btn btn-primary cart-btn-transform" :href="'/checkouts/page'">Commander</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>
<script>
	import EventBus from '../details/EventBus.js';
  import LoadCart from '../loadCart.js';

	export default {
    mixins: [LoadCart],
		data: function() {
			return {
				//carts: this.cart,
				baseUrl: Laravel.baseUrl,
        color:'warn',
        page:'cart-view',
			}
		},
		methods: {
      goToDetails: function(item) {
          let uri = "/partenaires/products/hash-id";
          axios.post(uri, {id: item.partner_product_id}).then(response => {
              let md5Id = response.data;
              //redirect to product detail page.
              document.location.href = "/partenaires/products/details/" + item.slug + "/" + md5Id;
          });
      },
		  totalAmount:function(item) {
		  	var price = item.price;
		  	if(item.have_variation) {
		  		price = item.combinantionPrice;
		  	}

		  	return price * item.quantity;
		  },
      updateCart: function(item) {
        let uri = "/carts/update-cart";
        
        axios.post(uri,{cartItem:item}).then(response => {
            
            EventBus.emit('cart-item-updated');
            
            //this.carts = response.data.cart;
        });
      },
      removeFromCart: function(item) {
        let uri = "/carts/remove-item-from-cart";
        axios.post(uri, {cartItem:item}).then(response => {

          EventBus.emit('cart-item-updated');
          
          //this.carts = response.data.cart;
        });
      },
		},
		created:function() {

		}
	}
</script>