<template>
    <div>
        <div class="row starter-main pt-5 bg-white">
            <div class="col-12 col-sm-12" style="background-color: #f8f9fa !important;">
                <div class="col-12">
					<div class="card align-middle" style="height: 300px;">
						<img class="img-fluid bg-img-cover" :src="baseUrl+'medias/cover_image/35995.jpg'" height="300" alt="" style="">
						<div class="card-body avatar-showcase" style="background-color: rgba(0, 0, 0,0.5);height: 300px; vertical-align: middle;">
							<div class="avatars text-center" style="padding-top: 5%;">
			                    <div class="title">
				                    <h3 class="text-white" style="font-weight: 900; font-size: 40px;">{{ category.name }}</h3>
                                    <span class="text-white" style="font-weight: 600;">Découvrez les produits nouveaux et tendances</span>
				                </div>
							</div>
						</div>
					</div>
				</div>
                <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-2"></div>
                    <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                        <div class="card shadow align-middle" style="margin-top: -110px; border-radius: 15px; height: 190px;" v-if="category.sub_categories.length > 0">
                            <span class="f-w-600 ms-5 mt-2" style="color:#333; font-size: 20px;">Les differentes catgeories</span>
                            <div class="card-body">
                                <carousel :per-page="8" :mouse-drag="false" :navigationEnabled="true" :paginationEnabled="true" style="vertical-align: middle;">
                                    <slide
                                        data-index="0"
                                        data-name="MySlideName"
                                        v-for="subcat in subCategories" :key="subcat.id">
                                        <div class="text-center">
                                            <a href="#" @click="filterBySubCategory(subcat)">
                                                <img class="img-70 rounded-circle" :src="baseUrl+'medias/sub_category_image/frontsize/187x187/'+subcat.image" width="70" height="70" alt="">
                                                <p class="text-dark mt-1 text-center" style="font-weight: 600;">{{ subcat.name }}</p>
                                            </a>
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                        <div class="card shadow" style="margin-top: -120px; border-radius: 15px; background-color: rgb(255, 236, 236);" v-else>
                            <div class="card-body">
                                <p class="text-danger">Aucune sous-catégorie disponible</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-1 col-lg-1"></div>
                </div>
                <section class="mt-5">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <div class="row">
                                <div class="row px-3 px-sm-3 px-md-0 px-lg-0 px-xl-0">
                                    <div class="col-6 col-sm-6 col-md-2 col-xl-2 col-lg-2" v-for="(item, index) in Products.data" :key="index">
                                        <div class="card shadow-sm" style="border-radius: 5px;">
                                            <div class="ribbon ribbon-clip-right ribbon-right ribbon-light text-muted" style="font-size:10px; background-color: white !important;">{{ item.partner }}</div>
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img class="img-fluid w-100 img-rounded" :src="baseUrl+'medias/partner_product_image/frontsize/187x187/'+item.partner_product_image" alt="" v-if="item.partner_product_image !== null">
                                                    <img class="img-fluid w-100 img-rounded" :src="baseUrl+'medias/product_image/'+item.product_image" alt="" v-else>
                                                    <div class="product-hover" v-if="!isRestaurant">
                                                        <ul>
                                                        <li @click="directAddToCart(item)" v-if="!item.have_variation"><a href="#"><i class="icon-shopping-cart"></i></a></li>
                                                        <li @click="displayProductDetails(item)"><a><i class="icon-eye"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="modal fade" id="exampleModalCenter3" v-if="!isRestaurant">
                                                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                        <form @submit.prevent="checkProductBeforeAddToCart">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <div class="row product-box">
                                                                        <div class="product-img col-lg-5" v-if="!combinationLoaded">
                                                                            <img class="img-fluid" :src="baseUrl+'medias/partner_product_image/'+productItem.partner_product_image" alt="" v-if="productItem.partner_product_image !== null">
                                                                            <img class="img-fluid" :src="baseUrl+'medias/product_image/'+productItem.product_image" alt="" v-else>
                                                                        </div>
                                                                        <div class="product-img col-lg-5" v-else>
                                                                            <img class="img-fluid" :src="baseUrl+'medias/combination_image/'+productItem.combination_image" alt="">
                                                                        </div>
                                                                        <div class="product-details col-lg-7 text-start"><a href="#" data-bs-original-title="" title="">
                                                                            <h4>{{ productItem.product }} <span v-if="combinationLoaded"> {{ productItem.combinationName }} </span></h4> <span class="badge rounded-pill badge-warning" v-if="productItem.is_under_prescription"> Délivré sous ordonnance</span></a>
                                                                            <p><span class="text-warning" style="color:#3c9e8a;"><b>{{ productItem.category }}</b></span>: {{ productItem.subcategory }}</p>
                                                                            <div class="product-price">
                                                                                <b v-if="!combinationLoaded">{{ productItem.price }} FCFA</b>
                                                                                <b v-else>{{ productItem.combinantionPrice }} FCFA</b>
                                                                            </div>
                                                                            <div class="product-view">
                                                                            <h6 class="f-w-600">Petite Description</h6>
                                                                            <p class="mb-0" v-html="productItem.description"></p>
                                                                            <vs-alert :color="alertcolor" :hidden-content.sync="hidden" v-if="productItem.is_under_prescription">
                                                                                <template #title style="font-size: 14px;">
                                                                                    Délivré sous ordonnance
                                                                                </template>
                                                                                <p style="font-size: 12px;">Ce produit n'est delivree que sous prescription medicale. Assurrez-vous d'avoir un scan de l'ordonnance car il vous sera demander lors de la validation de la votre commande.</p>
                                                                            </vs-alert>
                                                                            </div>
                                                                            <div class="product-size" v-if="productItem.have_variation">
                                                                            <h6 class="f-w-600">Variations</h6>
                                                                                <div class="row" v-for="(item, index) in pAttributes" :key="index">
                                                                                    <div class="col-sm-6 col-xl-3">
                                                                                        <label for="item.variationName"> {{ item.variationName }} </label>
                                                                                    </div>
                                                                                    <div class="col-sm-6 col-xl-5">
                                                                                        <div class="form-group">
                                                                                            <select v-model="item.option" id="item.variationName" class="form-select digits" @change="loadCombination" required>
                                                                                                <option value="" selected>Choix de l'option</option>
                                                                                                <option v-for="(opt, index) in item.productoptions" :key="index" :value="opt.variationOptionName"> {{ opt.variationOptionName }} </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="product-qnty">
                                                                            <h6 class="f-w-600">Quantité</h6>
                                                                            <fieldset>
                                                                                <div class="input-group bootstrap-touchspin">
                                                                                <button class="btn btn-primary btn-square bootstrap-touchspin-down" type="button" data-bs-original-title="" title="" @click="decrement(productItem, productItem.quantity)">
                                                                                    <i class="fa fa-minus"></i>
                                                                                </button>
                                                                                <span class="input-group-text bootstrap-touchspin-prefix" style="display: none;"></span>
                                                                                <input class="touchspin text-center form-control" type="number" min="1" v-model="productItem.quantity" style="display: block;" data-bs-original-title="" title="" @input="checkvalue(productItem.quantity, productItem)">
                                                                                <span class="input-group-text bootstrap-touchspin-postfix" style="display: none;"></span>
                                                                                <button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" data-bs-original-title="" title="" @click="increment(productItem, productItem.quantity)"><i class="fa fa-plus"></i>
                                                                                </button>
                                                                                </div>
                                                                            </fieldset>
                                                                            <div class="addcart-btn align-content-sm-between">
                                                                                <button class="btn btn-primary me-3" type="submit" data-bs-original-title="" title="">Ajouter au <i class="icon-shopping-cart"></i></button>
                                                                                <a class="btn btn-outline-secondary" :href="'/partenaires/products/details/'+productItem.slug" data-bs-original-title="" title=""><i class="icon-eye"></i> les Détails</a>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""> </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="product-details w-100">
                                                    <div class="text-start middle">
                                                        <a href="#" @click="goToDetails(item)" data-bs-original-title="" title="">
                                                            <p class="text-secondary" style="overflow:hidden; white-space: nowrap; text-overflow: ellipsis;">
                                                                <!-- {{ item.category }} :  -->
                                                                <b>{{ item.product }}</b>
                                                            </p>
                                                        </a>
                                                        <!-- <div class="product-price d-none d-sm-none d-md-inline"><b>{{ item.price }} FCFA</b></div> -->
                                                        <div class="product-price"><b>{{ item.price }} F</b></div>
                                                    </div>
                                                    <hr>
                                                    <div class="d-flex d-none d-sm-none d-md-block d-lg-block d-xl-block text-center">
                                                        <button class="btn btn-pill btn-outline-warning btn-sm" @click="displayProductDetails(item)" v-if="!isRestaurant">
                                                            <i class="icon-eye"></i>
                                                        </button>
                                                        <button class="btn btn-pill btn-outline-primary btn-sm" @click="!isRestaurant ? directAddToCart(item) : checkProductBeforeAddToCartForRestaurant(item)" v-if="!item.have_variation">
                                                            <i class="icon-shopping-cart"></i>
                                                        </button>
                                                    </div>
                                                    <div class="d-flex d-block d-sm-block d-md-none d-lg-none d-xl-none text-center">
                                                        <button class="btn btn-pill btn-outline-warning btn-sm" @click="displayProductDetails(item)" v-if="!isRestaurant">
                                                            <i class="icon-eye"></i>
                                                        </button>
                                                        <button class="btn btn-pill btn-outline-primary btn-sm" @click="!isRestaurant ? directAddToCart(item) : checkProductBeforeAddToCartForRestaurant(item)" v-if="!item.have_variation">
                                                            <i class="icon-shopping-cart"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <pagination :data="Products" :limit="3" @pagination-change-page="loadCategoryProducts">
                                        <span slot="prev-nav">&lt; Precedent</span>
                                        <span slot="next-nav">Suivant &gt;</span>
                                    </pagination>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import _ from 'lodash';
    import Form from 'vform';
    import restaurantProducts from '../details/restaurantProducts.vue';
    import products from '../details/products.vue';
    import loadCart from '../loadCart';
    import EventBus from '../details/EventBus';

    export default {
        props:['category'],
        mixins:[loadCart],
        components: {
            products, restaurantProducts
        },
        data: function() {
            return {
                baseUrl: Laravel.baseUrl,
                subCategories:[],
                Products: [],
                form: new Form({
                    category: this.category,
                    subcategory: [],
                    
                })
            }
        },
        computed: {
            isRestaurant: function() {
                return this.category?.category_type == 'restaurant';
            }
        },
        methods: {
            filterBySubCategory: function(subcategory) {
                this.form.subcategory = subcategory;
                this.loadCategoryProducts();
            },
            loadSubCategories: function() {
                let uri = "/categories/sub-categories/load-sub-categories/"+this.category.id;
                axios.get(uri).then(response => {
                    this.subCategories = response.data;
                });
            },
            loadCategoryProducts: function(page = 1) {
                let uri = "/categories/sub-categories/load-products?page=" + page;
                this.form.post(uri).then(response => {
                    this.Products = response.data;
                });
            },
            directAddToCart: function(item) {

                (this.productItem.length !== 0)? this.productItem = []:null;
                (this.combinationLoaded)? this.combinationLoaded = false:null;

                this.loadTotalQuantity(item);
                Vue.set(item, 'quantity', 1);
                this.productItem = item;

                this.debouceAddToCart();
            },
            debouceAddToCart: function() {
				//console.log(this.productItem);
				if(this.productItem.total_quantity > 1) {
					
					this.checkProductBeforeAddToCart();
				} else {
					var content = {};
					content.title = "";
					content.message = '<i class="fa fa-bell-o"></i><strong>Information </strong> stock limite atteint.Laissez-nous un mot pour la prise en charge de votre demande <a href="#">ici</a>';

					this.notify(content, 10000, null, null, 1000, 'secondary');
				}
			},
            addToCartForRestaurant: function(product) {
                
                this.pushToCartForRestaurant(product);
                let uri = "/partenaires/products/add-to-cart";
                axios.post(uri, {cart:this.carts}).then(response => {
                
                //this.carts = response.data.cart;

                EventBus.emit('new-item-added-to-cart');
                
                var content  = {};
                content.title = "";
                content.message = '<i class="fa fa-bell-o"></i><strong>Information </strong>'+response.data.message+'.';
                
                $("#exampleModalCenter3").modal('hide');

                this.notify(content,null, {from:'top', align:'right'}, {x:30, y:30}, 1000, 'success');
                });
            },
            pushToCartForRestaurant: function(product) {

                var have_variation = product.have_variation;
                this.carts.push({
                partner_product_id: product.id,
                account_id: product.account_id,
                product_id:product.product_id,
                category_id: product.category_id,
                sub_category_id: product.sub_category_id,
                product: product.product,
                slug: product.slug,
                product_type: product.product_type,
                category: product.category,
                subcategory: product.subcategory,
                price: product.price,
                partner_product_image: product.partner_product_image,
                have_variation: have_variation,
                is_under_prescription: product.is_under_prescription,
                product_combination_id: (have_variation)? product.product_combination_id: null,
                combinationName: (have_variation)? product.combinationName: null,
                combinationCode: (have_variation)? product.combinationCode:null,
                combinantionPrice: (have_variation)? product.combinantionPrice:null,
                combination_image:(have_variation)? product.combination_image:null,
                total_quantity: product.total_quantity,
                quantity: product.quantity,
                });
            },
            checkCartContentBeforeAddingForRestaurant: function(product) {
                let exist = false;
                Object.keys(this.carts).forEach(key => {
                if(product.have_variation) {
                    if(this.carts[key].partner_product_id == product.id && this.carts[key].combinationCode == product.combinationCode) {
                    exist = true;
                    }
                } else {
                    if(this.carts[key].partner_product_id == product.id) {
                    exist = true;
                    }
                }
                });

                return exist;
            },
            checkProductBeforeAddToCartForRestaurant: function(product) {
                Vue.set(product, 'quantity', 1);
                if(!this.checkCartContentBeforeAddingForRestaurant(product)){
                
                this.addToCartForRestaurant(product);
                } else {
                console.log(this.checkCartContentBeforeAddingForRestaurant(product));
                var content = {};
                content.title = "";
                content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> Ce produit existe deja dans votre panier. Modifiez sa quantite <a href="/carts/panier-d-achat">ici</a>';

                this.notify(content, 10000, null, null, 1051, 'secondary');
                }
            },
            goToDetails: function(item) {
                let uri = "/partenaires/products/hash-id";
                axios.post(uri, {id: item.id}).then(response => {
                    let md5Id = response.data;
                    //redirect to product detail page.
                    document.location.href = "/partenaires/products/details/" + item.slug + "/" + md5Id;
                });
            }
        },
        created: function() {
            this.loadSubCategories();
            this.loadCategoryProducts();
            this.debouceAddToCart = _.debounce(this.debouceAddToCart, 2000);
        }
    }
</script>
<style scoped>
	.img-rounded {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
</style>