import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

export default {
	components:{
		Button, HasError, AlertError
	},
	data: function() {
		return {
			cities: [],
			zones: [],
			form: new Form({
				city_id:'',
				zone_id:''
			})
		}
	},
	methods: {
		loadCities: function() {
			let uri = "/api/cities";
			axios.get(uri).then(response => {this.cities = response.data});
		},
		loadZones: function() {
			let uri = "/api/city-zones";
			axios.post(uri, {city: this.form.city_id}).then(response => { this.zones = response.data});
		}
	},
	mounted: function() {
		this.loadCities();
	}
}