// import { url } from 'stylus';
import EventBus from '../components/fronts/details/EventBus.js';
import Notify from './notification.js';
import { reactive } from 'vue';
export default {
	mixins:[Notify],
	data: function() {
		return {
			productItem:[],
			pAttributes:[],
			combinationLoaded:false,
			total_quantity:0,
		}
	}, 
	methods: {
		displayProductDetails: function(item) {
			(this.productItem.length !== 0)? this.productItem = []:null;
			(this.combinationLoaded)? this.combinationLoaded = false:null;
			
			
			this.loadTotalQuantity(item);
			Vue.set(item, 'quantity', 1);
			this.productItem = item;

			if(item.have_variation == 1) {
				this.loadProductAttributes(item.id);
			} else {
				$("#exampleModalCenter3").modal('show');
			}
		},
		
		loadProductAttributes: function(id) {
			const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement des donnees'});
			let uri = "/partenaires/products/attribute/"+id;
			axios.get(uri).then(response => {
				loading.close();
				this.pAttributes = response.data;
				$("#exampleModalCenter3").modal('show');
			}).catch(err => {loading.close(); console.log(err); });
		},
		loadTotalQuantity:function(item) {

			if(!item.have_variation) {
				let uri = "/partenaires/products/total-quantity/"+item.id;
			
				axios.get(uri).then(response => {
					this.total_quantity = response.data;
					Vue.set(this.productItem, 'total_quantity', this.total_quantity);
				}).catch(err => {
					console.log(err);
				});
			} else {
				this.total_quantity = 0;
				Vue.set(item, 'total_quantity', this.total_quantity);
				this.productItem = item;
			} 
		},
		loadCombination:function() {
			const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement des donnees'});
			let uri = "/partenaires/products/combination";
			axios.post(uri, {attrs: this.pAttributes, id:this.productItem.id})
			.then(response => {
					loading.close();
					if(response.data.success) {
						this.combinationLoaded = true;
						this.productItem = response.data.combination;
						// this.productItem.partner_product_image = response.data.combination.combination_image;
					} else {
						this.combinationLoaded = false;
						this.productItem = response.data.product;
						//this.pAttributes = [];

						var content = {};

						content.title = '';
						content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> Pas de produit pour cette combinaison!';
						
						this.notify(content, 2000, {from:'top', align:'right'}, {x:30, y:30}, null, 'danger');
					}
			}).catch(err => {
				loading.close();
				console.log(err);
			});
		},
		decrement:function(item, quantity) {
			if(quantity == 1) {

				var content = {};

				 content.title = '';
				content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> la quantite minimum est egale a 1';

				this.notify(content, 2000, {from:'top', align:'right'}, {x:30, y:30}, null);
				
			} else {
				quantity = quantity - 1;
				item.quantity = quantity;
				(this.page === 'cart-view')? this.updateCart(item): this.productItem = item;
			}
		},
		increment: function(item, quantity) {

			if(item.total_quantity == 0) {

				var content = {};

				content.title = '';
				content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> Vous devez selectionner les variations.';
				
				this.notify(content, 2000, null, null, 1051, 'info');
			} else {
				if(quantity == item.total_quantity) {
					
					var content = {};
					
					content.title = '';
					content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> stock limite atteint.Laissez-nous un mot pour la prise en charge de votre demande <a href="#">ici</a>';
					
					this.notify(content, 10000, null, null, 1051, 'secondary');
				} else {
					quantity =  quantity + 1;
					item.quantity = quantity;
					(this.page === 'cart-view')? this.updateCart(item): this.productItem = item;

				}
			}
		},
		checkvalue: function(value, item) {
			let quantity = value;
			if(value < 1) {

				var content = {};

				 content.title = '';
				content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> la quantite minimum est egale a 1';
				this.notify(content, 2000, {from:'top', align:'right'}, {x:30, y:30}, null);
				quantity = 1;
			} else if(value > item.total_quantity) {

				var content = {};
				content.title = '';
				content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> stock limite atteint.Laissez-nous un mot pour la prise en charge de votre demande <a href="#">ici</a>';
				
				this.notify(content, 10000, null, null, 1051, 'secondary');
				quantity = item.total_quantity;
			} else {
				quantity = value;
			}

			item.quantity = quantity;
			(this.page === 'cart-view')? this.updateCart(item): this.productItem = item;
		},
		pushToCart:function() {

			var have_variation = this.productItem.have_variation;
			
			this.carts.push({
				partner_product_id: this.productItem.id,
				account_id: this.productItem.account_id,
				product_id:this.productItem.product_id,
				category_id: this.productItem.category_id,
				sub_category_id: this.productItem.sub_category_id,
				product: this.productItem.product,
				slug: this.productItem.slug,
				product_type: this.productItem.product_type,
				category: this.productItem.category,
				subcategory: this.productItem.subcategory,
				price: this.productItem.price,
				partner_product_image: this.productItem.partner_product_image,
				have_variation: have_variation,
				is_under_prescription: this.productItem.is_under_prescription,
				product_combination_id: (have_variation)? this.productItem.product_combination_id: null,
				combinationName: (have_variation)? this.productItem.combinationName: null,
				combinationCode: (have_variation)? this.productItem.combinationCode:null,
				combinantionPrice: (have_variation)? this.productItem.combinantionPrice:null,
				combination_image:(have_variation)? this.productItem.combination_image:null,
				total_quantity: this.productItem.total_quantity,
				quantity: this.productItem.quantity,
			});
		},
		addToCart: function() {
			this.pushToCart();
			let uri = "/partenaires/products/add-to-cart";
			axios.post(uri, {cart:this.carts}).then(response => {
				//this.carts = response.data.cart;

				EventBus.emit('new-item-added-to-cart');
				
				(this.page === 'products')? $("#exampleModalCenter3").modal('hide'):null;
				this.displayToast(true, response.data.message);
			});
		},
		checkCartContentBeforeAdding: function(item) {
			// let exist = false;
			const exist =  this.carts.some(function(cart) {
				if(item.have_variation) {
					return cart.combinationCode === item.combinationCode;
				} else {
					return cart.partner_product_id == item.id;
				} 
			});

			return exist;
		},
		checkProductBeforeAddToCart: function() {
			if(this.productItem.have_variation) {
				if(this.productItem.total_quantity > 0) {
					if(!this.checkCartContentBeforeAdding(this.productItem)){
						console.log(this.checkCartContentBeforeAdding(this.productItem));
						this.addToCart();
					} else {
						console.log(this.checkCartContentBeforeAdding(this.productItem));
						const message = 'Ce produit existe deja dans votre panier. Modifiez-y sa quantite';
						// this.displaySwal(false,message, 3500);
						this.displayToast(false, message);
					}
				} else {
					const message = 'Impossible de satisfaire cette demande. Changez de combinaison ou laissez-nous votre demande au niveau de l\'onglet Services Speciaux';
					this.displaySwal(false,message, 3500);
				}
			} else {
				if(this.productItem.total_quantity > 0) {
					if(!this.checkCartContentBeforeAdding(this.productItem)){
						
						this.addToCart();
					} else {
						console.log(this.checkCartContentBeforeAdding(this.productItem));
						
						const message = 'Ce produit existe deja dans votre panier. Modifiez-y sa quantite';
						this.displayToast(false, message);
					}
				} else {
					const message = 'Impossible de satisfaire cette demande. Stock limite atteint. Laissez-nous un mot pour la prise en charge de votre demande au niveau de l\'onglet Services Speciaux';
					this.displaySwal(false, message, 3500);
					// this.notify(content, 10000, null, null, 1051, 'danger');
				}
			}
		},
	},

	created: function() {}
}