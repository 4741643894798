<template>
    <div>
        <div class="row starter-main">
			<div class="card">
				<div class="card-body">
                    <div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<select v-model="filter.per_page" class="form-select digits" @change="loadSupplies">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
                        <div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<multiselect v-model="filter.category" :options="categories" :hide-selected="false" placeholder="Tous les categories" label="name" track-by="id" @input="loadSubCategories"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<multiselect v-model="filter.subcategory" :options="subcategories" :hide-selected="false" placeholder="Tous les sous categories" label="name" track-by="id" @input="loadSupplies"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-3 col-md-3">
							<div class="form-group">
		                    	<multiselect v-model="filter.suppling_date" :options="SupplyDates" :hide-selected="false" placeholder="Toutes les dates" label="value" track-by="name" @input="loadSupplies"></multiselect>
		                    </div>
						</div>
						<div class="col-sm-12 col-lg-2 col-md-2"></div>
					</div>
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Produit</th>
                                    <th>Variable?</th>
									<th>Categorie</th>
									<th>Sous-Categorie</th>
                                    <th>Quantite</th>
                                    <th>Date</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in supplies.data" :key="index">
									<td class="align-middle">
										{{ index+1 }}
									</td>
									<td class="align-middle">
                                        <span v-if="item.have_variation"><a href="#" @click="loadCombinationStocks(item, 'show-combination-product-inputs')">{{ item.product }}</a></span>
                                        <span v-else>{{ item.product }}</span>
                                        </td>
                                    <td class="align-middle">
                                        <span v-if="item.have_variation">Oui <a href="#"><feather type="external-link" size="13"></feather></a></span><span v-else>Non</span>
                                    </td>
									<td class="align-middle">{{ item.category }}</td>
									<td class="align-middle">{{ item.subcategory }}</td>
                                    <th class="align-middle">{{ item.total_quantity }}</th>
                                    <td class="align-middle">{{ item.created_at | moment('DD-MM-YYYY') }}</td>
									<td class="align-middle">
										<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-input', item)" v-if="!item.have_variation">
											<feather type="edit" size="13"></feather>
										</span>
                                        <span style="cursor: pointer;" class="m-r-1" @click="loadCombinationStocks(item, 'show-combination-product-inputs')" v-else>
                                            <feather type="external-link" size="13" class="text-primary"></feather>
                                        </span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
                    						<feather type="trash-2" size="13"></feather>
                    					</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-3">
                    	<pagination :data="supplies" @pagination-change-page="loadSupplies">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div>
				</div>
			</div>
		</div>
        <modal name="edit-input" :width="400" :height="350" :adaptive="true">
	        <form @submit.prevent="UpdateSupply('edit-input')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div class="form-group">
	        				<label for="product">Nom du produit</label>
	        				<input type="text" v-model="stock.product" id="product" class="form-control" name="product" disabled>
	        			</div>
	        			<div class="form-group">
	        				<label for="date">Date d'entrée</label>
	        				<input type="text" :value="stock.created_at" id="date" class="form-control" name="date" disabled>
	        			</div>
	                    <div class="form-group">
	        				<label for="qte">Quantité</label>
	        				<input type="number" v-model="stock.total_quantity" id="qte" class="form-control" name="total_quantity">
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-input')">Close</button>
		                <button type="submit" :disabled="stock.busy" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
        <modal name="show-combination-product-inputs" :width="1000" :height="630" :clickToClose="false">
			<div slot="top-right">
		      <button @click="closeModal('show-combination-product-inputs')">
		        ❌
		      </button>
		    </div>
	        <form @submit.prevent="UpdateProduct('show-combination-product-inputs')" class="p-4" style="overflow-y:auto;">
	        	<div class="row">
					<div class="card border-0">
						<div class="card-body">
							<div class="table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th>#</th>
											<th>Produit</th>
											<th>Quantité</th>
											<th>Date</th>
											<th>...</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in combinationStocks" :key="index">
											<td>{{ index+1 }}</td>
											<td>{{ item.product }}</td>
											<td>{{ item.combination_quantity }}</td>
											<td>{{ item.created_at | moment('DD-MM-YYYY') }}</td>
											<td class="align-middle">
												<span style="cursor: pointer;" class="m-r-1" @click="editModal('edit-combination-product-inputs', item)">
													<feather type="edit" size="13"></feather>
												</span>

												<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
													<feather type="trash-2" size="13"></feather>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
	        </form>
	    </modal>
		<div class="modal fade" id="edit-combination-product-inputs" tabindex="-1" aria-labelledby="exampleModalCenter" aria-hidden="true" style="display: none;">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<form @submit.prevent="UpdateCombinationSupply('edit-combination-product-inputs')">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Modification de l'entrée</h5>
							<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
						</div>
						<div class="modal-body">
							<div class="form-group">
								<label for="product">Nom du produit</label>
								<input type="text" v-model="stock.product" id="product" class="form-control" name="product" disabled>
							</div>
							<div class="form-group">
								<label for="date">Date d'entrée</label>
								<input type="text" :value="stock.created_at" id="date" class="form-control" name="date" disabled>
							</div>
							<div class="form-group">
								<label for="qte">Quantité</label>
								<input type="number" v-model="stock.total_quantity" id="qte" class="form-control" name="total_quantity">
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">Close</button>
							<button class="btn btn-primary" :disabled="stock.busy" type="submit" data-bs-original-title="" title="">Appliquer les changements</button>
						</div>
					</div>
				</form>
			</div>
		</div>
    	<v-dialog />
    </div>
</template>

<script>

    import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

    export default {
        props: {
			Partner:Object,
		},
        components:{
			Button, HasError, AlertError
		},
        data: function() {
            return {
                filter: new Form({
                    account: this.Partner,
                    category:'',
                    subcategory:'',
                    suppling_date:'',
                    per_page:10,
                }),
                stock: new Form({
                    id:'',
                    product:'',
                    partner_product_id:'',
                    have_variation:'',
                    combinationCode:'',
                    total_quantity:'',
                    input_code:'',
					selectedProduct:[],
                    created_at:''
                }),
                types: [
					{name: 'restaurant', value: 'Restaurants'},
					{name: 'pharmacy', value: 'Phamacies'},
					{name: 'supermarket', value: 'Supermarches'},
				],
                supplies:[],
                partners: [],
				categories:[],
				subcategories:[],
                SupplyDates:[],
				combinationStocks:[],
				selectedProduct:[],
                partnerDetails: this.Partner,
                pages: [1,5,10,20,30,40,50,60,70,80,90,100],
            }
        },
        computed: {
            profile: function() {
                return this.partnerDetails.account_profile.toLowerCase();
            }
        },
        methods: {
            showModal: function(name, datas){
				datas? this.stock.fill(datas): this.stock.reset();
				this.$modal.show(name);
			},
			editModal: function(name, datas) {
				this.stock.fill(datas);
				this.stock.selectedProduct = this.selectedProduct;
				$('#'+name).modal('show');
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
            UpdateSupply: function(name) {
                let uri = "/"+this.profile+"/supplies/update-product-input";
                this.stock.put(uri).then(response => {
                    Fire.$emit("Refresh-Datas");
                    this.closeModal(name);

                    Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                 });

                }).catch(err => {
                    console.log(err);
                });
            },
			UpdateCombinationSupply: function(name) {
				let uri = "/"+this.profile+"/supplies/update-product-combination-input";
				this.stock.post(uri).then(response => {
					
					(response.data.success)? this.combinationStocks = response.data.combinations:null;
					(response.data.success)?  $("#"+name).modal('hide'):null;
				   	
					Fire.$emit("Refresh-Datas");
                   
                    Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                 });

                }).catch(err => {
                    console.log(err);
                });
			},
			loadCombinationStocks: function(item, name) {
				this.selectedProduct = item;
				let uri = "/"+this.profile+"/supplies/product-combination-registered-inputs";
				axios.post(uri, {stock: item}).then(response => {
					this.combinationStocks = response.data;
					this.$modal.show(name);
				}).catch(err => {
					console.log(err);
				});
			},
            loadSupplies: function(page = 1) {
                let uri = "/"+this.profile+"/supplies/load-supplies?page=" + page;
                this.filter.post(uri).then(response => {
                    this.supplies = response.data;
                }).catch(err => {
                    console.log(err);
                });
            },
            loadCategories: function() {
				this.loadSupplies();
				let uri = '/'+this.profile+'/products/get-sellpoints-products-categories';
				this.filter.post(uri).then(response => {
					this.categories = response.data;
				});
			},
            loadSubCategories: function() {
				this.loadSupplies();
				let uri = "/"+this.profile+"/products/get-sellpoints-products-sub-categories";
				this.filter.post(uri).then(response => {
					this.subcategories = response.data;
					//this.subCategoriesList = response.data;
				});
			},
            loadSupplyDates: function() {
                let uri = "/"+this.profile+"/supplies/supply-dates";
                axios.get(uri).then(response => {
                    if(Array.isArray(response.data)) {

                        this.SupplyDates = response.data;
                    } else {
                        this.SupplyDates = Object.keys(response.data).map(key => {
                            return response.data[key];
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        created: function() {
            this.loadSupplies();
            this.loadCategories();
            this.loadSupplyDates();

            Fire.$on('Refresh-Datas', () => {
                this.loadSupplies();
            });
        }
    }
</script>

<style>

</style>