<template>
	<div>
		<div class="row starter-main">
			<div class="col-sm-4">
	            <div class="card">
	                <form @submit.prevent="createZones">
	                	<div class="card-body" style="height: 350px; overflow-y: auto;">
	                		<div class="m-b-5">
	                			<select v-model="city_id" class="form-select digits" id="exampleFormControlSelect9">
	                              <option value="">Choisissez la ville</option>
	                              <option v-for="(item, index) in cities" :key="index" :value="item.id"> {{ item.name }} </option>
	                            </select>
	                		</div>
		                    <div class="table-responsive">
		                    	<table class="table table-bordered">
			                    	<thead>
			                    		<tr>
			                    			<th>Nom de la zone</th>
			                    			<th class="text-center align-middle">
			                    				<span style="cursor: pointer;" class="addRow text-success" v-on:click="addRow"><feather type="plus-circle" size="16"></feather></span>
			                    			</th>
			                    		</tr>
			                    	</thead>
			                    	<tbody>
			                    		<tr v-for="(addTd, zones) in addRows" :key="zones">
			                    			<td>
			                    				<input v-model='addTd.name' class="form-control" type="text" placeholder="">
			                    			</td>
			                    			<td class="text-center align-middle">
			                    				<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteRow(zones,addTd)"><feather type="x-circle" size="16"></feather></span>
			                    			</td>
			                    		</tr>
			                    	</tbody>
		                    	</table>
		                    </div>
		                </div>
		                <div class="card-footer p-3 bg-light text-center" v-if="$can('create-zone')">
		                	<button class="btn btn-outline-primary" type="submit">Enregistrer les zones</button>
		                </div>
	                </form>
	            </div>
	        </div>
	        <div class="col-sm-8">
	            <div class="card">
	                <div class="card-header pb-0">
	                    <div class="setting-list">
	                        <ul class="list-unstyled setting-option">
	                            <li>
	                                <div class="setting-primary"><i class="icon-settings"></i></div>
	                            </li>
	                            <li><i class="view-html fa fa-code font-primary"></i></li>
	                            <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
	                            <li><i class="icofont icofont-minus minimize-card font-primary"></i></li>
	                            <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
	                            <li><i class="icofont icofont-error close-card font-primary"></i></li>
	                        </ul>
	                    </div>
	                </div>
	                <div class="card-body">
	                    <div class="table-responsive">
	                    	<table class="table table-bordered">
	                    		<thead>
	                    			<tr>
	                    				<th>#</th>
	                    				<th>Nom</th>
	                    				<th>Ville</th>
	                    				<th>Date Creation</th>
	                    				<th>...</th>
	                    			</tr>
	                    		</thead>
	                    		<tbody>
	                    			<tr v-for="(item, index) in zones" :key="index">
	                    				<td>{{ item.id }}</td>
	                    				<td>{{ item.name }}</td>
	                    				<td>{{ item.city.name }}</td>
	                    				<td>{{ item.created_at | moment("from", "now")}}</td>
	                    				<td>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-zone', item)" v-if="$can('edit-zone')"><feather type="edit" size="13"></feather></span>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteZone(item)" v-if="$can('delete-zone')"><feather type="trash-2" size="13"></feather></span>
	                    				</td>
	                    			</tr>
	                    		</tbody>
	                    	</table>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
		<modal name="edit-zone" :width="400" :height="250" :adaptive="true">
	        <form @submit.prevent="UpdateZone('edit-zone')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<label for="name">Ville</label>
	        				<select v-model="zone.city_id" class="form-select digits" id="exampleFormControlSelect9">
                              <option value="">Choisissez la ville</option>
                              <option v-for="(item, index) in cities" :key="index" :value="item.id"> {{ item.name }} </option>
                            </select>
                            <HasError :form="zone" field="city_id" />
	        			</div>
	        			<div>
	        				<label for="name">Nom de la zone</label>
	        				<input type="text" v-model="zone.name" id="name" class="form-control" name="name">
	        				<HasError :form="zone" field="name" />
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-zone')">Close</button>
		                <button type="submit" :disabled="zone.busy" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <v-dialog />
	</div>
</template>
<script>
import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

import Permissions from '../mixins/permissions.vue';

	export default {
		mixins: [Permissions],
		components:{
			Button, HasError, AlertError
		},
		data(){
			return {
				addRows:[],
				zones:[],
				cities:[],
				city_id:'',
				zone: new Form({
					id:'',
					city_id:'',
					name:'',
				})
			}
		},

		methods:{
			addRow() {
		        this.addRows.push({
		            name:null,
		        });
	        },
	        deleteRow(index, addTd) {
	            var idx = this.addRows.indexOf(addTd);
	            if (idx > -1) {
	                this.addRows.splice(idx, 1);
	            }
	        },
	        showModal: function(name, datas){

				datas? this.zone.fill(datas): this.zone.reset();
				this.$modal.show(name);
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
	        createZones: function() {
	        	if(this.city_id != '') {
	        		let uri = "/admin/zones/create";
					var addRows = _.map(this.addRows, function (num) { return _.pick(num,'name')});
					axios.post(uri, {zones:addRows, city_id: this.city_id}).then(response => {
						Fire.$emit('AfterAction');
						response.data.success? this.addRows = [] :null;
						response.data.success? this.city_id = '' :null;
						
						Toast.fire({
		                    icon: response.data.success? 'success':'error',
		                    title: response.data.message
		                 });
					});
	        	}

	        	Toast.fire({
	                icon: 'warning',
	                title: 'Vous devez choisir une ville!'
	            });

			},
			UpdateZone: function(modal) {
				let uri = "/admin/zones/update";
				this.zone.put(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.$modal.hide(modal):null;
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
			},

			deleteZone: function(item) {

				let uri = "/admin/zones/delete";
				this.zone.fill(item);

				this.$modal.show('dialog', {
				  title: 'Suppression de zone',
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette zone?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        this.zone.delete(uri).then(response => {
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},

			loadZones: function() {
				let uri = '/admin/zones/zones';
				axios.get(uri).then(response => {
					this.zones = response.data;
				});
			},
			loadCities: function() {
				let uri = '/admin/cities/cities';
				axios.get(uri).then(response => {
					this.cities = response.data;
				});
			}
		},
		created(){
			this.loadZones(); this.loadCities();
			Fire.$on("AfterAction", () => {
				//console.log('action happened!');
				this.loadZones();
			});
		}
	}
</script>