<template>
	<div>
		<div class="row">
			<div class="col-6 col-sm-3 col-xl-3 col-lg-3" v-for="(item, index) in Products" :key="index">
				<div class="card">
					<div class="ribbon ribbon-clip-right ribbon-right ribbon-primary d-none d-sm-none d-md-inline d-lg-inline d-xl-inline" style="font-size:10px;">{{ item.partner }}</div>
		          <div class="product-box">
		            <div class="product-img">
		              <img class="img-fluid w-100" :src="baseUrl+'medias/partner_product_image/frontsize/187x187/'+item.partner_product_image" alt="" v-if="item.partner_product_image !== null">
		              <img class="img-fluid w-100" :src="baseUrl+'medias/product_image/'+item.product_image" alt="" v-else>
		              <div class="product-hover">
		                <ul>
		                  <li @click="directAddToCart(item)" v-if="!item.have_variation"><a href="#"><i class="icon-shopping-cart"></i></a></li>
		                  <li @click="displayProductDetails(item)"><a><i class="icon-eye"></i></a></li>
		                </ul>
		              </div>
		            </div>
		            <div class="modal fade" id="exampleModalCenter3">
		              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		                <form @submit.prevent="checkProductBeforeAddToCart">
		                	<div class="modal-content">
			                  <div class="modal-header">
			                    <div class="product-box row">
			                      <div class="product-img col-lg-5" v-if="!combinationLoaded">
			                      	<img class="img-fluid" :src="baseUrl+'medias/partner_product_image/'+productItem.partner_product_image" alt="" v-if="productItem.partner_product_image !== null">
			                      	<img class="img-fluid" :src="baseUrl+'medias/product_image/'+productItem.product_image" alt="" v-else>
			                      </div>
			                      <div class="product-img col-lg-5" v-else>
			                      	<img class="img-fluid" :src="baseUrl+'medias/combination_image/'+productItem.combination_image" alt="">
			                      </div>
			                      <div class="product-details col-lg-7 text-start"><a href="#" data-bs-original-title="" title="">
			                          <h4>{{ productItem.product }} <span v-if="combinationLoaded"> {{ productItem.combinationName }} </span></h4> <span class="badge rounded-pill badge-warning" v-if="productItem.is_under_prescription"> Délivré sous ordonnance</span></a>
			                          <p><span class="text-warning" style="color:#3c9e8a;"><b>{{ productItem.category }}</b></span>: {{ productItem.subcategory }}</p>
			                        <div class="product-price">
			                        	<b v-if="!combinationLoaded">{{ formatTHIS(productItem.price, 0) }} FCFA</b>
			                        	<b v-else>{{ formatTHIS(productItem.combinantionPrice, 0) }} FCFA</b>
			                        </div>
			                        <div class="product-view">
			                          <h6 class="f-w-600">Petite Description</h6>
			                          <p class="mb-0" v-html="productItem.description"></p>
			                          <vs-alert :color="alertcolor" :hidden-content.sync="hidden" v-if="productItem.is_under_prescription">
									      <template #title style="font-size: 14px;">
									        Délivré sous ordonnance
									      </template>
									      <p style="font-size: 12px;">Ce produit n'est delivree que sous prescription medicale. Assurrez-vous d'avoir un scan de l'ordonnance car il vous sera demander lors de la validation de la votre commande.</p>
									  </vs-alert>
			                        </div>
			                        <div class="product-size" v-if="productItem.have_variation">
			                          <h6 class="f-w-600">Variations</h6>
			                        	<div class="row" v-for="(item, index) in pAttributes" :key="index">
			                        		<div class="col-sm-6 col-xl-3">
			                        			<label for="item.variationName"> {{ item.variationName }} </label>
			                        		</div>
			                        		<div class="col-sm-6 col-xl-5">
			                        			<div class="form-group">
			                        				<select v-model="item.option" id="item.variationName" class="form-select digits" @change="loadCombination" required>
				                        				<option value="" selected>Choix de l'option</option>
				                        				<option v-for="(opt, index) in item.productoptions" :key="index" :value="opt.variationOptionName"> {{ opt.variationOptionName }} </option>
				                        			</select>
			                        			</div>
			                        		</div>
			                        	</div>
			                        </div>
			                        <div class="product-qnty">
			                          <h6 class="f-w-600">Quantité</h6>
			                          <fieldset>
			                            <div class="input-group bootstrap-touchspin">
			                              <button class="btn btn-primary btn-square bootstrap-touchspin-down" type="button" data-bs-original-title="" title="" @click="decrement(productItem, productItem.quantity)">
			                              	<i class="fa fa-minus"></i>
			                              </button>
			                              <span class="input-group-text bootstrap-touchspin-prefix" style="display: none;"></span>
			                              <input class="touchspin text-center form-control" type="number" min="1" v-model="productItem.quantity" style="display: block;" data-bs-original-title="" title="" @input="checkvalue(productItem.quantity, productItem)">
			                              <span class="input-group-text bootstrap-touchspin-postfix" style="display: none;"></span>
			                              <button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" data-bs-original-title="" title="" @click="increment(productItem, productItem.quantity)"><i class="fa fa-plus"></i>
			                              </button>
			                            </div>
			                          </fieldset>
			                          <div class="addcart-btn align-content-sm-between">
			                          	<button class="btn btn-primary me-3" type="submit" data-bs-original-title="" title="">Ajouter au <i class="icon-shopping-cart"></i></button>
			                          	<a class="btn btn-outline-secondary" :href="'/partenaires/products/details/'+productItem.slug" data-bs-original-title="" title=""><i class="icon-eye"></i> les Détails</a>
			                          </div>
			                        </div>
			                      </div>
			                    </div>
			                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""> </button>
			                  </div>
			                </div>
		                </form>
		              </div>
		            </div>
		            <div class="product-details">
						<a :href="'/partenaires/products/details/'+item.slug" data-bs-original-title="" title="">
							<p class="text-secondary">
								<!-- <span class="badge badge-light text-dark">{{ item.partner.toUpperCase() }}</span><br> -->
								<b>{{ item.product }}</b>
							</p>
						</a>
		              	<p>
		              		{{ item.category }}
		              	</p>
		              	<div class="product-price"><b>{{ formatTHIS(item.price,0) }} FCFA</b></div>
						  <div class="d-md-none d-lg-none d-xl-none" style="overflow:hidden; white-space: nowrap; text-overflow: ellipsis;">
							<span class="mr-1"><i class="fa fa-user" style="font-size: 10px;"></i></span>
							<span style="font-size: 10px;">{{ item.partner }}</span>
						</div>
		            </div>
		          </div>
		        </div>
			</div>
		</div>
		<!-- <div class="mt-3">
			<pagination :data="Products" @pagination-change-page="loadProducts">
				<span slot="prev-nav">&lt; Precedent</span>
				<span slot="next-nav">Suivant &gt;</span>
			</pagination>
		</div> -->
		<!-- <Dialog :message="message" :active="active" :button="withButton"></Dialog> -->
	</div>
</template>

<script>

	import moment from 'moment';
	import _ from 'lodash';
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	import Dialog from '../../details/dialog.vue';
	import EventBus from '../../details/EventBus.js';
	import LoadCart from '../../loadCart.js';
	import Formatter from '../../../../mixins/formatter.js';
	// import ProductMethods from '../../../mixins/productMethods.js';

	export default {
		mixins: [LoadCart, Formatter],
		props:{
			Products: Array,
		},
		components:{
			Button, HasError, AlertError, Dialog
		},
		data: function() {
			return {
				baseUrl:Laravel.baseUrl,
				color: '#168EEA',
				active:false,
				message:'',
				withButton:false,
				hidden:true,
				alertcolor: 'warn',
				page:'products',
				// Products:[],
				//carts: this.cart,
			}
		},
		methods: {
			directAddToCart: function(item) {

				(this.productItem.length > 0)? this.productItem = []:null;
				(this.combinationLoaded)? this.combinationLoaded = false:null;

				this.loadTotalQuantity(item);
				Vue.set(item, 'quantity', 1);
				this.productItem = item;

				this.debouceAddToCart();
			},
			debouceAddToCart: function() {
				//console.log(this.productItem);
				if(this.productItem.total_quantity > 1) {
					
					this.addToCart();
				} else {
					var content = {};
					content.title = "";
					content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> stock limite atteint.Laissez-nous un mot pour la prise en charge de votre demande <a href="#">ici</a>';

					this.notify(content, 10000, null, null, 1000, 'secondary');
				}
			},
		},
		created: function() {
			// this.loadProducts();
			this.debouceAddToCart = _.debounce(this.debouceAddToCart, 2000);
		},
		mounted: function() {
		}
	}
</script>