<template>
	<div>
		<div class="row">
			<div class="card">
				<div class="card-body">
					<div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<select v-model="filter.per_page" class="form-select digits" @change="loadOrders">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
		                    	<multiselect v-model="filter.order_state" :options="oStates" :hide-selected="false" placeholder="Tous les etats" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
		                    	<multiselect v-model="filter.order_status" :options="oStatus" :hide-selected="false" placeholder="Tous les statuts" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
		                    	<multiselect v-model="filter.payment_method" :options="pMethods" :hide-selected="false" placeholder="Tous les moyens" label="value" track-by="key" @input="loadOrders"></multiselect>
		                    </div>
                        </div>
						<div class="col-sm-12 col-md-2 col-lg-2">
                            <div class="form-group"></div>
                        </div>
					</div>
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Client</th>
									<th>Montant</th>
									<th>Moyen de paiement</th>
									<th>Etat</th>
									<th>Statut</th>
									<th>Date</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(order, index) in orders.data" :key="index">
									<td class="align-middle f-w-900 bg-light">{{ order.order_code }}</td>
									<td class="align-middle">{{ order.name }} {{ order.last_name }}</td>
									<td class="align-middle bg-light f-w-900">{{ order.total_amount }} FCFA</td>
									<td class="align-middle" v-html="checkMethodName(order.payment_method)"></td>
									<td class="align-middle" v-html="checkStateName(order.order_state)"></td>
									<td class="align-middle" v-html="checkStatusName(order.order_status)"></td>
									<td class="align-middle">{{ order.created_at | moment("DD-MM-YYYY") }}</td>
									<td class="align-middle">
										<span style="cursor: pointer;" class="m-r-1" @click="showModal('#order-item', order)">
											<feather type="shopping-cart" size="13"></feather>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-3">
                    	<pagination :data="orders" @pagination-change-page="loadOrders">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="order-item" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Panier d'achat</h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title=""></button>
              </div>
              <div class="modal-body">
                <p>
                	<table class="table table-bordered table-striped">
                		<thead>
                			<tr>
                				<th>#</th>
                				<th>Produit</th>
                				<th>Quantite</th>
                				<th>Prix unitaire</th>
                				<th>Montant total</th>
                			</tr>
                		</thead>
                		<tbody>
                			<tr v-for="(item, index) in orderItems" :key="index">
                				<td>{{ item.id }}</td>
                				<td>{{ item.name }}</td>
                				<td>{{ item.quantity }}</td>
                				<td>{{ item.unit_price }} FCFA</td>
                				<td>{{ item.quantity * item.unit_price }} FCFA</td>
                			</tr>
                		</tbody>
                	</table>
                </p>
              </div>
              <div class="modal-footer justify-content-between bg-primary">
                <span class="f-w-900">Code: <span class="f-w-900">{{ order.order_code }}</span></span>
                <span class="f-w-900">Etat: <span v-html="checkStateName(order.order_state)" class="badge badge-light"></span></span>
                <span class="f-w-900">Statut: <span v-html="checkStatusName(order.order_status)" class="badge badge-light"></span></span>
                <span class="f-w-900">Montant Total: <span class="f-w-900">{{ order.total_amount }} FCFA</span></span>
              </div>
            </div>
          </div>
        </div>
        <modal name="update-state" :width="400" :height="200" :adaptive="true">
	        <form @submit.prevent="UpdateState('update-state')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<div class="">
	        					<label for="currentstate">Etat actuel: </label>
	        					<span id="currentstate" class="badge rounded-pill badge-light" v-html="currentstate"></span>
	        				</div>

	        				<div class="mt-1">
	        					<label for="state"></label>
	        					<multiselect v-model="newState" :options="oStates" :hide-selected="false" placeholder="Tous les etats" label="value" track-by="key"></multiselect>
	        				</div>
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('update-state')">Close</button>
		                	<button type="submit" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <modal name="update-status" :width="400" :height="220" :adaptive="true">
	        <form @submit.prevent="UpdateStatus('update-status')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<div class="">
	        					<label for="currentstatus">Statut actuel: </label>
	        					<span id="currentstatus" class="badge rounded-pill badge-light" v-html="currentstatus"></span>
	        				</div>

	        				<div class="mt-1">
	        					<label for="status"></label>
	        					<multiselect v-model="newStatus" :options="oStatus" :hide-selected="false" placeholder="Tous les statuts" label="value" track-by="key"></multiselect>
	        				</div>
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('update-status')">Close</button>
		                	<button type="submit" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	</div>
</template>
<script>

	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	export default {
        props: {
			Partner:Object,
		},
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				filter: new Form({
					account_id: this.Partner,
					order_state:'',
					order_status:'',
					payment_method: '',
					per_page:10
				}),
				oStates:[
					{key: 'paid', value: 'Payee'},
					{key: 'unpaid', value: 'Impayee'},
				],
				oStatus:[
					{key: 'pending', value: 'En attente'},
					{key: 'ongoing', value: 'En cours'},
					{key: 'closed', value: 'Termine'},
				],
				pMethods:[
					{key: 'OM', value: 'Orange Money'},
					{key: 'MM', value: 'Moov Money'},
					{key: 'COD', value: 'Paiement a la livraison'},
				],
				pages: [1,5,10,20,30,40,50,60,70,80,90,100],
				partners:[],
				orders:[],
				orderItems:[],
				order:[],
				currentstate:'',
				newState:'',
				currentstatus:'',
				newStatus:'',
				color: '#168EEA',
			}
		},
        computed: {
            profile: function() {
                return this.Partner.account_profile.toLowerCase();
            }
        },
		methods: {
			editState: function(data, modal) {
				this.order = data;
				this.currentstate = this.checkStateName(data.order_state);
				this.$modal.show(modal);
			},
			editStatus: function(data, modal) {
				this.order = data;
				this.currentstatus = this.checkStatusName(data.order_status);
				this.$modal.show(modal);
			},
			UpdateState: function(modalname) {
				let uri = "/admin/orders/update-state";
				axios.post(uri, {order: this.order.id, state: this.newState}).then(response => {
					Fire.$emit('AfterAction');

					(response.data.success)? this.currentstate = null:null;
					(response.data.success)? this.order = []:null;
					(response.data.success)? this.newState = null:null;
					(response.data.success)? this.closeModal(modalname):null;

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });

				}).catch(err => {
					console.log(err);
				});
			},
			UpdateStatus: function(modalname) {
				let uri = "/admin/orders/update-status";
				axios.post(uri, {order: this.order.id, status: this.newStatus}).then(response => {
					Fire.$emit('AfterAction');

					(response.data.success)? this.currentstatus = null:null;
					(response.data.success)? this.order = []:null;
					(response.data.success)? this.newStatus = null:null;
					(response.data.success)? this.closeModal(modalname):null;

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });

				}).catch(err => {
					console.log(err);
				});
			},
			closeModal: function(name) {
				this.$modal.hide(name);
			},
			showModal: function(name, order) {
				this.loadOrderItems(order);
				this.order = order;
				$(name).modal('show');
			},
			checkStateName: function(state) {
				if(state === 'paid') {
					return "<span class='text-success cursor-pointer badge badge-light'>Payee</span>";
				} else {
					return "<span class='text-danger cursor-pointer'>Impayee</span>";
				}
			},
			checkStatusName: function(status) {
				if(status === 'pending') {
					return "<span class='text-primary cursor-pointer'>En attente</span>";
				} else if(status === 'ongoing') {
					return "<span class='text-warning cursor-pointer'>En cours</span>";
				} else {
					return "<span class='text-danger cursor-pointer'>Termine</span>";
				}
			},
			checkMethodName: function(method) {
				if(method === 'OM') {
					return "<span class='badge badge-primary'>Orange Money</span>";
				} else if(method === 'MM') {
					return "<span class='badge badge-primary'>Moov Money</span>";
				} else {
					return "<span class='badge badge-primary'>Paiement a la livraison</span>";
				}
			},
			loadOrders: function(page = 1) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement'});
				let uri = "/"+this.profile+"/orders/load-orders?page="+page;

				this.filter.post(uri).then(response => {
					loading.close();
					this.orders = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadOrderItems: function(order) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement du panier'});
				let uri ="/"+this.profile+"/orders/items-by-order/"+order.order_code;

				axios.get(uri).then(response => {
					loading.close();
					this.orderItems = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			}
		},
		created: function() {
			this.loadOrders();

			Fire.$on('AfterAction', () => {
				this.loadOrders();
			});
		},
		mounted(){
			console.log('test');
		}
	}
</script>
<style>
	.cursor-pointer{
		cursor:pointer;
	}
</style>