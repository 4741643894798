<template>
	<div>
		<div class="row starter-main">
			<form @submit.prevent="doSupply">
				<div class="card bg-primary" style="border-radius: 10px;">
					<div class="card-body pt-3 pb-0">
						<div class="row">
							<!-- <div class="col-sm-3">
								<div class="form-group">
									<label for="profile">Type de produit</label>
									<select v-model="form.profile" id="profile" class="form-select digits" @change="loadPartners">
								  		<option value="">Type</option>
										<option value="Pharmacy"> Produit de Pharmacie </option>
			                        	<option value="Supermarket">  Produit de Supermarche </option>
			                        </select>
								</div>
							</div>
							<div class="col-sm-3">
								<div class="form-group">
									<label for="account">Comptes Partenaires</label>
									<select v-model="form.account_id" id="account" class="form-select digits" @change="loadProducts">
										<option value="">Compte partenaire</option>
										<option v-for="(item, index) in partners" :key="index" :value="item.id"> {{ item.name }} </option>
									</select>
								</div>
							</div> -->
							<div class="col-sm-3 text-center">
								<div class="form-group">
									<label for="have-variation">Produit avec variation ?</label>

									<div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
										<label class="" for="yes">
				                          <input v-model="form.have_variation" class="radio_animated" id="yes" type="radio" name="rdo-ani" :value="true" @change="loadProducts">Oui
				                        </label>
				                        <label class="" for="no">
				                          <input v-model="form.have_variation" class="radio_animated" id="no" type="radio" name="rdo-ani" :value="false" @change="loadProducts">Non
				                        </label>
	                        		</div>
								</div>
							</div>
							<div class="col-sm-3" v-if="form.have_variation">
								<div class="form-group">
									<label for="product">Choisissez le produit</label>
                                    <multiselect v-model="variable_product" :options="products" :hide-selected="false" placeholder="Tous les produits" label="product" track-by="id" @input="loadCombinations"></multiselect>
									<!-- <select v-model="form.variable_product_id" id="product" class="form-select digits" @change="loadCombinations">
										<option value="">Produit</option>
										<option v-for="(item, index) in products" :key="index" :value="item.id"> {{ item.product }} </option>
									</select> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" style="border-radius: 10px ;" v-if="form.have_variation">
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>#</th>
										<th></th>
										<th>Combinaison</th>
										<th>Quantite existante</th>
										<th>Nouvelle Quantite</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(addTd, index) in addCombs" :key="index">
										<td class="align-middle"><b>{{ index }}</b></td>
										<td class="align-middle">
											<div class="media">
												<img class="img-fluid rounded-circle border-light"  :src="combinpath+addTd.combination_image" width="30" height="30" alt="">
											</div>
										</td>
										<td class="align-middle">
											{{ addTd.combinationName }}
											<input type="hidden" v-model="addTd.combinationName">
											<input type="hidden" v-model="addTd.id">
										</td>
										<td class="align-middle">
											<input type="text" v-model="addTd.quantity" class="form-control" disabled>
										</td>
										<td class="align-middle">
											<input type="number" class="form-control form-control" v-model="addTd.newquantity">
										</td>
										<td class="align-middle">
											<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteCombRow(index,addTd)"><feather type="x-circle" size="20"></feather></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="card" style="border-radius: 10px ;" v-if="!form.have_variation">
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>#</th>
										<th></th>
										<th>Produit</th>
										<th>Quantite existante</th>
										<th>Nouvelle Quantite</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(addTd, index) in addRows" :key="index">
										<td class="align-middle"><b>{{ index }}</b></td>
										<td class="align-middle">
											<div class="media">
												<img class="img-fluid rounded-circle border-light"  :src="partnerproductimgpath+addTd.partner_product_image" width="30" height="30" alt="">
											</div>
										</td>
										<td class="align-middle">
											{{ addTd.product }}
											<input type="hidden" v-model="addTd.product">
											<input type="hidden" v-model="addTd.id">
										</td>
										<td class="align-middle">
											<input type="text" v-model="addTd.quantity" class="form-control" disabled>
										</td>
										<td class="align-middle">
											<input type="number" class="form-control form-control" v-model="addTd.newquantity">
										</td>
										<td class="align-middle">
											<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteRow(index,addTd)"><feather type="x-circle" size="20"></feather></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="card-footer border-0 text-end mb-5" style="background-color:transparent !important;">
					<button class="btn btn-primary" type="submit">Enregistrer les products</button>
                      <input class="btn btn-outline-primary" type="reset"  value="Annuler">
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	export default {
        props: {
			Partner:Object,
		},
		components:{
			Button, HasError, AlertError
		},

		data: function() {

			return {
				form: new Form({
					profile: this.Partner.account_profile,
					account_id: this.Partner.id,
					have_variation:false,
					variable_product_id:'',
					products:[],
					combinations:[]
				}),
                variable_product:[],
				partners:[],
				products:[],
				combinations:[],
				addCombs:[],
				addRows:[],
				basePath: Laravel.baseUrl+'medias/product_image/',
				combinpath: Laravel.baseUrl+'medias/combination_image/',
				partnerproductimgpath: Laravel.baseUrl+'medias/partner_product_image/',
			}
		},
        computed: {
            profile: function() {
                return this.Partner.account_profile.toLowerCase();
            }
        },
		methods: {
			addRow() {
		        this.addRows.push({
		            product:null, id:null, quantity:null, newquantity:null
		        });
	        },
			deleteRow(index, addTd) {
	            var idx = this.addRows.indexOf(addTd);
	            if (idx > -1) {
	                this.addRows.splice(idx, 1);
	            }
	        },
			addComb() {
		        this.addCombs.push({
		            combinationName:null, id:null, quantity:null, newquantity:null
		        });
	        },
			deleteCombRow(index, addTd) {
	            var idx = this.addCombs.indexOf(addTd);
	            if (idx > -1) {
	                this.addCombs.splice(idx, 1);
	            }
	        },
			loadPartners: function() {
				
				const loading = this.$vs.loading({type:'points'});
				let uri = "/admin/accounts/get-partners/"+this.form.profile;
				axios.get(uri).then(response => {
					(response.data)? loading.close():null;
					this.partners = response.data;
				});
			},
			loadProducts: function() {
				const loading = this.$vs.loading({type:'points'});
				let uri = "/"+this.profile+"/supplies/get-products";
				this.form.post(uri).then(response => {
					(response.data)? loading.close():null;
					this.products = response.data;
					this.addRows = response.data;
				});
			},
			loadCombinations: function() {
				const loading = this.$vs.loading({type:'points'});
                this.form.variable_product_id = this.variable_product.id;
				let uri = "/"+this.profile+"/supplies/get-combinations";
				this.form.post(uri).then(response => {
					(response.data)? loading.close():null;
					this.combinations = response.data;
					this.addCombs = response.data;
				});
			},
			doSupply: function() {
				const loading = this.$vs.loading({type:'points'});
				let uri = "/"+this.profile+"/supplies/make-supply";
				var addRows = _.map(this.addRows, function(num) {
					return _.pick(num, "id", "product", "quantity", "newquantity")
				});
				var addCombs = _.map(this.addCombs, function(num) {
					return _.pick(num, "id", "combinationName", "quantity", "newquantity")
				});
				this.form.products = addRows;
				this.form.combinations = addCombs;

				this.form.post(uri).then(response => {
					if(response.data.success) {
						this.form.reset();
						this.form.combinations = [];
						this.form.products = [],
						this.addRows = [];
						this.addCombs = [];
						loading.close();
					}

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                });
				});
			}
		},
		created: function () {
            this.loadProducts();
		}, 
		mounted: function() {

		}
	}
</script>