<template>
	<div>
		<div class="row">
			<div class="card">
				<div class="card-body">
					<div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<select v-model="filter.per_page" class="form-select digits" @change="loadOrderItems">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
		                    	<multiselect v-model="filter.order_state" :options="oStates" :hide-selected="false" placeholder="Tous les etats" label="value" track-by="key" @input="loadOrderItems"></multiselect>
		                    </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
		                    	<multiselect v-model="filter.order_status" :options="oStatus" :hide-selected="false" placeholder="Tous les statuts" label="value" track-by="key" @input="loadOrderItems"></multiselect>
		                    </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
		                    	<multiselect v-model="filter.payment_method" :options="pMethods" :hide-selected="false" placeholder="Tous les moyens" label="value" track-by="key" @input="loadOrderItems"></multiselect>
		                    </div>
                        </div>
						<div class="col-sm-12 col-md-2 col-lg-2 align-middle">
                            <!-- <div class="form-group"> -->
                                <div class="setting-list align-middle">
                                    <ul class="list-unstyled setting-option align-middle">
                                        <li>
                                            <div class="setting-primary"><i class="icon-settings"></i></div>
                                        </li>
                                        <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
                                        <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
                                    </ul>
                                </div>
                            <!-- </div> -->
                        </div>
					</div>
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th class="bg-light">#</th>
									<!-- <th>Image</th> -->
									<th>Produit</th>
									<th>Client</th>
									<th>Qte</th>
									<th class="bg-light">P.U</th>
									<th class="bg-light">P.T</th>
									<th>Moyen de paiement</th>
									<th>Etat</th>
									<th>Statut</th>
									<th>Date</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in orderItems.data" :key="index">
										
									<td class="align-middle f-w-800 bg-light">{{ item.order_code }}</td>
									<td class="align-middle">
										<div class="media" style="vertical-align: middle;">
											<img class="img-fluid rounded-circle" :src="baseUrl+'medias/combination_image/'+item.image" alt="" width="40" height="40" title="" v-if="item.product_combination_id !== null">
											<img class="img-fluid rounded-circle mr-1" :src="baseUrl+'medias/partner_product_image/'+item.image" alt="" width="40" height="40" title="" v-else>
                                    		<div class="media-body">
                                    			<a href="#" class="text-dark">
                                    				<span style="margin-left:10px; vertical-align: middle;">{{ item.name }}</span>
                                    			</a>
                                    		</div>
                                  		</div>
									</td>
									<td class="align-middle">{{ item.client_name }} {{ item.client_last_name }}</td>
									<!-- <td class="align-middle"> {{ item.name }} </td> -->
									<td class="align-middle">{{ item.quantity }}</td>
									<td class="align-middle bg-light f-w-800">{{ item.unit_price }} F</td>
									<td class="align-middle bg-light f-w-800">{{ item.quantity * item.unit_price }} F</td>
									<td class="align-middle" v-html="checkMethodName(item.payment_method)"></td>
									<td class="align-middle" v-html="checkStateName(item.order_state)"></td>
									<td class="align-middle" v-html="checkStatusName(item.order_status)"></td>
									<td class="align-middle">{{ item.created_at | moment("DD-MM-YYYY") }}</td>
									<td class="align-middle">
										<!-- <span style="cursor: pointer;" class="m-r-1" @click="showModal('#order-item', item)">
											<feather type="shopping-cart" size="13"></feather>
										</span> -->

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
                    						<feather type="trash-2" size="13"></feather>
                    					</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-3">
                    	<pagination :data="orderItems" @pagination-change-page="loadOrderItems">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	export default {
        props: {
			Partner:Object,
		},
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				baseUrl: Laravel.baseUrl,
				filter: new Form({
					account_id: this.Partner,
					order_state:'',
					order_status:'',
					payment_method: '',
					per_page:10
				}),
				oStates:[
					{key: 'paid', value: 'Payee'},
					{key: 'unpaid', value: 'Impayee'},
				],
				oStatus:[
					{key: 'pending', value: 'En attente'},
					{key: 'ongoing', value: 'En cours'},
					{key: 'closed', value: 'Termine'},
				],
				pMethods:[
					{key: 'OM', value: 'Orange Money'},
					{key: 'MM', value: 'Moov Money'},
					{key: 'COD', value: 'Paiement a la livraison'},
				],
				pages: [1,5,10,20,30,40,50,60,70,80,90,100],
				partners:[],
				orderItems:{},
				color: '#168EEA',
			}
		},
        computed: {
            profile: function() {
                return this.Partner.account_profile.toLowerCase();
            }
        },
		methods: {
			checkStateName: function(state) {
				if(state === 'paid') {
					return "<span class='text-success cursor-pointer badge badge-light'>Payee</span>";
				} else {
					return "<span class='text-danger cursor-pointer'>Impayee</span>";
				}
			},
			checkStatusName: function(status) {
				if(status === 'pending') {
					return "<span class='text-primary cursor-pointer'>En attente</span>";
				} else if(status === 'ongoing') {
					return "<span class='text-warning cursor-pointer'>En cours</span>";
				} else {
					return "<span class='text-danger cursor-pointer'>Termine</span>";
				}
			},
			checkMethodName: function(method) {
				if(method === 'OM') {
					return "<span class='badge badge-primary'>Orange Money</span>";
				} else if(method === 'MM') {
					return "<span class='badge badge-primary'>Moov Money</span>";
				} else {
					return "<span class='badge badge-primary'>Paiement a la livraison</span>";
				}
			},
			loadOrderItems: function(page = 1) {
				const loading = this.$vs.loading({type:'square', color: this.color, text: 'Chargement'});
				let uri = "/"+this.profile+"/orders/load-order-items?page="+page;

				this.filter.post(uri).then(response => {
					loading.close();
					this.orderItems = response.data;
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
		},
		created: function() {
			this.loadOrderItems();
		}
	}
</script>