<template>
	<div>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-12">
	            <div class="card">
	                <div class="card-header pb-0">
	                    <div class="setting-list">
	                        <ul class="list-unstyled setting-option">
	                            <li>
	                                <div class="setting-primary"><i class="icon-settings"></i></div>
	                            </li>
	                            <li><i class="view-html fa fa-code font-primary"></i></li>
	                            <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
	                            <li><i class="icofont icofont-minus minimize-card font-primary"></i></li>
	                            <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
	                            <li><i class="icofont icofont-error close-card font-primary"></i></li>
	                        </ul>
	                    </div>
	                </div>
	                <div class="card-body">
	                    <div class="table-responsive">
	                    	<div class="row flex-lg-row">
	                    		<div class="col-sm-12 col-lg-1">
	                    			<div class="form-group">
	                    				<select v-model="search.per_page" class="form-control" @change="loadRequests">
	                    					<option v-for="(item, i) in numbers" :key="i" :value="item">{{ item }}</option>
	                    				</select>
	                    			</div>
	                    		</div>
	                    		<div class=""></div>
	                    	</div>
	                    	<table class="table table-bordered">
	                    		<thead>
	                    			<tr>
	                    				<th>#</th>
	                    				<th>CLient</th>
	                    				<th>Besoin</th>
	                    				<th>Etat</th>
	                    				<th>Creation</th>
	                    				<th>...</th>
	                    			</tr>
	                    		</thead>
	                    		<tbody>
	                    			<tr v-for="(item, index) in requestsList.data" :key="index">
	                    				<td class="align-middle">
	                    					{{ item.id }}
	                    				</td>
	                    				<td class="align-middle">
	                    					{{ item.requestor_name }}
	                    					<div>
	                    						<span class="badge rounded-pill badge-light text-primary"> 
	                    							{{ item.city.name }} / {{ item.zone.name }} 
	                    						</span>
	                    						<span class="badge rounded-pill badge-light text-dark"> 
	                    							Tel: {{ item.requestor_number }}
	                    						</span>
	                    					</div>
	                    				</td>
	                    				<td>
	                    					<span class="f-w-800 text-secondary"> {{ item.service_type }} :</span>
	                    					<span class="badge rounded-pill badge-warning text-dark" v-for="(service,i) in item.requestservices" :key="i"> {{ service.service_name }} </span>
	                    					<div class="bg-light p-3 b-r-5 mt-2">
	                    						{{ item.request_description }}
	                    					</div>
	                    				</td>
	                    				<td class="align-middle">
	                    					<span class="span badge rounded-pill pill-badge-primary" v-if="item.request_state == 'pending'" style="cursor:pointer;" @click="editState(item, 'update-state')"> 
	                    						En attente 
	                    					</span>
	                    					<span class="span badge rounded-pill pill-badge-warning" v-else-if="item.request_state == 'ongoing'" style="cursor:pointer;" @click="editState(item, 'update-state')"> 
	                    						Traitement en cours 
	                    					</span>
	                    					<span class="span badge rounded-pill pill-badge-danger" v-else style="cursor:pointer;" @click="editState(item, 'update-state')"> 
	                    						Terminee 
	                    					</span>	
	                    				</td>
	                    				
	                    				<td class="align-middle">{{ item.created_at | moment("from", "now")}}</td>
	                    				<td class="align-middle">
	                    					
	                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteRequest(item)" v-if="$can('delete-option')">
	                    						<feather type="trash-2" size="13"></feather>
	                    					</span>
	                    				</td>
	                    			</tr>
	                    		</tbody>
	                    	</table>
	                    	
	                    </div>
	                    <div class="mt-3">
	                    	<pagination :data="requestsList" @pagination-change-page="loadRequests">
	                    		<span slot="prev-nav">&lt; Precedent</span>
								<span slot="next-nav">Suivant &gt;</span>
	                    	</pagination>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
		<modal name="update-state" :width="400" :height="200" :adaptive="true">
	        <form @submit.prevent="UpdateState('update-state')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<div class="">
	        					<label for="currentstate">Etat actuel: </label>
	        					<span id="currentstate" class="badge rounded-pill badge-primary" v-html="currentstate"></span>
	        				</div>

	        				<div class="mt-1">
	        					<label for="state"></label>
	        					<select v-model="newState" class="form-select digits" id="state" required>
	        						<option value="null" selected>Changer d'etat</option>
	        						<option v-for="(state, i) in states" :key="i" :value="state.key"> {{ state.value }} </option>
	        					</select>
	        				</div>
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('update-state')">Close</button>
		                	<button type="submit" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <v-dialog />
	</div>
</template>
<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	import Permissions from '../mixins/permissions.vue';
	export default {
		mixins: [Permissions],
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				requestsList:{},
				numbers:[1,5,10,15,20,30,40,50,60,70,80,90,100],
				states:[
				{key: "pending", value:"En attente"},
				{key: "ongoing", value:"Traitement en cours"},
				{key: "closed", value:"Terminee"},
				],
				search: new Form({
					per_page:10
				}),
				currentstate:null,
				newState:null,
				currentItem:[],
			}
		},
		methods: {
			loadRequests: function(page = 1) {
				this.search.post('/admin/service-request/request-list?page='+page)
				.then(({data}) => {
					this.requestsList = data;
				});
			},
			editState: function(item, modalname) {
				this.currentItem = item;
				this.currentstate = this.checkState(item.request_state);
				this.$modal.show(modalname);
			},
			closeModal: function(name) {
				this.$modal.hide(name);
			},
			checkState: function(state) {
				if(state == 'pending') {
					return "En attente";
				} else if(state == 'ongoing') {
					return "Traitement en cours";
				} else {
					return "Terminee";
				}
			},
			UpdateState: function(name) {
				let uri = "/admin/service-request/update-state/"+this.currentItem.id;
				axios.put(uri, {state:this.newState}).then(response => {
					Fire.$emit('AfterAction');

					(response.data.success)? this.currentstate = null:null;
					(response.data.success)? this.currentItem = []:null;
					(response.data.success)? this.newState = null:null;
					(response.data.success)? this.closeModal(name):null;

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
			},
			deleteRequest: function(item) {

				let uri = "/admin/service-request/delete/"+item.id;
				//this.form.fill(item);

				this.$modal.show('dialog', {
				  title: 'Suppression de demande de service',
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette demande?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        axios.delete(uri).then(response => {
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},
		},
		created: function() {
			this.loadRequests();
			Fire.$on('AfterAction', () => {
				this.loadRequests();
			});
		}
	}
</script>