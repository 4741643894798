<template>
	<div>
		<div class="row">
			<div class="col-xl-3 col-sm-6 xl-3 col-6" v-for="(item, index) in partners" :key="index">
	            <div class="card">
	              <div class="product-box">
	                <div class="product-img">
	                  <div class="ribbon ribbon-success ribbon-right" v-if="checkDayAndHour(item.servicedays)">Ouvert</div>
	                  <div class="ribbon ribbon-danger ribbon-right" v-else>Ferme</div>
	                  <img class="img-fluid" :src="baseUrl+'medias/account_picture/homesize/'+item.account_picture" alt="">
	                  <div class="product-hover">
	                    <ul>
	                      <li @click="toDetails(item)"><a href="#"><i class="icon-eye"></i></a></li>
	                    </ul>
	                  </div>
	                </div>
	                <div class="product-details"><a href="#" @click="toDetails(item)" data-bs-original-title="" title="">
	                    <h4 style="font-size: 15px;">{{ item.name }}</h4></a>
	                  <p style="font-size: 12px;">{{ currentDay(item.servicedays) }}</p>
	                  <!-- <div class="product-price">$38.00
	                    <del>$45.00 </del>
	                  </div> -->
	                </div>
	              </div>
	            </div>
	         </div>
		</div>
	</div>
</template>

<script>

	import moment from 'moment';
	import { mapActions, mapState } from 'vuex';
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

	export default {
		props:{
			partners: Array,
		},
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				baseUrl:Laravel.baseUrl,
				color: '#168EEA',
			}
		},
		methods: {
			toDetails: function(item) {
				const loading = this.$vs.loading({type:'square', color: this.color});
				$("#partner-detail #partner").val(item.id);
				document.getElementById('partner-detail').submit();

			},
			checkDayAndHour: function(servicedays) {
				
				var isOpened = false;
				Object.keys(servicedays).forEach(key => {

					if(servicedays[key].opentoday) {

						if(servicedays[key].stillopen) {
							isOpened = true;
						}
					}
				});

				return isOpened;
			},

			currentDay: function(servicedays) {
				
				var result = "N'ouvre pas aujourd'hui";

				Object.keys(servicedays).forEach(key => {
					
					if(servicedays[key].opentoday) {
						var openHour = moment(servicedays[key].openHour, 'HH:mm:ss').format('HH:mm');
						var closeHour = moment(servicedays[key].closeHour, 'HH:mm:ss').format('HH:mm');

						result = "Ouvre a: "+openHour+" Ferme a: "+closeHour;
					}
				});
				return result;
			}
		},
		created: function() {

		},
		mounted: function() {
			moment().locale('fr');
			// var days = moment().format('dddd');
			// var hour = moment().format('HH:mm');
		}
	}
</script>