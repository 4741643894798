<template>
	<div>
		<div class="row starter-main bg-white pt-5">
			<div class="col-xl-12 col-sm-12 col-lg-12">
				<div class="card pt-4 bg-primary align-middle">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-1 col-sm-12 xl-2"></div>
							<div class="col-lg-10 col-sm-12 xl-8">
								<ol class="breadcrumb mt-0 mt-sm-0 mt-md-3 mt-lg-3 mt-xl-3 mb-0 p-b-0 pl-5" style="overflow:auto; white-space: nowrap; text-overflow: ellipsis;">
			                      <li class="breadcrumb-item"><a :href="'/'" class="text-white" data-bs-original-title="" title=""><i class="fa fa-home"></i></a></li>
			                      <li class="breadcrumb-item f-w-800">Partenaires</li>
			                      <li class="breadcrumb-item f-w-800">Produits</li>
			                      <li class="breadcrumb-item active text-white f-w-800">Details</li>
			                    </ol>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row pt-3 pb-5 bg-white">
			<div class="col-lg-2 col-sm-12"></div>
			<div class="col-lg-8 col-sm-12 p-4 p-sm-4 p-md-none p-lg-none p-xl-none">
				<form @submit.prevent="checkProductBeforeAddToCart">
					<div class="product-box row">
		              <div class="product-img  col-lg-5 p-1 b-r-3" v-if="combinationLoaded == false">
		              	<img class="img-fluid" :src="baseUrl+'medias/partner_product_image/'+productItem.partner_product_image" alt="" v-if="productItem.partner_product_image !== null">
		              	<img class="img-fluid" :src="baseUrl+'medias/product_image/'+productItem.product_image" alt="" v-else>
		              </div>
		              <div class="product-img col-lg-5 b-r-3" id="product-img" v-if="combinationLoaded == true">
		              	<img class="img-fluid" :src="baseUrl+'medias/combination_image/'+productItem.combination_image" alt="">
		              </div> 
		              <div class="product-details col-lg-7 col-12 p-sm-5 text-start  border-1 b-light">
		              	<a href="#" data-bs-original-title="" title="">
		                  <h4>{{ productItem.product }} <span v-if="combinationLoaded"> {{ productItem.combinationName }} </span></h4>
		                  <span class="badge rounded-pill badge-warning" v-if="productItem.is_under_prescription"> Délivrable uniquement sous prescription medicale</span>
		              	</a>
		                  <p><span class="text-warning"><b>{{ productItem.category }}</b></span>: {{ productItem.subcategory }}</p>
		                <div class="product-price">
		                	<b v-if="!combinationLoaded">{{ productItem.price }} FCFA</b>
		                	<b v-else>{{ productItem.combinantionPrice }} FCFA</b>
		                </div><div class="dropdown-divider mt-3 mb-3"></div>
		                <div class="product-view">
		                  <h6 class="f-w-600">Petite Description</h6>
		                  <p class="mb-0" v-html="productItem.description"></p>
		                  <vs-alert :color="alertcolor" :hidden-content.sync="hidden" v-if="productItem.is_under_prescription">
						      <template #title style="font-size: 14px;">
						        Délivré sous prescription medicale
						      </template>
						      <p style="font-size: 12px;">Ce produit n'est delivree que sous prescription medicale. Assurrez-vous d'avoir un scan de l'ordonnance car il vous sera demander lors de la validation de votre commande.</p>
						  </vs-alert>
		                </div>
		                <div class="dropdown-divider mt-3 mb-3" v-if="productItem.have_variation"></div>
		                <div class="product-size" v-if="productItem.have_variation">
		                  <h6 class="f-w-600">Variations</h6>
		                	<div class="row" v-for="(item, index) in pAttributes" :key="index">
		                		<div class="col-sm-6 col-xl-3">
		                			<label for="item.variationName"> {{ item.variationName }} </label>
		                		</div>
		                		<div class="col-sm-6 col-xl-5">
		                			<div class="form-group">
		                				<select v-model="item.option" id="item.variationName" class="form-control" @change="loadCombination" required>
		                    				<option value="">Choix de l'option</option>
		                    				<option v-for="(opt, index) in item.productoptions" :key="index" :value="opt.variationOptionName"> {{ opt.variationOptionName }} </option>
		                    			</select>
		                			</div>
		                		</div>
		                	</div>
		                </div><div class="dropdown-divider mt-3 mb-3"></div>
		                <div class="product-qnty mt-3">
		                  <h6 class="f-w-600">Quantité</h6>
		                 
		                  <div class="row">
		                  	<div class="col-sm-12 col-md-5">
		                  		<fieldset>
				                    <div class="input-group bootstrap-touchspin">
				                      <button class="btn btn-primary btn-square bootstrap-touchspin-down" type="button" data-bs-original-title="" title="" @click="decrement(productItem, productItem.quantity)">
				                      	<i class="fa fa-minus"></i>
				                      </button>
				                      <span class="input-group-text bootstrap-touchspin-prefix" style="display: none;"></span>
				                      	<input class="touchspin text-center form-control" type="number" min="1" v-model="productItem.quantity" style="display: block;" data-bs-original-title="" title="" @input="checkvalue(productItem.quantity, productItem)">
				                      <span class="input-group-text bootstrap-touchspin-postfix" style="display: none;"></span>
				                      <button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" data-bs-original-title="" title="" @click="increment(productItem, productItem.quantity)"><i class="fa fa-plus"></i>
				                      </button>
				                    </div>
				                </fieldset>
		                  	</div>
		                  </div>
		                  <div class="addcart-btn mt-lg-5 mt-3 d-flex">
		                  	<button class="btn btn-primary" type="submit" style="margin-right: 25px;">Ajouter au 
		                  		<i class="icon-shopping-cart"></i>
		                  	</button>
		                  	<a class="btn btn-light" :href="'/carts/panier-d-achat'">	
		                  		<i class="icon-eye"></i>Panier
		                  	</a>
		                  </div>
		                </div>
		              </div>
	            	</div>
	            </form>
			</div>
		</div>
	</div>
</template>
<script>
	import EventBus from './EventBus.js';
	import LoadCart from '../loadCart.js';
import { reactive } from 'vue';
	export default {
		mixins: [LoadCart],
		props: {
		 details: Object,
		 productdetails: Object,
		 //cart: Array, 
		},
		data: function() {
			return {
				//carts: this.cart,
				productItem: this.productdetails,
				partnerDetail: this.details,
				baseUrl: Laravel.baseUrl,
				combinationLoaded: false,
				pAttributes:[],
				product:[],
				color:'#168EEA',
				hidden:false,
				alertcolor: 'warn',
				page:'product-details',
				// style: this.styles
			}
		},

		methods: {
			displayProductDetails: function(item) {
				//(this.product.length > 0)? this.product = []:null;
				(this.combinationLoaded)? this.combinationLoaded = false:null;
				
				
				this.loadTotalQuantity(item);
				Vue.set(item, 'quantity', 1);
				this.productItem = item;

				if(item.have_variation == 1) {
					const loading = this.$vs.loading({type:'square', color: this.color});
					let uri = "/partenaires/products/attribute/"+item.id;
					axios.get(uri).then(response => {
						loading.close();
						this.pAttributes = response.data;
						//$("#exampleModalCenter3").modal('show');
					});
				} else {
					//$("#exampleModalCenter3").modal('show');
				}
			},
		},

		created: function() {
			this.displayProductDetails(this.productItem);
		},
		mounted: function() {}

	}


</script>