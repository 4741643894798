<template>
	<div>
		<div class="row starter-main">
			<div class="card">
				<div class="card-body">
                    <div class="row justify-content-between">
						<div class="col-sm-12 col-lg-1 col-md-1">
							<div class="form-group">
								<select v-model="filter.per_page" class="form-select digits form-control-lg" @change="loadProducts">
									<option v-for="(page, index) in pages" :key="index" :value="page"> {{ page }}</option>
								</select>
							</div>
						</div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <multiselect v-model="filter.category" :options="categories" :hide-selected="false" placeholder="Toutes categories" label="name" track-by="id" @input="loadSubCategories"></multiselect>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <multiselect v-model="filter.sub_category" :options="subcategories" :hide-selected="false" placeholder="Toutes sous-categories" label="name" track-by="id" @input="loadProducts"></multiselect>
                            </div>
                        </div>
						<div class="col-sm-12 col-md-5 col-lg-5">
                            <div class="form-group"></div>
                        </div>
					</div>
					<div class="table-responsive">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th></th>
									<th>Nom du produit</th>
									<th>Categorie</th>
									<th>Sous-categorie</th>
									<th>Prix</th>
									<th v-if="Partner.account_profile !='Restaurant'">Quantite</th>
									<th>Produit variable?</th>
									<th>Etat</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in partnerProducts.data" :key="index">
									<td class="align-middle">
										<b>{{ index }}</b>
									</td>
									<td>
										<div class="media">
											<img class="img-fluid rounded-circle border-light"  :src="basePath+item.image" width="30" height="30" alt="" v-if="item.partner_product_image == null">
											<img class="img-fluid rounded-circle border-light"  :src="partnerproductimgpath+item.partner_product_image" width="30" height="30" alt="" v-if="item.partner_product_image != null">
										</div>
									</td>
									<td class="align-middle"><b>{{ item.product }}</b></td>
									<td class="align-middle">{{ item.category }}</td>
									<td class="align-middle">{{ item.subcategory }}</td>
									<td class="align-middle">{{ item.price }} FCFA</td>
									<td class="align-middle" v-if="Partner.account_profile !='Restaurant'">
										{{ item.quantity }}
									</td>
									<td class="align-middle">
				                        <div class="switch-sm icon-state">
				                          <label class="switch">
				                            <input type="checkbox" :checked="item.have_variation" disabled><span class="switch-state bg-outline-primary"></span>
				                          </label>
				                          <div class="f-16 span badge rounded-pill pill-badge-secondary" v-if="item.have_variation" style="cursor: pointer;" @click="showCombinations(item)">
				                          	<b>Les Combinaisons</b>
				                          </div>
				                        </div>
									</td>
									<td>
										<vs-button-group v-if="item.is_on">
									        <vs-button  class="bg-primary"> 
									        	<feather type="check" size="15"></feather>
									        </vs-button>
									        <vs-button class="bg-light" @click="changeState(item, false)"> 
									        	<span class="small">Desactiver</span>
									        </vs-button>
									    </vs-button-group>
									    <vs-button-group v-else>
									        <vs-button  class="bg-danger"> <feather type="x-circle" size="15"></feather>
									        </vs-button>
									        <vs-button class="bg-light" @click="changeState(item, true)"> 
									        	<span class="small">Activer</span>
									        </vs-button>
									    </vs-button-group>
									</td>
									<td class="align-middle">
										<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-product', item)">
											<feather type="edit" size="13"></feather>
										</span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
                    						<feather type="trash-2" size="13"></feather>
                    					</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
                    <div class="mt-3">
                    	<pagination :data="partnerProducts" @pagination-change-page="loadProducts">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div>
				</div>
			</div>
		</div>
		<modal name="show-combinations" :width="850" :height="auto" :resizable="true" :draggable="true">
	        <div class="card m-15" style="border-radius: 10px;">
	        	<div class="card-body">
	        		<div class="table-responsive">
	        			<table class="table table-bordered">
	        				<thead>
	        					<tr>
	        						<th>#</th>
	        						<th></th>
	        						<th>Combinaison</th>
	        						<th>Quantite</th>
	        						<th>Prix</th>
	        						<th>...</th>
	        					</tr>
	        				</thead>
	        				<tbody>
	        					<tr v-for="(item, index) in combinations" :key="index">
	        						<td class="align-middle"><b>{{ index }}</b></td>
	        						<td>
										<div class="media">
											<img class="img-fluid rounded-circle border-light"  :src="combinpath+item.combination_image" width="30" height="30" alt="">
										</div>
									</td>
	        						<td class="align-middle"><b>{{ item.combinationName }}</b></td>
	        						<td class="align-middle">{{ item.quantity }}</td>
	        						<td class="align-middle">{{ item.combinantionPrice }} FCFA</td>
	        						<td class="align-middle">
	        							<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-combination', item)">
											<feather type="edit" size="13"></feather>
										</span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteCombination(item)">
                    						<feather type="trash-2" size="13"></feather>
                    					</span>
	        						</td>
	        					</tr>
	        				</tbody>
	        			</table>
	        		</div>
	        	</div>
	        </div>
	    </modal>
	    <modal name="edit-product" :width="700" :height="360" :resizable="true" :draggable="true">
	        <form @submit.prevent="UpdateProduct('edit-product')" class="p-4">
	        	<div class="row">
	        		<div class="col-sm-6">
	        			<div class="form-group">
	        				<label for="name">Nom du produit</label>
	        				<input type="text" v-model="productform.product" id="name" class="form-control" name="product">
                            <HasError :form="productform" field="product" />
	        			</div>
	        			<div class="form-group" v-if="profile != 'restaurant'">
	        				<label for="quantity">Quantite</label>
	        				<input type="number" v-model="productform.quantity" id="quantity" class="form-control" name="quantity" disabled v-if="productform.have_variation == false && productform.product_type != 'restaurant'">
	        				<input type="number" v-model="productform.quantity" id="quantity" class="form-control" name="quantity" disabled v-if="productform.have_variation == true || productform.product_type =='restaurant'">
	        				<HasError :form="productform" field="quantity" />
	        			</div>
	        			<div class="form-group">
	        				<label for="price">Prix</label>
	        				<input type="number" v-model="productform.price" id="price" class="form-control" name="price">
	        				<HasError :form="productform" field="price" />
	        			</div>
	        		</div>
	        		<div class="col-sm-6">
	        			<!-- <div class="form-group  text-center">
	        				<img :src="basePath+productform.image" class="align-middle" width="150" height="150" alt="" v-if="productform.partner_product_image == null">
	        				<img :src="partnerproductimgpath+productform.partner_product_image" class="align-middle" width="150" height="150" alt="" v-else>
	        			</div>
	        			<div class="form-group">
	        				<label for="image">Image</label>
	        				<input type="file" name="partner_product_image" @change="onFileChange">
	        			</div> -->
						<picture-input 
							ref="pictureInput"
							width="400" 
							height="250" 
							margin="0" 
							accept="image/jpeg,image/png, image/webp" 
							size="10"
							z-index="0" 
							:prefill="getFilePath(productform.partner_product_image != null ? productform.partner_product_image: productform.image, productform.partner_product_image != null ? true: false)"
							:prefill-options="{fileName: productform.partner_product_image != null ? productform.partner_product_image: productform.image, fileType:getFileType(productform.partner_product_image != null ? productform.partner_product_image: productform.image), mediaType: getMediaType(productform.partner_product_image != null ? productform.partner_product_image: productform.image)}"
							button-class="btn"
							:removable="true"
							:custom-strings="{
								upload: '<h1>Bummer!</h1>',
								drag: 'Selectionner une image',
								change: 'Changer',
								remove: 'Supprimer', 
							}"
							@change="onChange">
						</picture-input>
	        		</div>
        			<div class="col-12">
        				<hr>
        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-product')">Close</button>
	                	<button type="submit" :disabled="productform.busy" class="btn btn-primary">Mettre a jours</button>
        			</div>

	        	</div>
	        </form>
	    </modal>
	    <modal name="edit-combination" :width="400" :height="330" :adaptive="true">
	        <form @submit.prevent="UpdateCombination('edit-combination')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div class="form-group">
	        				<label for="name">Combinaison</label>
	        				<input type="text" v-model="combin.combinationName" id="name" class="form-control" name="combinationName" disabled>
                            <HasError :form="combin" field="combinationName" />
	        			</div>
	        			<div class="form-group">
	        				<label for="quantity">Quantite</label>
	        				<input type="number" v-model="combin.quantity" id="quantity" class="form-control" name="quantity" disabled>
	        				<HasError :form="combin" field="quantity" />
	        			</div>
	        			<div class="form-group">
	        				<label for="price">Prix</label>
	        				<input type="number" v-model="combin.combinantionPrice" id="price" class="form-control" name="quantity">
	        				<HasError :form="combin" field="combinantionPrice" />
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-combination')">Close</button>
		                <button type="submit" :disabled="combin.busy" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <v-dialog />
	</div>
</template>
<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	import PictureInput from 'vue-picture-input';

	export default{
		props: {
			Partner:Object,
		},
		components:{
			Button, HasError, AlertError, PictureInput
		},
		data: function() {

			return {
				baseUrl: Laravel.baseUrl,
				basePath: Laravel.baseUrl+'medias/product_image/',
				combinpath: Laravel.baseUrl+'medias/combination_image/',
				partnerproductimgpath: Laravel.baseUrl+'medias/partner_product_image/',
				combinations:[],
				combin: new Form({
					id:'',
					partner_product_id:'',
					combinationName:'',
					quantity:'',
					combinantionPrice:'',
				}),
				productform: new Form({
					id:'',
					account_id:'',
					product_id:'',
					category_id:'',
					sub_category_id:'',
					product:'',
					product_type:'',
					category:'',
					subcategory:'',
					price:'',
					partner_product_image:'',
					image:'',
					quantity:'',
					is_on:'',
					have_variation:'',
					update_image:false
				}),
				changestate: new Form({
					id:'',
					account_id:'',
					is_on:'',
				}),
				update_image:false,
                partnerDetails: this.Partner,
                filter: new Form({
                    per_page:10,
                    account:this.Partner,
                    category:'',
                    sub_category:'',
                }),
                partnerProducts:[],
                pages: [1,5,10,20,30,40,50,60,70,80,90,100],
                categories:[],
				subcategories:[],
			}
		},
        computed: {
            profile: function() {
                return this.partnerDetails.account_profile.toLowerCase();
            }
        },
		methods: {
			getFilePath: function(file, booleanValue) {
				return booleanValue? this.partnerproductimgpath+file : this.basePath+file;
			},
			getFileType: function(file) {
				var array = file.split('.');
				return array[array.length-1];
			},
			getMediaType: function(file) {
				var ext = this.getFileType(file);
				return "image/"+ext;
			},
			onChange (image) {
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.')
                    this.productform.partner_product_image = image
					this.productform.update_image = true;
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            },
			showModal:function(name, datas, form) {

				(datas && name == "edit-combination")? this.combin.fill(datas):this.combin.reset();
				(datas && name == "edit-product")? this.productform.fill(datas):this.productform.reset();
				this.$modal.show(name);
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
			showCombinations: function(item) {

				this.loadProductCombinations(item.id);
				this.showModal('show-combinations');
			},
			loadProductCombinations: function (id) {
				this.combinations = [];
				let uri = "/"+this.profile+"/products/product-combinations/"+id;
				axios.get(uri).then(response => {
					this.combinations = response.data;
				});
			},
			UpdateCombination: function(modal) {
				let uri = "/"+this.profile+"/products/update-combination";
				this.combin.put(uri).then(response => {
					if(response.data.success) {
						this.combinations = response.data.combinations;
						this.Products = response.data.products;
						this.closeModal(modal);
						this.combin.reset();
					}

					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                });
				});
			},
			changeState: function(item, state) {
				let uri = "/"+this.profile+"/products/change-product-state";
				this.changestate.id = item.id;
				this.changestate.account_id = item.account_id;
				this.changestate.is_on = state;

				this.$modal.show('dialog', {
				  title: "Activation de produit",
				  text: 'Voulez-vous vraiment le rendre disponible?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        this.changestate.post(uri).then(response => {
				        	if(response.data.success) {
				        		this.changestate.reset();
				        		this.Products = response.data.products;
				        	}
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},

			deleteCombination: function(item) {

				let uri = "/"+this.profile+"/products/delete-combination";
				this.combin.fill(item);

				this.$modal.show('dialog', {
				  title: "Suppression de combinaison",
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette combinaison ?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        this.combin.delete(uri).then(response => {
				        	if(response.data.success) {
				        		this.combin.reset();
				        		this.combinations = response.data.combinations;
				        		this.Products = response.data.products;
				        	}
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},
			deleteProduct: function(item) {

				let uri = "/"+this.profile+"/products/delete-product";
				this.productform.fill(item);

				this.$modal.show('dialog', {
				  title: "Suppression de produit",
				  text: 'Êtes-vous sûr de bien vouloir supprimer ce produit ?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        this.productform.delete(uri).then(response => {
				        	if(response.data.success) {
				        		this.productform.reset();
				        		this.Products = response.data.products;
				        	}
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},
			onFileChange(e){
	        	//e.default();
	        	console.log(e);

              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                     this.productform.partner_product_image = reader.result;
                     this.productform.update_image = true;
                    }              
                     reader.readAsDataURL(file);
                } else {
                    alert('File size can not be bigger than 2 MB')
                }
            },
            UpdateProduct: function(modal) {
            	const loading = this.$vs.loading({type:'points'});
            	let uri = "/"+this.profile+"/products/update-product";
            	this.productform.put(uri).then(response => {
            		if(response.data.success){
            			this.closeModal(modal);
            			this.productform.reset();
            			this.update_image = false;
            			this.Products = response.data.products;
            			loading.close();
            		}

            		Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                });
            	});
            },
            loadProducts: function(page = 1) {
                let uri = "/"+this.profile+"/products/load-products?page=" + page;
                this.filter.post(uri).then(response => {
                    this.partnerProducts = response.data;
                }).catch(err => {
                    console.log(err);
                });
            },
            loadCategories: function() {
				let uri = '/'+this.profile+'/products/get-sellpoints-products-categories';
				this.filter.post(uri).then(response => {
					this.categories = response.data;
				});
			},
            loadSubCategories: function() {
                this.loadProducts();
				let uri = "/"+this.profile+"/products/get-sellpoints-products-sub-categories";
				this.filter.post(uri).then(response => {
					this.subcategories = response.data;
				});
			},
		},
		created: function() {
            this.loadProducts();
            this.loadCategories();
		},
		mounted: function() {
			console.log(this.Products);
		}
	}
</script>