<template>
	<div>
		  <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <!-- <div class="card-header">
              <h5>Liste des partenaires</h5>
            </div> -->
            <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead class="bg-light text-dark text-center align-middle">
                      <tr>
                        <th>Nom</th>
                        <th>Profils</th>
                        <th>Telephone</th>
                        <th>Mail</th>
                        <th>Etat</th>
                        <!-- <th>Ville/Zone</th> -->
                        <th>...</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(partner, index) in partners" :key="index">
                          <td style="width: 40%;">
                            {{ partner.name }}
                          </td>
                          <td>
                            <div class="span badge rounded-pill pill-badge-primary">{{ partner.account_profile }}</div>
                          </td>
                          <td>{{ partner.phone_number }}</td>
                          <td>{{ partner.mail_address }}</td>
                          <td style="width: 15%;">
                            <vs-button-group v-if="partner.is_on">
                                <vs-button  class="bg-primary"> 
                                  <feather type="check" size="10"></feather>
                                </vs-button>
                                <vs-button class="bg-light small" @click="changeState(partner, false)"> 
                                  <span class="small">Desactiver</span>
                                </vs-button>
                            </vs-button-group>
                            <vs-button-group v-else>
                                <vs-button  class="bg-danger"> <feather type="x-circle" size="15"></feather>
                                </vs-button>
                                <vs-button class="bg-light" @click="changeState(partner, true)"> 
                                  <span class="small">Activer</span>
                                </vs-button>
                            </vs-button-group>
                          </td>
                          <!-- <td>{{ partner.city }} / {{ partner.zone }}</td> -->
                          <td>
                            <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                              <span class="m-r-5 font-primary">
                                <a :href="'/admin/accounts/edit/'+partner.id"><i data-feather='edit' style="width:16px; height: 16px; cursor:pointer;"></i>
                                </a>
                              </span>
                              <span class="m-r-5 font-danger">
                                <a :href="'/admin/accounts/delete/'+partner.id">
                                  <i data-feather='trash' style="width:16px; height: 16px; cursor:pointer;"></i>
                                </a>
                              </span>
                            </div>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>
      </div>
      <v-dialog />
	</div>
</template>
<script>
  import Form from 'vform';
  import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';

  export default {
    props: {
      partners: Array,
    },
    components:{
      Button, HasError, AlertError
    },
    data: function() {
      return {
        changestate: new Form({
          id:'',
          is_on:'',
        }),
      }
    },
    methods: {
      changeState: function(item, state) {
        let uri = "/admin/accounts/change-partner-state";
        this.changestate.id = item.id;
        //this.changestate.account_id = item.account_id;
        this.changestate.is_on = state;

        this.$modal.show('dialog', {
          title: "Activation de partenaires",
          text: 'Voulez-vous vraiment le rendre disponible?',
          buttons: [
            {
              title: 'Non',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Oui',
              handler: () => {
                this.changestate.post(uri).then(response => {
                  if(response.data.success) {
                    this.changestate.reset();
                    this.partners = response.data.partners;
                  }
                  Toast.fire({
                          icon: response.data.success? 'success':'error',
                          title: response.data.message
                      });
                      this.$modal.hide('dialog'); 

                });
              }
            }
          ]
        });
      },
    },
    created: function() {}
  }
</script>