<template>
	<div>
		<div class="row starter-main pt-5 bg-white">
			
			<div class="col-sm-12 col-12" style="background-color: #f8f9fa !important;">
				<div class="col-12">
					<div class="card align-middle" style="height: 400px;">
						<img class="img-fluid bg-img-cover" :src="baseUrl+'medias/cover_image/'+Details.cover_image" alt="" style="">
						<div class="card-body avatar-showcase" style="background-color: rgba(0, 0, 0,0.6);height: 400px; vertical-align: middle;">
							<div class="avatars text-center" style="padding-top: 5%;">
								<div class="avatar">
									<img class="img-100 rounded-circle" :src="baseUrl+'medias/logo/'+Details.logo" alt="#" width="100" height="100">
			                      <div class="status status-100 bg-primary" v-if="checkStatus(Details.servicedays)"> </div>
			                      <div class="status status-100 bg-danger" v-else> </div>
			                    </div>
			                    <div class="title">
				                    <h4 class="text-secondary">{{ Details.account_profile }}</h4>
				                    <h4 class="text-white f-w-900">{{ Details.name }}</h4>
				                    <h5 class="text-warning" v-html="currentDay(Details.servicedays)"></h5>
				                </div>
							</div>
						</div>
					</div>
				</div>
				<section class="p-lg-5">
					<div class="row" v-if="isRestaurant">
				        <div class="col-sm-12 col-md-1 col-lg-1"></div>
						<div class="col-sm-12 col-md-10 col-lg-10">
							<div class="card card-absolute" style="background-color: #f8f9fa !important;">
								<div class="card-header pt-1 pb-1 pl-2 pr-2">
								<h5 class="text-muted" style="text-transform: none;">Menu du jour</h5>
								</div>
								<div class="card-body">
									<restaurant-products :detail="Details"></restaurant-products>
								</div>
							</div>
						</div>
				    </div>
					<div class="row" v-else>
						<div class="col-sm-12 col-md-1 col-lg-1"></div>
						<div class="col-sm-12 col-md-10 col-lg-10">
							<products :detail="Details"></products>
						</div>
					</div>
				</section>
			</div>
			
		</div>
	</div>
</template>
<script>
	import moment from 'moment';
	import Products from './products.vue';
	import ProductsTwo from './productsTwo.vue';
	import restaurantProducts from './restaurantProducts.vue';

	export default {
		props: {
		  Details: Object,
		},
		components: {
			Products, ProductsTwo, restaurantProducts
		},
		data: function() {
			return {
				baseUrl:Laravel.baseUrl,
			}
		},
		computed: {
		  isRestaurant: function() {
		  	var isRestaurant = false;
	        if(this.Details.account_profile === 'Restaurant') {
	          isRestaurant = true;
	        }

	        return isRestaurant;
		  }
		},
		methods: {
			checkStatus: function(servicedays) {
				
				var isOpened = false;
				Object.keys(servicedays).forEach(key => {

					if(servicedays[key].opentoday) {

						if(servicedays[key].stillopen) {
							isOpened = true;
						}
					}
				});

				return isOpened;
			},
			currentDay: function(servicedays) {
				
				var result = "N'ouvre pas aujourd'hui";

				Object.keys(servicedays).forEach(key => {
					
					if(servicedays[key].opentoday) {
						var openHour = moment(servicedays[key].openHour, 'HH:mm:ss').format('HH:mm');
						var closeHour = moment(servicedays[key].closeHour, 'HH:mm:ss').format('HH:mm');

						result = "<b>Horaires</b> : "+openHour+" - "+closeHour;
					}
				});
				return result;
			},
		},

		created: function() {

		},

		mounted: function() {

		}
	}
</script>