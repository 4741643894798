<template>
    <div>
        <div class="card">
            <div class="product-box">
                <div class="product-img"><img class="img-fluid" :src="baseUrl+'/assets/images/ecommerce/01.jpg'" alt="">
                    <div class="product-hover">
                        <ul>
                            <li><a href="cart.html" data-bs-original-title="" title=""><i
                                        class="icon-shopping-cart"></i></a></li>
                            <li><a data-bs-toggle="modal" data-bs-target="#exampleModalCenter16"
                                    data-bs-original-title="" title=""><i class="icon-eye"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalCenter16">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="product-box row">
                                    <div class="product-img col-lg-6"><img class="img-fluid"
                                        :src="baseUrl+'/assets/images/ecommerce/01.jpg'" alt=""></div>
                                    <div class="product-details col-lg-6 text-start">
                                        <a href="product-page.html" data-bs-original-title="" title=""><h4>Man's Jacket </h4></a>
                                        <div class="product-price">$26.00
                                            <del>$35.00</del>
                                        </div>
                                        <div class="product-view">
                                            <h6 class="f-w-600">Product Details</h6>
                                            <p class="mb-0">Sed ut perspiciatis, unde omnis iste natus error sit
                                                voluptatem accusantium doloremque laudantium, totam rem aperiam
                                                eaque ipsa, quae ab illo.</p>
                                        </div>
                                        <div class="product-size">
                                            <ul>
                                                <li>
                                                    <button class="btn btn-outline-light" type="button"
                                                        data-bs-original-title="" title="">M</button>
                                                </li>
                                                <li>
                                                    <button class="btn btn-outline-light" type="button"
                                                        data-bs-original-title="" title="">L</button>
                                                </li>
                                                <li>
                                                    <button class="btn btn-outline-light" type="button"
                                                        data-bs-original-title="" title="">Xl</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="product-qnty">
                                            <h6 class="f-w-600">Quantity</h6>
                                            <fieldset>
                                                <div class="input-group bootstrap-touchspin">
                                                    <button class="btn btn-primary btn-square bootstrap-touchspin-down" type="button" data-bs-original-title="" title=""><i class="fa fa-minus"></i></button>
                                                    <span class="input-group-text bootstrap-touchspin-prefix" style="display: none;"></span>
                                                    <input class="touchspin text-center form-control" type="text" value="5" style="display: block;" data-bs-original-title="" title="">
                                                    <span class="input-group-text bootstrap-touchspin-postfix" style="display: none;"></span><button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" data-bs-original-title="" title=""><i class="fa fa-plus"></i></button>
                                                </div>
                                            </fieldset>
                                            <div class="addcart-btn">
                                                <a class="btn btn-primary me-3"
                                                    href="cart.html" data-bs-original-title="" title="">Add to Cart
                                                </a>
                                                <a class="btn btn-primary" href="product-page.html"
                                                    data-bs-original-title="" title="">View Details
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"
                                data-bs-original-title="" title=""></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product-details"><a href="product-page.html" data-bs-original-title="" title="">
                        <h4>Man's Jacket </h4>
                    </a>
                    <p>Solid Denim Jacket</p>
                    <div class="product-price">$26.00
                        <del>$35.00</del>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props: ['product'],
        data(){
            return {
				baseUrl:Laravel.baseUrl,
            }
        }
    }
</script>