import EventBus from './details/EventBus.js';
import CartMethods from '../../mixins/cartMethods.js';

export default {
	mixins:[CartMethods],
	data: function() {
		return {
			carts:[],
		}
	},
	computed: {
	  	cartTotalAmount: function() {
	      var total = 0;
	      Object.keys(this.carts).forEach(key => {
	        let price = (this.carts[key].have_variation)? this.carts[key].combinantionPrice:this.carts[key].price;
	        total = total + (this.carts[key].quantity * price);
	      });

	      return total;
	    },
	    disabled: function() {
	    	if(this.cartTotalAmount > 0) {
	    		return false;
	    	} else {
	    		return true;
	    	}
	    },
	    cartContainUnderPrescriptionProduct: function() {
	    	
	    	var result = this.carts.some(function(item) {

	    		return item.is_under_prescription === 1;
	    	});

	    	return result;
	    }
	},
	methods: {
		loadCart: function () {
		    let uri = "/partenaires/products/load-cart";
		    axios.get(uri).then(response => {
		    	if(Array.isArray(response.data.cart)) {

		    		this.carts = response.data.cart;
		    	} else {
		    		this.carts = Object.keys(response.data.cart).map(key => {
		    			return response.data.cart[key];
		    		});
		    	}
		    }).catch(err => {
		    	console.log(err);
		    });
		},
		emptyCart: function() {
			let uri = "/partenaires/products/empty-cart";
			axios.get(uri).then(response => {
				this.carts = [];
			}).catch(err => {
				console.log(err);
			});
		}
	},

	mounted: function() {
		this.loadCart();
		EventBus.on('new-item-added-to-cart', () => {
			this.loadCart();
		});

		EventBus.on('cart-item-updated', () => {
			this.loadCart();
			console.log('cart-item-updated');
		});

		EventBus.on('empty-cart', () => {
			this.emptyCart();
			console.log("empty the cart onging...");
		})
	},
}