<template>
    <div ref="target" id="target" class="center">
        <div class="login-card pb-5 o-auto" ref="content" style="background-color: white !important;">
            <form @submit.prevent="partnerRegistration" class="theme-form login-form" style="width: 700px;">
                <div class="mt-1 mb-1 text-center">
                  <img :src="baseUrl+'logo-1-rmdbg.png'" width="100" height="100" alt="">
                </div>
                <h4 class="text-primary text-center mb-none mb-sm-none mb-md-5 mb-lg-5 mb-xl-5 registration-form-heading" style="font-size:26px;">
                    <span class="f-w-800">Devenir</span> 
                    <span class="text-warning  f-w-800">Livreur (euse)</span>
                </h4>
                <span class="m-2"></span>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label>Nom</label>
                            <input class="form-control form-control-lg" v-model="form.firstname" type="text" placeholder="Votre Nom">
                            <div class="text-danger f-12 mt-1" v-if="form.errors.has('firstname')" v-html="form.errors.get('firstname').replace('validation.required', 'Ce champs est requis')"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label>Prénom</label>
                            <input class="form-control form-control-lg" v-model="form.lastname" type="text" placeholder="Votre Prénom">
                            <div class="text-danger f-12 mt-1" v-if="form.errors.has('lastname')" v-html="form.errors.get('lastname').replace('validation.required', 'Ce champs est requis')"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label>Adresse Email</label>
                        <input class="form-control form-control-lg" v-model="form.account_email" type="email" placeholder="Adresse email">
                        <!-- <div class="text-info" v-if="form.errors.has('account_email')" v-html="form.errors.get('account_email').replace('validation.required', 'Ce champs est requis')"/> -->
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label>Numéro de Telephone</label>
                        <input class="form-control form-control-lg" v-model="form.account_number" type="tel" placeholder="Numéro de téléphone">
                        <div class="text-danger f-12 mt-1" v-if="form.errors.has('account_number')" v-html="form.errors.get('account_number').replace('validation.required', 'Ce champs est requis')"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label>Ville</label>
                            <multiselect v-model="form.city_id" :options="cities" :allow-empty="false" :hide-selected="false" label="name" track-by="id" @input="loadZones" placeholder="Ville"></multiselect>
                            <div class="text-danger f-12 mt-1" v-if="form.errors.has('city_id')" v-html="form.errors.get('city_id').replace('validation.required', 'Ce champs est requis')"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label>Quartier ou zone</label>
                            <multiselect v-model="form.zone_id" :options="zones" :allow-empty="false" :hide-selected="false" label="name" track-by="id" placeholder="Quartier ou Zone"></multiselect>
                            <div class="text-danger f-12 mt-1" v-if="form.errors.has('zone_id')" v-html="form.errors.get('zone_id').replace('validation.required', 'Ce champs est requis')"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label for="">Documents</label> <span class="text-muted" style="font-size:12px;">(Formats de fichier autorisé PNG et JPEG)</span>
                        <picture-input 
                            ref="pictureInput"
                            width="700" 
                            height="220" 
                            margin="" 
                            accept="image/jpeg,image/png" 
                            size="5"
                            :removable="true"
                            :hideChangeButton="true"
                            :autoToggleAspectRatio="true"
                            :zIndex="0"
                            button-class="btn btn-outline-primary"
                            :custom-strings="{
                                upload: '<h1>Document</h1>',
                                drag: '<span class=text-dark style=font-size:13px;>Selectionnez le fichier 😺</span>',
                                change: 'Modifier le fichier',
                                remove: 'Supprimer le fichier',
                            }"
                            @change="onChange">
                        </picture-input>
                    </div>
                </div>
                <span class="m-1"></span>
                
                <div class="form-group">
                    <div class="checkbox">
                        <input id="checkbox1" type="checkbox" v-model="form.accept_termsandconditions">
                        <label class="text-muted text-warning" for="checkbox1">J'accepte <span class="text-warning">les Termes et Conditions</span></label>
                        <div class="text-danger f-12 mt-1" v-if="form.errors.has('accept_termsandconditions')" v-html="form.errors.get('accept_termsandconditions').replace('validation.required', 'Vous devez accepter les termes et conditions')"/>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary w-100" type="submit">DEVENIR LIVREUR (EUSE)</button>
                </div>
                <p>Vous avez déjà un compte?<a class="ms-2" href="/login">Connectez-vous</a></p>
            </form>
        </div>
    </div>
</template>
<script>
    import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
    import Placement from '../fronts/placement.js';
    import PictureInput from 'vue-picture-input';
    import Notification from '../../mixins/notification.js';

    export default {
        mixins:[Placement, Notification],
        components:{
			Button, HasError, AlertError, PictureInput
		},
        data(){
            return {
                baseUrl: Laravel.baseUrl,
                types: [
                    {key:'Restaurant', value:'Restaurants'},
                    {key:'Pharmacy', value:'Pharmacies'},
                    {key:'Supermarket', value:'Supermarchés/Alimentations/Boutiques'},
                ],
                form: new Form({
                    firstname: '',
                    lastname: '',
                    account_email:'',
                    account_number: '',
                    document: '',
                    accept_termsandconditions: true
                }),
            }
        },
        methods:{
            onChange (image) {
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.');
                    this.form.document = image;
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!');
                }
            },
            loadZones: function() {
                let uri = "/api/city-zones";
                axios.post(uri, {city: this.form.city_id.id}).then(response => { this.zones = response.data});
		    },
            partnerRegistration: function() {
                const loading = this.$vs.loading({type:'square', target: this.$refs.content, color: this.color, text: 'Inscription en cours...'});
                let uri = "/deliveryman-registration";
                this.form.post(uri).then(response => {
                    loading.close();
                    (response.data.success)? this.form.reset():null;
                    let success = (response.data.success)? 'success' : 'danger';
                    this.openNotification('none','top-right', success, success, response.data.title, response.data.message);
                    //this.form.document
                }).catch(err => {
                    //this.openNotification('none','top-right', null, 'danger', 'test', 'test');
                    loading.close();
                    console.log(err);
                });
            }
        },
        created: function() {}
    }
</script>