export default {
	methods: {
		notify: function(content, delay = null, placement = null, offset = null, z_index = null, type = null) {
			$.notify(content, {
			    type:(type !== null)? type: 'primary',
			    allow_dismiss: true,
			    delay: (delay!== null)? delay: 2000,
			    showProgressbar: true,
			    timer: 900,
			    z_index: (z_index!== null)? z_index:1051,
			    placement:(placement !== null)? placement : {
			        from:'top',
			        align:'center'
			    },
			    offset: (offset !== null)? offset: {
			        x:300,
			        y:300
			     },
			});
		},
		displayToast: function(success, message) {
			Toast.fire({
				icon: success? 'success':'error',
				title: message
			});
		},
		displaySwal: function(success, message, timer) {
			Swal.fire({
				position: 'center',
				icon: (success)? 'success' : 'error',
				title: 'Ooups!',
				text: message,
				showConfirmButton: true,
				timer: timer
			})
		},
		openNotification(duration, position = null, color, border, title, message) {
			const noti = this.$vs.notification({
			  square: true,
			  color,
			  border,
			  position,
			  duration,
			  title: title,
			  text: message
			})
		}
	}
}