<template>
    <div>
        <ul class="framworks-list">
            <li class="fadeInUp bg-white" style="cursor: pointer;" v-for="(category, index) in categories" :key="index" @click="goToCategoryPage(category)">
                <div> <img :src="baseUrl+'medias/category_image/frontsize/187x187/'+category.image" width="100" height="100" alt=""></div>
                <h6 style="font-size: 13px;">{{ category.name }}</h6>
            </li>
        </ul>
    </div>
</template>
<script>
import axios from 'axios';


    export default {
        props:{
			categories: Array,
		},
        data: function() {
            return {
                baseUrl: Laravel.baseUrl,
            }
        },
        methods: {
            goToCategoryPage: function(item) {
                //hash id
                let uri = "/partenaires/products/hash-id";
                axios.post(uri, {id: item.id}).then(response => {
                    let hashedId = response.data;
                    //redirect to categorie page
                    document.location.href = this.baseUrl+'categories/details/'+hashedId;
                });
            }
        },
        created: function() {}
    }
</script>