<template>
	<div>
		<div class="row">
			<form @submit.prevent="defineData">
				<div class="col-sm-10 xl-12">
				<label>Jours et Horaires de travail ou d'ouverture</label>
				<div class="form-group">
					<multiselect v-model="form.rows" :options="days" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="choisissez les jours" label="days" track-by="days">
					</multiselect>
				</div>
				<div class="form-group">
					<div v-for="(line, index) in form.rows" :key="index">
            			<div class="row">
	            			<div class="col-sm-2">
	            				<div class="form-group">
	            					<input type="text" class="form-control form-control-sm bg-white border-0" v-model="line.days" disabled>
	            				</div>
	            			</div>
	            			<div class="col-sm-8">
	            				<div class="form-group">
	            					<vue-timepicker v-model="line.openHour" placeholder="Heure d'ouverture" input-width="240px" input-class="form-control bg-white" close-on-complete auto-scroll>
	            						
	            					</vue-timepicker>

	            					<span>a</span>
	            					<vue-timepicker input-class="form-control bg-white" v-model="line.closeHour" input-width="240px" placeholder="Heure de fermeture" close-on-complete auto-scroll></vue-timepicker>
	            				</div>
	            			</div>
	            			<div class="col-sm-2">
	            				<span style="cursor: pointer;" class="cursor-pointer text-danger align-middle" v-on:click="deleteRow(index,line)"><feather type="x-circle" size="24"></feather></span>
	            				<span style="cursor: pointer;" class="cursor-pointer text-dark align-middle" v-on:click="deleteLine(line)"><feather type="trash-2" size="24" v-if="line.from_db"></feather></span>
	            			</div>
	            		</div>
            		</div>
				</div>
				<div class="form-group">
					<button class="btn btn-light">Enregistrer</button>
				</div>
			</div>
			</form>	
		</div>
		<v-dialog />
	</div>
</template>
<script>

	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	
	import VueTimepicker from 'vue2-timepicker'
	import 'vue2-timepicker/dist/VueTimepicker.css'

	export default {
		props:["partnerid"],
		components:{
			Button, HasError, AlertError, VueTimepicker
		},
		data: function(){

			return {
				days: [
				{days:"Lundi"}, {days:"Mardi"}, {days:"Mercredi"}, {days:"Jeudi"}, {days:"Vendredi"}, {days:"Samedi"}, {days:"Dimanche"}
				],
				form: new Form({
					rows:[],
					partner_id:'',
				})

			}
		},

		methods: {

			addRow: function() {
				this.form.rows.push({
					days:null, openHour:null, closeHour:null, from_db:false
				})
			},

			deleteRow: function(index, row) {
				var idx = this.form.rows.indexOf(row);
	            if (idx > -1) {
	                this.form.rows.splice(idx, 1);
	            }
			},

			defineData: function() {
				this.form.partner_id = this.partnerid;
				const loading = this.$vs.loading({type:'points'});
				let uri = "/admin/accounts/define-working-days-times";
				this.form.post(uri).then(response => {
					(response.data.success)? loading.close():null;
					Fire.$emit('AfterAction');
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                });
				}).catch(err =>{
					console.log(err);
				});
			},
			deleteLine: function(line) {

				let uri = "/admin/accounts/delete-working-days-times/"+line.id;

				this.$modal.show('dialog', {
				  title: 'Suppression de ligne',
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette ligne?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				      	const loading = this.$vs.loading({type:'points'});
				        axios.delete(uri).then(response => {
				        	(response.data.success)? loading.close():null;
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 
				        });
				      }
				    }
				  ]
				});
			},
			loadDatas: function() {
				const loading = this.$vs.loading({type:'points'});
				let uri = "/admin/accounts/partner-working-days-times/"+this.partnerid;
				axios.get(uri).then(response => {
					loading.close();
					this.form.rows = response.data.rows;
				});
			}
		},

		created: function() {
			this.loadDatas();
			Fire.$on("AfterAction", () => {
				this.loadDatas();
			});
		}
	}
</script>