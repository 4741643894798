<template>
	<div>
		<div class="row starter-main">
			<div class="col-sm-4">
	            <div class="card">
	                <form @submit.prevent="createVariations">
	                	<div class="card-body" style="height: 350px; overflow-y: auto;">
		                    <div class="table-responsive">
		                    	<table class="table table-bordered">
			                    	<thead>
			                    		<tr>
			                    			<th>Nom de la variation</th>
			                    			<th class="text-center">
			                    				<span style="cursor: pointer;" class="addRow text-success" v-on:click="addRow"><feather type="plus-circle" size="16"></feather></span>
			                    			</th>
			                    		</tr>
			                    	</thead>
			                    	<tbody>
			                    		<tr v-for="(addTd, variations) in addRows" :key="variations">
			                    			<td>
			                    				<input v-model='addTd.variationName' class="form-control" type="text" placeholder="">
			                    			</td>
			                    			<td>
			                    				<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteRow(variations,addTd)"><feather type="x-circle" size="16"></feather></span>
			                    			</td>
			                    		</tr>
			                    	</tbody>
		                    	</table>
		                    </div>
		                </div>
		                <div class="card-footer p-3 bg-light text-center" v-if="$can('create-variation')">
		                	<button class="btn btn-outline-primary" type="submit">Enregistrer les variations</button>
		                </div>
	                </form>
	            </div>
	        </div>
	        <div class="col-sm-8">
	            <div class="card">
	                <div class="card-header pb-0">
	                    <div class="setting-list">
	                        <ul class="list-unstyled setting-option">
	                            <li>
	                                <div class="setting-primary"><i class="icon-settings"></i></div>
	                            </li>
	                            <li><i class="view-html fa fa-code font-primary"></i></li>
	                            <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
	                            <li><i class="icofont icofont-minus minimize-card font-primary"></i></li>
	                            <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
	                            <li><i class="icofont icofont-error close-card font-primary"></i></li>
	                        </ul>
	                    </div>
	                </div>
	                <div class="card-body">
	                    <div class="table-responsive">
	                    	<table class="table table-bordered">
	                    		<thead>
	                    			<tr>
	                    				<th>#</th>
	                    				<th>Nom</th>
	                    				<th>Date Creation</th>
	                    				<th>...</th>
	                    			</tr>
	                    		</thead>
	                    		<tbody>
	                    			<tr v-for="(item, index) in variations.data" :key="index">
	                    				<td>{{ item.id }}</td>
	                    				<td>{{ item.variationName }}</td>
	                    				<td>{{ item.created_at | moment("from", "now")}}</td>
	                    				<td>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-variation', item)" v-if="$can('edit-variation')">
	                    						<feather type="edit" size="13"></feather>
	                    					</span>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteVariation(item)" v-if="$can('delete-variation')">
	                    						<feather type="trash-2" size="13"></feather>
	                    					</span>
	                    				</td>
	                    			</tr>
	                    		</tbody>
	                    	</table>
	                    </div>
						<div class="mt-3">
	                    	<pagination :data="variations" @pagination-change-page="loadVariations">
	                    		<span slot="prev-nav">&lt; Préc</span>
								<span slot="next-nav">Suiv &gt;</span>
	                    	</pagination>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
		<modal name="edit-variation" :width="400" :height="200" :adaptive="true">
	        <form @submit.prevent="UpdateVariation('edit-variation')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div>
	        				<label for="name">Nom de la variation</label>
	        				<input type="text" v-model="variation.variationName" id="name" class="form-control" name="name">
	        				<HasError :form="variation" field="name" />
	        			</div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-variation')">Close</button>
		                <button type="submit" :disabled="variation.busy" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <v-dialog />
	</div>
</template>
<script>
import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
import Permissions from '../mixins/permissions.vue';

	export default {
		mixins: [Permissions],
		components:{
			Button, HasError, AlertError
		},
		data(){
			return {
				addRows:[],
				variations:[],
				variation: new Form({
					id:'',
					variationName:'',
				})
			}
		},

		methods:{
			addRow() {
		        this.addRows.push({
		            name:null,
		        });
	        },
	        deleteRow(index, addTd) {
	            var idx = this.addRows.indexOf(addTd);
	            if (idx > -1) {
	                this.addRows.splice(idx, 1);
	            }
	        },
	        showModal: function(name, datas){

				datas? this.variation.fill(datas): this.variation.reset();
				this.$modal.show(name);
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
	        createVariations: function() {
				let uri = "/admin/attributs/variations/store";
				var addRows = _.map(this.addRows, function (num) { return _.pick(num,'variationName')});
				axios.post(uri, {variations:addRows}).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.addRows = []:null;
					
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                 });
				});
			},
			UpdateVariation: function(modal) {
				let uri = "/admin/attributs/variations/update";
				this.variation.put(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.$modal.hide(modal):null;
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
			},

			deleteVariation: function(item) {

				let uri = "/admin/attributs/variations/delete";
				this.variation.fill(item);

				this.$modal.show('dialog', {
				  title: 'Suppression de variation',
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette variation ?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        this.variation.delete(uri).then(response => {
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},

			loadVariations: function(page = 1) {
				let uri = '/admin/attributs/variations/paginated-variations?page=' + page;
				axios.get(uri).then(response => {
					this.variations = response.data;
				});
			}
		},
		mounted() {
			this.addRow();
		},
		created(){
			this.loadVariations();
			Fire.$on("AfterAction", () => {
				//console.log('action happened!');
				this.loadVariations();
			});
		}
	}
</script>