<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-2 col-lg-2"></div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2" v-for="(category, index) in categories" :key="index">
                        <a href="#" @click="goToCategoryPage(category)">
                            <div class="card height-equal shadow shadow-showcase b-r-5 p-0" style="min-height: 204.375px;">
                                <div class="card-body">
                                    <img class="img-fluid" :src="baseUrl+'medias/category_image/frontsize/187x187/'+category.image" alt="">
                                </div>
                                <div class="card-footer p-3 text-center">
                                    <span class="h6 mb-0 text-bold text-dark" style="overflow:hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px;">{{ category.name }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-2 col-lg-2"></div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';


    export default {
        props:{
			categories: Array,
		},
        data: function() {
            return {
                baseUrl: Laravel.baseUrl,
            }
        },
        methods: {
            goToCategoryPage: function(item) {
                //hash id
                let uri = "/partenaires/products/hash-id";
                axios.post(uri, {id: item.id}).then(response => {
                    let hashedId = response.data;
                    //redirect to categorie page
                    document.location.href = this.baseUrl+'categories/details/'+hashedId;
                });
            }
        },
        created: function() {}
    }
</script>