<template>
	<div>
		<div class="row starter-main bg-white pt-5">
			<div class="col-xl-12 col-sm-12 col-lg-12">
				<div class="card pt-4 bg-primary align-middle">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-1 col-sm-12 xl-2"></div>
							<div class="col-lg-10 col-sm-12 xl-8">
								<ol class="breadcrumb mt-0 mt-sm-0 mt-md-3 mt-lg-3 mt-xl-3 mb-0 p-b-0 pl-5">
			                      <li class="breadcrumb-item"><a :href="'/'" class="text-white" data-bs-original-title="" title=""><i class="fa fa-home"></i></a></li>
			                      <!-- <li class="breadcrumb-item f-w-800">Resultat recherche</li>
			                      <li class="breadcrumb-item f-w-800">Produits</li> -->
			                      <li class="breadcrumb-item active text-white f-w-800">Resultat recherche</li>
			                    </ol>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-lg-1"></div>
							<div class="col-sm-12 col-lg-10">
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row pt-3 pb-3 bg-white">
			<div class="col-lg-1 col-sm-12"></div>
			<div class="col-lg-10 col-sm-12 p-4 p-sm-4 p-md-none p-lg-none p-xl-none">
				<span>
					<h5>Resultat de recherche</h5>
				</span>
				<div class="card shadow shadow-showcase bg-white b-r-10">
					<div class="card-body pl-1 pr-2 pt-3 pb-3">
						<form @submit.prevent="launchSearch">
							<div class="row">
								<div class="col-sm-12 col-lg-2 col-xl-2 col-12 text-center">
									<label class="typo__label text-secondary">Type de produit</label>
									<multiselect v-model="form.product_type" :options="types" :hide-selected="false"  placeholder="Type" class="text-primary text-center" @input="loadCategories" label="value" track-by="name"></multiselect>
								</div>
								<div class="col-sm-12 col-lg-3 col-xl-3 col-12 text-center">
									<label class="typo__label text-secondary"> Categories de produit </label>
									<multiselect v-model="form.category" :options="categories" :hide-selected="false"  placeholder="Categories de produit" label="name" track-by="id" @input="loadSubCategories" class="text-primary text-center"></multiselect>
								</div>
								<div class="col-sm-12 col-lg-3 col-xl-3 col-12 text-center">
									<label class="typo__label text-secondary">Sous categories</label>
									<multiselect v-model="form.sub_category" :options="subcategories" :hide-selected="false"  placeholder="Sous categories" label="name" track-by="id" class="text-primary text-center"></multiselect>
								</div>
								<div class="col-sm-12 col-lg-4 col-xl-4 col-12 text-center">
									<label class="typo__label text-secondary">Votre recherche</label>
									<input type="text" v-model="form.search_text" class="form-control form-control-lg btn-pill text-center text-warning" placeholder="Entrer votre recherche">	
								</div>
								<div class="col-sm-12 col-lg-12 col-xl-12 text-center">
									<button type="submit" class="mt-3 btn btn-pill btn-outline-light btn-air-light btn-sm txt-dark" :disabled="isDisabled"> <feather type="search" size="16"></feather> Rechercher</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row pt-3 bg-white">
			<div class="col-sm-12 col-lg-1"></div>
			<div class="col-sm-12 col-lg-10">
				<div class="card border-1 border-light">
					<div class="card-body">
						<div v-if="activeComponent === 'productsTwo'">
							<products-two :Products="searchResults.data"></products-two>
						</div>
						<div v-if="activeComponent == 'products'">
							<products :Products="searchResults.data"></products>
						</div>

						<div class="mt-3">
							<pagination :data="searchResults" @pagination-change-page="launchSearch">
								<span slot="prev-nav">&lt; Precedent</span>
								<span slot="next-nav">Suivant &gt;</span>
							</pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	
	import Products from '../pages/partials/products.vue';
	import ProductsTwo from '../pages/partials/productsTwo.vue';

	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	import axios from 'axios';
	
	export default {
		// props: ['resultat', 'search'],
		components:{
			Button, HasError, AlertError, Products, ProductsTwo
		},
		data:function() {
			return {
				searchResults:[],
				form: new Form({
					product_type: '',
					category:'',
					sub_category: '',
					search_text: ''
				}),
				types: [
					{name: 'restaurant', value: 'Restaurants'},
					{name: 'pharmacy', value: 'Phamacies'},
					{name: 'supermarket', value: 'Supermarches'},
				],
				categories: [],
				subcategories:[],
				page:'products',
				activeComponent:''
			}
		},
		computed: {
		  isDisabled: function () {
		    return (this.form.product_type !== '')? false:true;
		  }
		},
		methods: {
			checkActiveComponent: function(product_type){
				if(product_type !== 'restaurant') {
					this.activeComponent = 'products';
				} else {
					this.activeComponent = 'productsTwo';
				}
			},
			launchSearch: function(page = 1) {
				const loading = this.$vs.loading({type:'square', color: this.color, 'text': 'Recherche en cours...'});
				this.checkActiveComponent(this.form.product_type.name);
				let uri = "/api/load-search-result?page=" + page;
				this.form.post(uri).then(response => {
					loading.close();
					this.searchResults = response.data;
				}).catch(err => {
					console.log(err);
					loading.close();
				});
			},
			loadSearchResult: function() {
				const loading = this.$vs.loading({type:'square', color: this.color, 'text': 'Chargement du résultat en cours...'});
				let uri = "/load-search-result";
				axios.post(uri).then(response => {
					loading.close();
					this.searchResults = response.data.result;
					this.categories = response.data.search.categories;
					this.subcategories = response.data.search.subcategories;

					this.form.product_type  = response.data.search.product_type;
					this.form.category = response.data.search.category,
					this.form.sub_category =  response.data.search.sub_category,
					this.form.search_text =  response.data.search.search_text
				}).catch(err => {
					loading.close();
					console.log(err);
				});
			},
			loadCategories: function() {
				let uri = "/api/product-categories";
				this.form.post(uri).then(({data}) => {
					this.categories = data;
				});
			},
			loadSubCategories: function() {
				let uri = "/api/product-sub-categories";
				this.form.post(uri).then(({data}) => {
					this.subcategories = data;
				});
			}
		},
		created: function() {
			this.loadSearchResult();
			this.checkActiveComponent(this.form.product_type.name);
			//this.launchSearch();
		},
	}
</script>