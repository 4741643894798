<template>
	<div>
		<div class="row starter-main bg-white pt-5">
			<div class="col-xl-12 col-sm-12 col-lg-12">
				<div class="card pt-4 bg-primary align-middle">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-1 col-sm-12"></div>
							<div class="col-lg-10 col-sm-12">
								<ol class="breadcrumb mt-3 mb-0 p-b-0 pl-5">
					              <li class="breadcrumb-item"><a :href="'/'" class="text-white" data-bs-original-title="" title=""><i class="fa fa-home"></i></a></li>
					              <!-- <li class="breadcrumb-item f-w-800">Partenaires</li> -->
					              <li class="breadcrumb-item active text-white f-w-800">Distribution</li>
					            </ol>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row bg-white pt-1 pb-5">
			<div class="col-lg-2 col-sm-12"></div>
		    <div class="col-lg-8 col-sm-12">
		      <div class="card border-0">
		        <div class="card-header pb-0">
		          <h5 class="text-center text-secondary" style="font-weight: 900; font-size: 30px;">Service de distribution</h5>
		        </div>
		        <div class="card-body p-lg-5 p-sm-0">
		            <request-form :type="type" :button="buttonText"></request-form>
		        </div>
		      </div>
		    </div>
    	</div>
	</div>
</template>
<script>
	import RequestForm from '../requestForm.vue';
	export default {
		components:{
			RequestForm,
		},
		data: function() {

			return {
				type:'distribution',
				buttonText:'Solliciter le service'
			}
		},
		
	}
</script>