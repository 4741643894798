<script>
	export default {

		methods: {
		  $can: function(permissionName) {

		  	const index = authPermissions.findIndex(object => {
		  		return object.name === permissionName;
		  	});

		  	//console.log(index);
		  	const usrCan = (index !== -1)? true: false;
		  	//console.log(usrCan);
		  	return usrCan;
		  },
		}
	}
</script>