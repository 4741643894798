import axios from "axios";

export default {
    methods: {
        loadProducts: function(page = 1) {
            let uri = "/partenaires/load-partner-products?page=" + page;
            axios.post(uri, {partner: this.detail}).then(response => {
                this.Products = response.data;
            }).catch(err => {
                console.log(err);
            });
        },
        goToDetails: function(item) {
            let uri = "/partenaires/products/hash-id";
            axios.post(uri, {id: item.id}).then(response => {
                let md5Id = response.data;
                //redirect to product detail page.
                document.location.href = "/partenaires/products/details/" + item.slug + "/" + md5Id;
            });
        }
    },
    created: function() {
        // this.loadProducts();
    }
}