<template>
	<div>
		<div class="d-none d-sm-none d-md-inline d-lg-inline d-xl-inline">
			<div class="notification-box">
				<i data-feather="shopping-bag"></i>
				<span class="badge rounded-pill badge-primary">{{ carts.length }}</span>
			</div>
			<ul class="notification-dropdown onhover-show-div card" style="width: 370px;">
				<div class="card-header p-0">
					<p class="f-w-700 p-2 product-price text-center">Contient {{ carts.length }} produit(s)
						<span class="pull-right badge badge-primary badge-pill">{{ carts.length }}</span>
					</p>
				</div>
				<div class="card-body p-3" style="height: 300px; overflow-y:auto;">

					<table class="table">
						<tbody>
							<tr class="noti-primary align-middle mt-2" v-for="(item, index) in carts" :key="index">
							<!-- <div class="row" style="vertical-align:middle !important;"> -->
								<td>
									<img class="rounded-circle" :src="baseUrl+'medias/combination_image/'+item.combination_image" alt="" width="50" height="50" v-if="item.have_variation">
									<img class="rounded-circle" :src="baseUrl+'medias/partner_product_image/frontsize/187x187/'+item.partner_product_image" alt="" width="50" height="50" v-else>
								</td>
								<td >
									<div class="text-secondary" style="font-size: 13px;">
										<span v-if="item.have_variation">{{ item.combinationName }}</span>
										<span v-else>{{ item.product }}</span>
									</div>
									<div class="text-secondary" style="font-size: 12px;">
										Qte: <span class="text-warning">{{ item.quantity }}</span>
									</div>
									<div class="text-secondary" style="font-size: 12px;">
										Prix: <span class="text-primary product-price" v-if="item.have_variation">{{ item.combinantionPrice * item.quantity }} F</span>
										<span class="text-primary product-price" v-else>{{ item.price * item.quantity }} F</span>
									</div>
								</td>
								<!-- <td>
									
								</td> -->
							<!-- </div>  -->
							<!-- <div class="dropdown-divider"></div> -->
							</tr>
						</tbody>
					</table>
					<li>
						
					</li>
				</div>
				<div class="card-footer pt-2 pb-2 bg-light align-content-lg-between">
					<a class="btn btn-sm btn-pill btn-outline-primary btn-air-primary mb-1" :href="'/carts/panier-d-achat'" ><i class="icon-shopping-cart"></i>Panier</a>
					<a class="btn btn-sm btn-pill btn-primary btn-air-secondary" :href="'/checkouts/page'"><i class="icon-arrow-right"></i>Commander</a>
				</div>
			</ul>
		</div>
		<div class="d-inline d-sm-inline d-md-none d-lg-none d-xl-none">
			<a class="notification-box" :href="'/carts/panier-d-achat'">
				<i data-feather="shopping-bag" class="font-primary"></i>
				<span class="badge rounded-pill badge-primary">{{ carts.length }}</span>
			</a>
		</div>
	</div>
</template>
<script>
	
	import EventBus from './details/EventBus.js';
	import LoadCart from './loadCart.js';
	export default {
		mixins: [LoadCart],
		data: function() {
			return {
				baseUrl:Laravel.baseUrl,
			}
		},
		methods: {},
		// created: function() {},
		mounted: function() {}
	}
</script>