<template>
	<div>
		<div class="row starter-main">
			<div class="col-sm-5">
	            <div class="card">
	                <form @submit.prevent="createCategory">
	                	<div class="card-body" style="height: 500px; overflow-y: auto;">
		                    <div class="form-group">
		                    	<label for="name">Libelle de la sous-categorie</label>
		                    	<input v-model='form.name' class="form-control" type="text">
		                    	<HasError :form="form" field="name" />
		                    </div>
		                    <div class="form-group">
		                    	<label for="name">Categorie Parente</label>
		                    	<select v-model="form.category_id" class="form-select digits" id="exampleFormControlSelect9">
	                              <option value="">Categorie parente</option>
	                              <option v-for="item in categories" :key="item.id" :value="item.id"> {{ item.name }} </option>
	                            </select>
	                            <HasError form="form" field="category_id" />
		                    </div>
		                    <div class="form-group">
		                    	<label for="name">image de la categorie</label>
		                    	<picture-input 
									ref="pictureInput"
									width="300" 
									height="200" 
									:margin="0" 
									accept="image/jpeg,image/png,image/webp" 
									:size="2"
									:z-index="0" 
									button-class="btn"
									:removable="true"
									:custom-strings="{
										upload: '<h1>Bummer!</h1>',
										drag: 'Selectionner une image',
										change: 'Changer',
										remove: 'Supprimer', 
									}"
									@change="onChange">
								</picture-input>
								<HasError :form="form" field="categoryImage" />
		                    </div>
		                </div>
		                <div class="card-footer p-3 bg-light text-center" v-if="$can('create-sub-category')">
		                	<button class="btn btn-outline-primary" type="submit">Enregistrer la categorie</button>
		                </div>
	                </form>
	            </div>
	        </div>
	        <div class="col-sm-7">
	            <div class="card">
	                <div class="card-header pb-0">
	                    <div class="setting-list">
	                        <ul class="list-unstyled setting-option">
	                            <li>
	                                <div class="setting-primary"><i class="icon-settings"></i></div>
	                            </li>
	                            <li><i class="view-html fa fa-code font-primary"></i></li>
	                            <li><i class="icofont icofont-maximize full-card font-primary"></i></li>
	                            <li><i class="icofont icofont-minus minimize-card font-primary"></i></li>
	                            <li><i class="icofont icofont-refresh reload-card font-primary"></i></li>
	                            <li><i class="icofont icofont-error close-card font-primary"></i></li>
	                        </ul>
	                    </div>
	                </div>
	                <div class="card-body">
	                    <div class="table-responsive">
	                    	<table class="table table-bordered">
	                    		<thead>
	                    			<tr>
	                    				<th>#</th>
	                    				<th>Nom</th>
	                    				<th>Categorie Parente</th>
	                    				<!-- <th>Date Creation</th> -->
	                    				<th>...</th>
	                    			</tr>
	                    		</thead>
	                    		<tbody>
	                    			<tr v-for="(item, index) in subcategories.data" :key="index">
	                    				<td>{{ item.id }}</td>
	                    				<td>{{ item.name }}</td>
	                    				<td>{{ item.category.name }}</td>
	                    				<!-- <td>{{ item.created_at | moment("from", "now")}}</td> -->
	                    				<td>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-category', item)" v-if="$can('edit-sub-category')">
	                    						<feather type="edit" size="13"></feather>
	                    					</span>
	                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteCategory(item)" v-if="$can('delete-sub-category')">
	                    						<feather type="trash-2" size="13"></feather>
	                    					</span>
	                    				</td>
	                    			</tr>
	                    		</tbody>
	                    	</table>
	                    </div>
						<div class="mt-3">
	                    	<pagination :data="subcategories" @pagination-change-page="loadSubCategories">
	                    		<span slot="prev-nav">&lt; Préc</span>
								<span slot="next-nav">Suiv &gt;</span>
	                    	</pagination>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
		<modal name="edit-category" :width="400" :height="550" :adaptive="true">
	        <form @submit.prevent="UpdateCategory('edit-category')" class="p-4">
	        	<div class="row">
	        		<div class="col-12">
	        			<div class="form-group">
	        				<label for="name">Nom de la category</label>
	        				<input type="text" v-model="form.name" id="name" class="form-control" name="name">
	        				<HasError :form="form" field="name" />
	        			</div>
	        			<div class="form-group">
	                    	<label for="name">Categorie Parente</label>
	                    	<select v-model="form.category_id" class="form-select digits" id="exampleFormControlSelect9">
                              <option value="" selected>Categorie parente</option>
                              <option v-for="item in categories" :key="item.id" :value="item.id"> {{ item.name }} </option>
                            </select>
                            <HasError form="form" field="category_id" />
	                    </div>
	                    <div class="form-group">
	                    	<label for="name">image de la categorie</label>
	                    	<picture-input 
								ref="pictureInput"
								width="400" 
								height="200" 
								margin="0" 
								accept="image/jpeg,image/png,image/webp" 
								size="10"
								z-index="0" 
								:prefill="getFilePath(form.sub_category_image)"
								:prefill-options="{fileName: form.sub_category_image, fileType:getFileType(form.sub_category_image), mediaType: getMediaType(form.sub_category_image)}"
								button-class="btn"
								:removable="true"
								:custom-strings="{
									upload: '<h1>Bummer!</h1>',
									drag: 'Selectionner une image',
									change: 'Changer',
									remove: 'Supprimer', 
								}"
								@change="onChange">
							</picture-input>
							<HasError :form="form" field="categoryImage" />
	                    </div>
	        			<div>
	        				<hr>
	        				<button class="btn btn-secondary" type="button" @click="closeModal('edit-category')">Close</button>
		                <button type="submit" :disabled="form.busy" class="btn btn-primary">Mettre a jours</button>
	        			</div>
	        		</div>

	        	</div>
	        </form>
	    </modal>
	    <v-dialog />
	</div>
</template>
<script>
import Form from 'vform';
import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
import Permissions from '../mixins/permissions.vue';
import PictureInput from 'vue-picture-input';

	export default {
		mixins: [Permissions],
		components:{
			Button, HasError, AlertError, PictureInput
		},
		data(){
			return {
				basePath: Laravel.baseUrl+'medias/sub_category_image/',
				categories:[],
				subcategories:[],
				form: new Form({
					id:'',
					name:'',
					category_id:'',
					sub_category_image:'',
					imageEdited:false
				})
			}
		},

		methods:{
			getFilePath: function(file) {
				console.log(file);
				return !this.form.imageEdited ? this.basePath+file : file;
			},
			getFileType: function(file) {
				if(!this.form.imageEdited) {
					var array = file.split('.');
					return array[array.length-1];
				} else {
					var firstpart = file.split(';')[0];
					var mediaType = firstpart.split(':')[1];
					var ext = mediaType.split('/')[1];
					return ext;
				}
			},
			getMediaType: function(file) {
				var ext = this.getFileType(file);
				return "image/"+ext;
			},
			onChange (image) {
				this.form.imageEdited = true;
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.')
                    this.form.sub_category_image = image
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            },
	        showModal: function(name, datas){

				datas? this.form.fill(datas): this.form.reset();
				if(datas) {
					this.form.sub_category_image = datas.image;
				}
				this.$modal.show(name);
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
	        createCategory: function() {
				let uri = "/admin/categories/subcategories";
				
				this.form.post(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.form.reset():null;
					
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                 });
				});
			},
			UpdateCategory: function(modal) {
				let uri = "/admin/categories/subcategories/"+this.form.id;
				this.form.put(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.$modal.hide(modal):null;
					response.data.success? this.form.reset():null;
					
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
			},

			deleteCategory: function(item) {

				let uri = "/admin/categories/subcategories/"+item.id;
				//this.form.fill(item);

				this.$modal.show('dialog', {
				  title: 'Suppression de categorie',
				  text: 'Êtes-vous sûr de bien vouloir supprimer cette categorie?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        axios.delete(uri).then(response => {
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},

			uploadImage(e){

              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                     this.form.sub_category_image = reader.result;
                    }              
                     reader.readAsDataURL(file);
                }else{
                    alert('File size can not be bigger than 2 MB')
                }
            },

			loadCategories: function() {
				let uri = '/admin/categories/categories';
				axios.get(uri).then(response => {
					this.categories = response.data;
				});
			},
			loadSubCategories: function(page = 1) {
				let uri = '/admin/categories/paginated-subcategories?page='+page;
				axios.get(uri).then(response => {
					this.subcategories = response.data;
				});
			}
		},
		created(){
			this.loadCategories();
			this.loadSubCategories();
			Fire.$on("AfterAction", () => {
				//console.log('action happened!');
				this.loadSubCategories();
			});
		}
	}
</script>