<template>
	<div>
		<div class="row starter-main">
			<form @submit.prevent="createProduct">
				<div class="row">
					<div class="col-sm-8">
			            <div class="card" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Nom du produit</label>
			                    	<input v-model='createForm.product_name' class="form-control" type="text">
			                    	<HasError :form="createForm" field="product_name" />
			                    </div>
			                    <div class="form-group">
			                    	<label for="name">Description</label>
			                    	<ckeditor v-model="createForm.description" :config="editorConfig"></ckeditor>
			                    </div>
			            	</div>
			            </div>
			        </div>
			        <div class="col-sm-4">
			            <div class="card mb-3" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Type de produit</label>
			                    	<select v-model="createForm.product_type" class="form-select digits form-control-lg" id="exampleFormControlSelect9" @change="loadCategories">
		                              <option value="">Choisissez le type</option>
		                              <option value="pharmacy"> Produit de Pharmacie </option>
		                              <option value="restaurant"> Produit de Restaurant </option>
		                              <option value="supermarket">  Produit de Supermarche </option>
		                            </select>
		                            <HasError form="createForm" field="product_type" />
			                    </div>
			            		<div class="form-group">
			                    	<label for="name">Categories Parente</label>
			                    	<select v-model="createForm.category_id" class="form-select digits form-control-lg" id="exampleFormControlSelect9" @change="loadSubCategories">
		                              <option value="">Categories parente</option>
		                              <option v-for="item in categories" :key="item.id" :value="item.id"> {{ item.name }} </option>
		                            </select>
		                            <HasError form="createForm" field="category_id" />
			                    </div>
			                    <div class="form-group">
			                    	<label for="name">Sous Categories</label>
			                    	<select v-model="createForm.sub_category_id" class="form-select digits form-control-lg" id="exampleFormControlSelect9">
		                              <option value="">Sous Categories</option>
		                              <option v-for="item in subCategories" :key="item.id" :value="item.id"> {{ item.name }} </option>
		                            </select>
		                            <HasError form="createForm" field="sub_category_id" />
			                    </div>
			            	</div>
			            </div>
			            <div class="card mb-2" style="border-radius: 10px;">
			            	<div class="card-header pt-2 pb-2 bg-light text-dark">
			            		Image du produit
			            	</div>
			            	<div class="card-body">
			            		<!-- <div class="pl-5 pt-5 text-center" style="vertical-align: middle;" v-if="createForm.product_image">
									<img :src="getLogo()" width="70%" height="70%" />
									<p class="text-danger" @click="clearImage"><u>Supprimer cette image</u></p>
								</div>
			            		<input type="file" @change="uploadImage" id="logo" name="stationLogo" class="form-control" placeholder=""> -->
								<picture-input 
									ref="pictureInput"
									width="400" 
									height="200" 
									:margin="0" 
									accept="image/jpeg,image/png" 
									:size="2"
									:z-index="0" 
									button-class="btn"
									:removable="true"
									:custom-strings="{
										upload: '<h1>Bummer!</h1>',
										drag: 'Selectionner une image',
										change: 'Changer',
										remove: 'Supprimer', 
									}"
									@change="onChange">
								</picture-input>
								
			            	</div>
			            </div>
			            <div class="card" style="border-radius: 10px; background-color:transparent;">
			            	<div class="card-footer p-0 b-0 text-center">
			            		<button class="btn btn-primary" type="submit">Ajouter le produit</button>
			            	</div>
			            </div>
			        </div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	import VueUploadMultipleImage from 'vue-upload-multiple-image';
	import PictureInput from 'vue-picture-input';

	export default{
		components:{
			Button, HasError, AlertError, VueUploadMultipleImage, PictureInput
		},
		data(){
			return {
				products:[],
				categories:[],
				subCategories:[],
				filepath:'',
                filetype:'',
                mediatype:'',
				createForm: new Form({
					product_name:'',
					product_type:'',
					category_id:'',
					sub_category_id:'',
					description:'',
					product_image:'',
				}),
				editorConfig:{}
			}
		},
		computed:{
		},
		methods: {
			onChange (image) {
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.')
                    this.createForm.product_image = image
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            },
			createProduct: function(){
				let uri = "/admin/products/store";
				this.createForm.post(uri).then(response => {
					response.data.success? this.createForm.reset():null;
					response.data.success? this.createForm.product_image = '' :null;
					
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                 });
				});
			},
			loadCategories: function() {
				let uri = '/admin/categories/by-product-type/'+this.createForm.product_type;
				axios.get(uri).then(response => {
					this.categories = response.data;
				});
			},

			loadSubCategories: function() {
				let uri = "/admin/categories/subcategories/by-category-id/"+this.createForm.category_id;
				axios.get(uri).then(response => {
					this.subCategories = response.data;
				});
			},
			clearImage: function() {
				this.createForm.product_image = '';
				$('#logo').val('');
			},
			uploadImage(e){

              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                    //console.log('RESULT', reader.result)
                     this.createForm.product_image = reader.result;
                    }              
                     reader.readAsDataURL(file);
                }else{
                    alert('File size can not be bigger than 2 MB')
                }
            },
            getLogo(){
               let logo = this.createForm.product_image;
               return logo;
            },
		},
		created() {
			//this.loadVariations();
			//this.loadCategories();

		}
	}
</script>
<style scoped>
.picture-inner .picture-inner-text[data-v-431cb064] {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 1em;
    line-height: 1.5;
}
</style>