<template>
  <div>
      <!-- <div class="row">
          <div class="card card-absolute">
            <div class="card-header bg-primary pt-1 pb-1 pl-2 pr-2">
              <h5 class="text-white">Menu du jour</h5>
            </div>
            <div class="card-body"> -->
              <div class="row">
                <div class="col-xl-2 col-sm-6 mb-sm-3 xl-3" v-for="(Product, index) in Products.data" :key="index">
                  <vs-card type="1">
                    <template #title>
                      <span class="badge badge-light text-dark">{{ Product.partner.toUpperCase() }}</span><br>
                      <h3 style="font-size: 13px;">{{ Product.product }}</h3>
                    </template>
                    <template #img>
                      <img :src="baseUrl+'medias/partner_product_image/frontsize/200x130/'+Product.partner_product_image" alt="">
                    </template>
                    <template #text>
                      <p style="font-size: 12px;">
                        {{ Product.category }} / {{ Product.subcategory }}
                      </p><hr>
                      <p class="product-price">
                        <b>{{ Product.price }} FCFA</b>
                      </p>
                    </template>
                    <template #interactions>
                      <vs-button icon class="bg-primary" @click="checkProductBeforeAddToCart(Product)">
                        <i class="icon-shopping-cart"></i>
                      </vs-button>
                    </template>
                  </vs-card>
                </div>
              </div>
              <div class="mt-3">
                <pagination :data="Products" @pagination-change-page="loadProducts">
                  <span slot="prev-nav">&lt; Precedent</span>
                  <span slot="next-nav">Suivant &gt;</span>
                </pagination>
              </div>
            <!-- </div>
          </div>
      </div> -->
  </div>
</template>
<script>

  import moment from 'moment';


  import EventBus from './EventBus.js';
  import LoadCart from '../loadCart.js';
	import ProductMethods from '../../../mixins/productMethods.js';

  export default {
    mixins: [LoadCart, ProductMethods],
    props:{
      detail: Object,
      // Profile:String,
    },
    data: function() {
      return {
        baseUrl:Laravel.baseUrl,
        Products:[],
      }
    },
    methods: {
      showAlert: function(text) {
        alert(text);
      },

      addToCart: function(product) {
        
        this.pushToCart(product);
        let uri = "/partenaires/products/add-to-cart";
        axios.post(uri, {cart:this.carts}).then(response => {
          
          //this.carts = response.data.cart;

          EventBus.emit('new-item-added-to-cart');
          
          var content  = {};
          content.title = "";
          content.message = '<i class="fa fa-bell-o"></i><strong>Information </strong>'+response.data.message+'.';
          
          $("#exampleModalCenter3").modal('hide');

          this.notify(content,null, {from:'top', align:'right'}, {x:30, y:30}, 1000, 'success');
        });
      },
      pushToCart: function(product) {

        var have_variation = product.have_variation;
        this.carts.push({
          partner_product_id: product.id,
          account_id: product.account_id,
          product_id:product.product_id,
          category_id: product.category_id,
          sub_category_id: product.sub_category_id,
          product: product.product,
          slug: product.slug,
          product_type: product.product_type,
          category: product.category,
          subcategory: product.subcategory,
          price: product.price,
          partner_product_image: product.partner_product_image,
          have_variation: have_variation,
          is_under_prescription: product.is_under_prescription,
          product_combination_id: (have_variation)? product.product_combination_id: null,
          combinationName: (have_variation)? product.combinationName: null,
          combinationCode: (have_variation)? product.combinationCode:null,
          combinantionPrice: (have_variation)? product.combinantionPrice:null,
          combination_image:(have_variation)? product.combination_image:null,
          total_quantity: product.total_quantity,
          quantity: product.quantity,
        });
      },
      checkCartContentBeforeAdding: function(product) {
        let exist = false;
        Object.keys(this.carts).forEach(key => {
          if(product.have_variation) {
            if(this.carts[key].partner_product_id == product.id && this.carts[key].combinationCode == product.combinationCode) {
              exist = true;
            }
          } else {
            if(this.carts[key].partner_product_id == product.id) {
              exist = true;
            }
          }
        });

        return exist;
      },
      checkProductBeforeAddToCart: function(product) {
        Vue.set(product, 'quantity', 1);
        if(!this.checkCartContentBeforeAdding(product)){
          
          this.addToCart(product);
        } else {
          console.log(this.checkCartContentBeforeAdding(product));
          var content = {};
          content.title = "";
          content.message = '<i class="fa fa-bell-o"></i><strong>Information</strong> Ce produit existe deja dans votre panier. Modifiez sa quantite <a href="/carts/panier-d-achat">ici</a>';

          this.notify(content, 10000, null, null, 1051, 'secondary');
        }
      }
    },
    created: function() {

    },
    mounted: function() {
    }
  }
</script>