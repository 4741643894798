<template>
	<div>
		<div class="card b-r-10">
			<div class="card-body p-lg-5 p-sm-0">
				<form @submit.prevent="saveRequest">
					<div class="row">
        				<div class="mb-3">
                      		<label class="form-check-label" for="gridCheck">Avez-vous un compte?</label>
                      	</div>
	                    <div class="form-group mb-4 m-checkbox-inline mb-0 custom-radio-ml">
	                        <div class="radio radio-primary">
	                        	<input id="radioinline1" type="radio" v-model="form.have_account" :value="true">
	                        	<label class="mb-0" for="radioinline1">Oui, j'ai un compte</label>
	                        </div>
	                        <div class="radio radio-warning">
	                        	<input id="radioinline2" type="radio" v-model="form.have_account" :value="false">
	                        	<label class="mb-0" for="radioinline2">Non, j'ai pas de compte</label>
	                        </div>
	                    </div>
	                </div>
	                <div class="row" v-if="form.have_account">
	                	<div class="col-sm-12">
                          	<div class="form-group">
	                          	<label for="inputEmail4">Nom d'utilisateur<span class="text-danger">*</span></label>
	                          	<input class="form-control" id="inputEmail4" type="text" v-model="form.username" placeholder="Entrez votre nom d'utilisateur">
                          	</div>
                        </div>
                        <div class="col-sm-12">
                          	<div class="form-group">
                          		<label for="inputPassword4">Mot de passe<span class="text-danger">*</span></label>
                          		<input class="form-control" id="inputPassword4" type="password" v-model="form.password" placeholder="Entrez votre mot de passe">
                          	</div>
                        </div>
	                </div>
					<div class="row" v-else>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<div class="form-group">
								<label for="requestor_firstname">Nom</label>
								<input type="text" class="form-control form-control-lg" v-model="form.requestor_firstname" id="requestor_firstname" placeholder="Entrer votre nom...">
								<div class="text-danger" v-if="form.errors.has('requestor_firstname')" v-html="form.errors.get('requestor_firstname').replace('validation.required', 'Le champs <b>Nom</b> est requis')" />
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<div class="form-group">
								<label for="requestor_lastname">Prenom</label>
								<input type="text" class="form-control form-control-lg" v-model="form.requestor_lastname" id="requestor_lastname" placeholder="Entrer votre prenom...">
								<div class="text-danger" v-if="form.errors.has('requestor_lastname')" v-html="form.errors.get('requestor_lastname').replace('validation.required', 'Le champs <b>Prenom</b> est requis')" />
							</div>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="form-group">
								<label for="requestor_number">Numero de telephone</label>
								<input type="tel" class="form-control form-control-lg" v-model="form.requestor_number" id="requestor_number" placeholder="Entrer votre numero de telepohone...">
								<div class="text-danger" v-if="form.errors.has('requestor_number')" v-html="form.errors.get('requestor_number').replace('validation.required', 'Le champs <b>Numero de telephone</b> est requis')" />
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<div class="form-group">
								<label for="city_id">Ville</label>
								<multiselect v-model="form.city_id" :options="cities" :hide-selected="false"  placeholder="Vous etes dans quelle ville?" label="name" track-by="id" @input="loadZones" class="text-primary"></multiselect>
								<div class="text-danger" v-if="form.errors.has('city_id')" v-html="form.errors.get('city_id').replace('validation.required', 'Le champs <b>Ville</b> est requis')" />
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<div class="form-group">
								<label for="zone_id">Zone/Quartier</label>
								<multiselect v-model="form.zone_id" :options="zones" :hide-selected="false"  placeholder="Quelle zone precisement?" label="name" track-by="id" @input="loadZones" class="text-primary"></multiselect>
								<div class="text-danger" v-if="form.errors.has('zone_id')" v-html="form.errors.get('zone_id').replace('validation.required', 'Le champs <b>Zone/Quartier</b> est requis')" />
							</div>
						</div>
					</div>
					<div class="row">
						
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="form-group">
								<label for="requester_number">Type de services</label>
								<input type="text" class="form-control form-control-lg" :value="servicetype" id="service_type" placeholder="" disabled>
							</div>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="form-group">
								<label for="service"> Service(s) concerne(s)</label>
								<multiselect v-model="form.service" :options="services" :hide-selected="false"  placeholder="Quelle est le service que vous desirez?" label="service_name" :multiple="true" track-by="id" class="text-warning" id="service"></multiselect>
								<div class="text-danger" v-if="form.errors.has('service')" v-html="form.errors.get('service').replace('validation.required', 'Le champs <b>Zone/Quartier</b> est requis')" />
							</div>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="form-group">
								<label for="zone_id">Votre besoin</label>
								<textarea class="form-control" rows="10" v-model="form.request_description" placeholder="Expliquez-nous un peu votre besoin ici..."></textarea>
								<div class="text-danger" v-if="form.errors.has('request_description')" v-html="form.errors.get('request_description').replace('validation.required', 'Le champs <b>Votre besoin</b> est requis')" />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form-group text-center">
							<button type="submit" :disabled="form.busy" class="btn btn-pill btn-outline-primary btn-air-light">{{ button }}</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	import Placement from '../placement.js';
	import Notify from '../../../mixins/notification.js';
	import EventBus from '../details/EventBus.js';

	export default {
		mixins: [Placement, Notify],
		props: ['type', 'button'],
		components:{
			Button, HasError, AlertError
		},
		data: function() {
			return {
				services:[],
				form: new Form({
					service:'',
					requestor_firstname:'',
					requestor_lastname:'',
					requestor_number:'',
					service_name:'',
					service_type: this.type,
					request_description:'',
					have_account:false,
					username:'',
					password:'',
				}),
				color: '#168EEA',
				active:true,
				message:'Dialog',
			}
		},
		computed: {
		  servicetype () {
		    return this.type.charAt(0).toUpperCase()+this.type.slice(1);
		  }
		},
		methods: {
			appropriateForm: function() {
				if(!this.form.have_account) {
					return this.form = new Form({
						service:this.form.service,
						requestor_firstname: this.form.requestor_firstname,
						requestor_lastname: this.form.requestor_lastname,
						requestor_number: this.form.requestor_number,
						service_name: this.form.service_name,
						service_type: this.type,
						city_id: this.form.city_id,
						zone_id: this.form.zone_id,
						request_description:this.form.request_description,
						have_account:false,
					});
				} else {
					return this.form = new Form({
						service:this.form.service,
						service_name: this.form.service_name,
						service_type: this.type,
						request_description:this.form.request_description,
						have_account:true,
						username:this.form.username,
						password:this.form.password,
					});
				}
			},
			saveRequest: function(){
				const loading = this.$vs.loading({type:'square', color: this.color});
				let uri = "/api/services";
				this.appropriateForm();
				this.form.post(uri).then(response => {
						loading.close();
						(response.data.success)? this.form.reset():null;
						(response.data.success)? EventBus.emit('new-special-request-submitted'):null;
						//this.message = response.data.message;
						
						var content = {};

					 	content.title = '';
						content.message = '<i class="fa fa-bell-o"></i><strong>'+response.data.message;
						this.notify(content, 10000, {from:'top', align:'right'}, {x:30, y:30}, null, (response.data.success)? 'success': 'danger');

				}).catch(err => {
					loading.close();
				});
			},
			closeDialog: function() {
				this.active=false;
			},
			loadServices: function() {
				let uri = "/api/services/"+this.form.service_type;
				axios.get(uri).then(response => {
					 this.services = response.data;
				});
			},
			loadZones: function() {
				let uri = "/api/city-zones";
				axios.post(uri, {city: this.form.city_id.id}).then(response => { this.zones = response.data});
			}
		},

		created: function() {
			this.loadServices();
		}
	}
</script>
<style>
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #168eea;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

</style>