<template>
	<div>
		<div class="row starter-main">
			<form @submit.prevent="configPharmacy">
				<div class="card">
					<div class="card-body">
						<div class="card" style="border-radius: 10px;">
							<div class="card-body p-3">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group">
					                    	<label for="name">Choix du partenaire</label>
					                    	<multiselect v-model="account_id" :options="Partners" :hide-selected="true" placeholder="Selectionner le partenaire" label="name" track-by="id"></multiselect>
					                    </div>
									</div>
									<div class="col-sm-5">
										<div class="mb-2">
					            			<label>Attributs</label>
					            		</div>
					            		<div class="form-group">
					                        <div class="row">
					                          <div class="col-sm-2">
					                          	<label class="switch">
						                            <input type="checkbox" v-model="have_variation" checked=""><span class="switch-state"></span>
						                        </label>
					                          </div>
					                          <div class="col-sm-6">
					                          	<label class="text-left">Configuration de Produit avec variation</label>
					                          </div>
					                        </div>
					                    </div>
									</div>
								</div>
								<div class="row p-3" style="border:1px solid rgb(231, 231, 231); border-radius: 5px; background-color: whitesmoke; margin-left: 4px; margin-right: 4px;" v-if="have_variation">
				            		<div class="col-sm-6">
				            			<div class="form-group">
					                    	<multiselect v-model="product" :options="Products" :preserve-search="true" placeholder="choisissez le produit" label="product_name" track-by="id" @input="showName">
			  								</multiselect>
					                    </div>
										<div class="form-group">
											<textarea v-model="custom_description" id="" rows="4" class="form-control form-control-lg" placeholder="Modifier la description"></textarea>
										</div>
					            	</div>
					            	<div class="col-sm-6">
										<div class="form-group">
											<input type="text" class="form-control form-control-lg" v-model="product_custom_name" placeholder="Modifier le nom">
										</div>
					            		<div class="form-group">
					            			<input type="number" v-model="product_price" class="form-control form-control-lg" placeholder="Entrer le prix">
					            		</div>
										<div class="form-group">
					                    	<multiselect v-model="addLines" :options="variations" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="choisissez les variations" label="variationName" track-by="id">
			  								</multiselect>
					                    </div>
					            	</div>
					            	<!-- <div class="col-sm-4">
					            		<div class="form-group">
					                    	<multiselect v-model="addLines" :options="variations" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="choisissez les variations" label="variationName" track-by="id">
			  								</multiselect>
					                    </div>
					            	</div> -->
					            	<div class="col-12 p-4">
					            		<div v-for="(addTd, index) in addLines" :key="index">
					            			<div class="row pt-4 pb-0 mb-2" style="border-radius: 10px; background-color: white;">
						            			<div class="col-sm-3">
						            				<div class="form-group">
						            					<input type="text" class="form-control" :value="addTd.variationName" disabled>
						            				</div>
						            			</div>
						            			<div class="col-sm-8">
						            				<div class="form-group">
						            					<multiselect v-model="addTd.option" :options="addTd.options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="choisissez les variations" :preselect-first="false">
				  										</multiselect>
						            				</div>
						            			</div>
						            			<div class="col-sm-1">
						            				<span style="cursor: pointer;" class="cursor-pointer text-danger align-middle" v-on:click="deleteLine(index,addTd)"><feather type="x-circle" size="24"></feather></span>
						            			</div>
						            		</div>
					            		</div>
					            		<button class="btn btn-light text-muted" style="background-color: white !important;" type="button" @click="combinations">generate combinations</button>
					            	</div> 
					            	<div class="col-lg-6 col-sm-12 mt-3">
										<div class="form-group">
											<label for="check-box">Ce produit est-il delivre sous prescription medical?</label>
											<div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
					                          <div class="radio radio-primary">
					                            <input id="radioinline1" type="radio" v-model="is_under_prescription" value="true">
					                            <label class="mb-0" for="radioinline1">Oui</label>
					                          </div>
					                          <div class="radio radio-primary">
					                            <input id="radioinline2" type="radio" v-model="is_under_prescription" value="false">
					                            <label class="mb-0" for="radioinline2">Non</label>
					                          </div>
					                        </div>
										</div>
									</div>
				            	</div>
							</div>
						</div>
						<div class="card" style="border-radius: 10px;" v-if="have_variation == false">
							<div class="card-body">
								<div class="row">
									<!-- <div class="col-sm-1"></div> -->
									<div class="col-sm-12">
										<div class="table-responsive">
											<table class="table table-bordered">
												<thead>
													<tr>
														<!-- <th></th> -->
														<th>Produit</th>
														<th>Quantite</th>
														<!-- <th>Prix</th> -->
														<th>Image</th>
														<th colspan="2">Sous presc?</th>
														<!-- <th class="align-middle">
															<span style="cursor: pointer;" class="addRow text-success" @click="addRow"><feather type="plus-circle" size="20"></feather></span>
														</th> -->
													</tr>
												</thead>
												<tbody>
													<tr v-for="(addTd, index) in addRows" :key="index" class="align-middle">
														<!-- <td class="align-middle"><b>{{ index }}</b></td> -->
														<td style="width:37%;">
															<multiselect v-model="addTd.product" :options="Products" :hide-selected="true" placeholder="Produit" label="product_name" track-by="id" @input="verifyProduct(addTd)"></multiselect>
															<input type="text" class="form-control form-control-lg mt-2" v-model="addTd.custom_product_name" placeholder="Nom personalise du produit">
														</td>
														<td class="align-middle" style="width:13%;">
															<input type="number" class="form-control form-control-lg" v-model="addTd.quantity" placeholder="Quantite">
															<input type="number" class="form-control form-control-lg mt-2" v-model="addTd.price" placeholder="Prix">
														</td>
														<!-- <td class="align-middle" style="width:13%;">
															<input type="number" class="form-control form-control-lg" v-model="addTd.price">
														</td> -->
														<td class="align-middle" style="width:30%;">
															<input type="file" name="image" :id="index" id="image+index" @change="onFileChange($event,addTd)" class="inputfile text-center">
															<label for="image+index"><feather type="upload" size="18"></feather> Image</label>
															<textarea v-model="addTd.custom_description" class="form-control mt-2" id="description" rows="2" placeholder="Nouvelle description du produit"></textarea>
														</td>
														<td class="align-middle" style="width: 15%;">
															
									                        
								                        <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
								                          <div class="radio radio-primary">
								                            <input id="radioinline1" type="radio" v-model="addTd.is_under_prescription" value="true">
								                            <label class="mb-0" for="radioinline1">Oui</label>
								                          </div>
								                          <div class="radio radio-primary">
								                            <input id="radioinline2" type="radio" v-model="addTd.is_under_prescription" value="false">
								                            <label class="mb-0" for="radioinline2">Non</label>
								                          </div>
								                        </div>
														</td>
														<td class="align-middle">
															<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteRow(index,addTd)"><feather type="trash-2" size="20"></feather></span>
														</td>
													</tr>
													<tr>
														<td colspan="6"><button class="btn btn-outline-light addRow" type="button" @click="addRow"><feather type="plus" size="20" class="align-middle"></feather> Nouvelle ligne</button></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card" style="border-radius: 10px;" v-if="have_variation == true">
							<div class="card-body">
								<div class="row">
									<!-- <div class="col-sm-1"></div> -->
									<div class="col-sm-12">
										<div class="table-responsive">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th></th>
														<th>Combinaison</th>
														<th>Qte</th>
														<th>Prix</th>
														<th>Image</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(addTd, index) in addCombs" :key="index">
														<td class="align-middle"><b>{{ index }}</b></td>
														<td style="width: 30%;" class="align-middle">
					            							<b> {{ product_name }}-{{ addTd.combinName }}</b>
					            							<input type="hidden" v-model="addTd.combinName">
					            						</td>
					            						<td style="width: 15%;" class="align-middle">
					            							<input type="number" v-model="addTd.quantity" class="form-control">
					            						</td>
					            						<td style="width: 15%;" class="align-middle">
					            							<input type="number" v-model="addTd.price" class="form-control">
					            						</td>
					            						<td class="align-middle" style="width: 30%;">
															<input type="file" name="image" :id="index" @change="onFileChange($event,addTd)">
														</td>
					            						<td class="align-middle">
					            							<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteCombinRow(index,addTd)">
					            								<feather type="x-circle" size="16"></feather>
					            							</span>
					            						</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer p-3 text-end">
                      <button class="btn btn-primary" type="submit">Enregistrer les products</button>
                      <input class="btn btn-light" type="reset" value="Annuler">
					</div>
				</div>
			</form>
		</div>
		<vs-dialog prevent-close width="400px" center v-model="active">
	        <template #header>
	          <h4 class="not-margin">
	            Alert <b>Info</b>
	          </h4>
	        </template>


	        <div class="con-content">
	         
	          	<p>{{ message }}</p>
	          	<span v-html="combins">
	          		
	          	</span>
	        </div>

	        <template class="text-end">
	          <div class="text-center align-middle">
	            <vs-button @click="closeDialog" transparent>
	              Fermer
	            </vs-button>
	          </div>
	        </template>
	    </vs-dialog>
	</div>
</template>
<script>
	// import { vsButton, vsSelect, vsPopup, vsDialog, } from 'vuesax';
 //  	import 'vuesax/dist/vuesax.css';
	
	export default {
		props: {
			Partners: Array,
			Products: Array,
		},
		data: function() {

			return {
				addRows:[],
				addLines:[],
				account_id:'',
				partner_product_id:'',
				product:'',
				product_name:'',
				product_price:"",
				product_custom_name:null,
				custom_description:null,
				have_variation:false,
				is_under_prescription: false,
				addCombs:[],
				variations:[],
				Options:[],
				active:false,
				combins:'',
				message:'',
				exists:false,

			}
		},
		methods: {
			verifyProduct: function(addTd) {
				console.log(addTd);
				//var product = addTd.product.id;
				this.checkProduct(addTd);
			},
			checkProduct: function(addTd = null, product = null) {
				var product = (addTd != null)? addTd.product.id:product;
				let uri = "/admin/configurations/verify-product";
				axios.post(uri, {product:product, account_id: this.account_id.id}).then(response => {
					this.exists = response.data.exists;
					if(this.exists) {
						(addTd != null)? addTd.product = []: null;
						var combinations = response.data.combinations;
						this.product_price = response.data.product.price;
						this.partner_product_id = response.data.product.id;

						if(response.data.have_variation) {
							this.message = "Ce produit existe deja au niveau du point de vente et possede des variations.";
							this.combins = "<p class='text-primary'>Ses combinaisons sont les suivantes: </p>";
							this.combins = this.combins+"<ol>"

							Object.keys(combinations).forEach(key => {
								this.combins = this.combins+ "<li><b>"+combinations[key].combinationName+"</b></li>";
							});
							this.combins = this.combins+"</ol>";
						} else {
							this.combins = "";
							this.product_price = ""; this.partner_product_id = "";
							this.message = "Ce produit existe deja au niveau du point de vente. Vous pouvez vous Rendre au niveau des approvisionnements pour ajouter du stock.";
						}
					}

					this.active = (response.data.exists)? true:false;
				});
			},
			closeDialog: function() {
				this.active = !this.active;
				this.combins = [];
			},
			addRow() {
		        this.addRows.push({
		            product:null, custom_product_name:null, quantity:null, price:null, image:null, is_under_prescription:false, custom_description:null
		        });
	        },
	        addComb() {
		        this.addCombs.push({
		            combinName:null, quantity:null, price:null, image: null
		        });
	        },
	        addLine() {
		        this.addLines.push({
		            variationName:null, option:null,
		        });
	        },
	        deleteRow(index, addTd) {
	            var idx = this.addRows.indexOf(addTd);
	            if (idx > -1) {
	                this.addRows.splice(idx, 1);
	            }
	        },
	        deleteLine(index, addTd) {
	            var idx = this.addLines.indexOf(addTd);
	            if (idx > -1) {
	                this.addLines.splice(idx, 1);
	            }
	        },
	        deleteCombinRow(index, addTd) {
	            var idx = this.addCombs.indexOf(addTd);
	            if (idx > -1) {
	                this.addCombs.splice(idx, 1);
	            }
	        },
	        showName: function() {
	        	this.checkProduct(null, this.product.id);
	        	this.product_name = this.product.product_name;
	        },
	        onFileChange(e, row){
	        	//e.default();
	        	console.log(e);

              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                     row.image = reader.result;
                    }              
                     reader.readAsDataURL(file);
                } else {
                    alert('File size can not be bigger than 2 MB')
                }
            },

	        configPharmacy: function() {
	        	if(this.account_id) {
	        		const loading = this.$vs.loading({type:'points'});

	        		let uri = "/admin/configurations/pharmacies/store";
	        		var addLines = _.map(this.addLines, function (num) { return _.pick(num,'variationName', 'option')});
	        		var addCombs = _.map(this.addCombs, function (num) { return _.pick(num, "combinName", "quantity", "price", "image") });
	        		var addRows = _.map(this.addRows, function (num) { return _.pick(num, "product", "custom_product_name", "quantity", "price", "image", "is_under_prescription", "custom_description") });

	        		axios.post(uri, {
						datas:addLines, combinations:addCombs, pharmacies:addRows, have_variation:this.have_variation, 
						is_under_prescription: this.is_under_prescription, product:this.product, product_price:this.product_price, 
						product_custom_name:this.product_custom_name, description: this.custom_description, account_id:this.account_id, exist:this.exists, partner_product_id:this.partner_product_id}).then(response => {

	        			if(response.data.success) {
	        				this.addRows = []; 
	        				this.addLines = [];
	        				this.addCombs = []; 
	        				this.addRow(); 
	        				this.product = '';
	        				this.product_price = '';
	        				this.product_name = '';
	        				this.account_id = '';
	        				this.have_variation = false;
	        				loading.close();
	        			}

	        			Toast.fire({
		                    icon: response.data.success? 'success':'error',
		                    title: response.data.message
		                 });
	        		});

	        	} else {
	        		Toast.fire({
	                    icon: 'error',
	                    title: 'Vous devez selectionner un compte partenaire'
	                 });
	        	}
	        },
	        combinations: function() {
	        	const loading = this.$vs.loading({type:'points'});
				let uri = '/admin/products/build-combinations';
				var addLines = _.map(this.addLines, function (num) { return _.pick(num,'variationName', 'option')});
				axios.post(uri, {datas:addLines, price:this.product_price}).then(response => {
					//console.log(response.data);
					(response.data)? loading.close():null;
					this.addCombs = response.data.data;
				});
			},
	        loadVariations: function() {
	        	const loading = this.$vs.loading({type:'points'});
				let uri = '/admin/attributs/variations/variations-with-options';
				axios.get(uri).then(response => {
					(response.data)? loading.close():null;
					this.variations = response.data;
				});
			},
		},
		created: function() {
			this.loadVariations();
		},
		mounted: function() {
			this.addRow();
			console.log(this.Partners);
		}
	}
</script>
<style>
	.inputfile {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	.inputfile + label {
	    font-size: 0.9em;
	    width:100%;
	    font-weight: 500;
	    text-align:center;
	    vertical-align:middle;
	    color: black;
	    background-color: whitesmoke;
	    padding-top:2%;
	    padding-bottom:2%;
	    padding-left: 1%;
	    padding-right: 1%;
	    border-radius: 5px;
	    display: inline-block;
	}

	.inputfile:focus + label,
	.inputfile + label:hover {
	    background-color:none;
	    color: red;
	}
</style>