<template>
	<div>
		<div class="row starter-main">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Type de produit</label>
		                    	<select v-model="search.product_type" class="form-select digits" id="exampleFormControlSelect9" @change="loadCategories(search.product_type)">
		                          <option value="">Choisissez le type</option>
		                          <option value="pharmacy"> Produit de Pharmacie </option>
		                          <option value="restaurant"> Produit de Restaurant </option>
		                          <option value="supermarket">  Produit de Supermarche </option>
		                        </select>
		                        <HasError form="search" field="product_type" />
		                    </div>
						</div>
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Categories Parente</label>
		                    	<select v-model="search.category" class="form-select digits" id="exampleFormControlSelect9" @change="loadSubCategories(search.category)">
	                              <option value="">Categories parente</option>
	                              <option v-for="item in categories" :key="item.id" :value="item.id"> {{ item.name }} </option>
	                            </select>
	                            <HasError form="search" field="category" />
		                    </div>
						</div>
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Sous Categories</label>
		                    	<select v-model="search.subcategory" class="form-select digits" id="exampleFormControlSelect9" @change="loadProducts">
	                              <option value="">Sous Categories</option>
	                              <option v-for="item in subCategories" :key="item.id" :value="item.id"> {{ item.name }} </option>
	                            </select>
	                            <HasError form="search" field="subcategory" />
		                    </div>
						</div>
						<div class="col-sm-3">
							<div class="form-group">
		                    	<label for="name">Nombre de ligne</label>
		                    	<select v-model="search.per_page" class="form-select digits" id="exampleFormControlSelect9" @change="loadProducts">
	                              <option value="">Nombre de ligne</option>
	                              <option v-for="(item, index) in pageCounts" :key="index" :value="item"> {{ item }} </option>
	                            </select>
	                            <HasError form="search" field="per_page" />
		                    </div>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Img</th>
									<th>Nom</th>
									<th>Type</th>
									<th>Categorie</th>
									<th>Sous-Categorie</th>
									<th>...</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in products.data" :key="index">
									<td>
										{{ item.id }}
									</td>
									<td>
										<div class="media">
											<img class="img-fluid border-light"  :src="basePath+'/frontsize/187x187/'+item.product_image"  width="40" height="40" alt="" style="border-radius: 100%;">
										</div>
									</td>
									<td class="align-middle">{{ item.product_name }}</td>
									<td class="align-middle">{{ item.product_type }}</td>
									<td class="align-middle">{{ item.category_name }}</td>
									<td class="align-middle">{{ item.subcategory_name }}</td>
									<td class="align-middle">
										<span style="cursor: pointer;" class="m-r-1" @click="showModal('edit-product', item)">
											<feather type="edit" size="13"></feather>
										</span>

                    					<span style="cursor: pointer;" class="m-r-1" @click="deleteProduct(item)">
                    						<feather type="trash-2" size="13"></feather>
                    					</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-3">
                    	<pagination :data="products" @pagination-change-page="loadProducts">
                    		<span slot="prev-nav">&lt; Precedent</span>
							<span slot="next-nav">Suivant &gt;</span>
                    	</pagination>
                    </div>
				</div>
			</div>
		</div>
		<modal name="edit-product" :resizable="true" :width="1200" :height="730" :clickToClose="false">
			<div slot="top-right">
		      <button @click="closeModal('edit-product')">
		        ❌
		      </button>
		    </div>
	        <form @submit.prevent="UpdateProduct('edit-product')" class="p-4" style="overflow-y:auto;">
	        	<div class="row">
					<div class="col-sm-8">
			            <div class="card" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Nom du produit</label>
			                    	<input v-model='createForm.product_name' class="form-control" type="text">
			                    	<HasError :form="createForm" field="product_name" />
			                    </div>
			                    <div class="form-group">
			                    	<label for="name">Description</label>
			                    	<ckeditor v-model="createForm.description" :config="editorConfig"></ckeditor>
			                    </div>
			            	</div>
			            </div>
						<div class="card" style="border-radius: 10px;">
							<div class="card-body">
								<div class="col-12">
									<button class="btn btn-secondary" type="button" @click="closeModal('edit-product')">Close</button>
									<button type="submit" :disabled="createForm.busy" class="btn btn-primary">Mettre a jours</button>
								</div>
							</div>
						</div>
			        </div>
			        <div class="col-sm-4">
			            <div class="card mb-3" style="border-radius: 10px;">
			            	<div class="card-body">
			            		<div class="form-group">
			                    	<label for="name">Type de produit</label>
			                    	<select v-model="createForm.product_type" class="form-select digits" id="exampleFormControlSelect9" @change="loadCategoriesList">
		                              <option value="">Choisissez le type</option>
		                              <option value="pharmacy"> Produit de Pharmacie </option>
		                              <option value="restaurant"> Produit de Restaurant </option>
		                              <option value="supermarket">  Produit de Supermarche </option>
		                            </select>
		                            <HasError form="createForm" field="product_type" />
			                    </div>
			            		<div class="form-group">
			                    	<label for="name">Categories Parente</label>
			                    	<select v-model="createForm.category_id" class="form-select digits" id="exampleFormControlSelect9" @change="loadSubCategoriesList">
		                              <option value="">Categories parente</option>
		                              <option v-for="item in categoriesList" :key="item.id" :value="item.id"> {{ item.name }} </option>
		                            </select>
		                            <HasError form="createForm" field="category_id" />
			                    </div>
			                    <div class="form-group">
			                    	<label for="name">Sous Categories</label>
			                    	<select v-model="createForm.sub_category_id" class="form-select digits" id="exampleFormControlSelect9">
		                              <option value="">Sous Categories</option>
		                              <option v-for="item in subCategoriesList" :key="item.id" :value="item.id"> {{ item.name }} </option>
		                            </select>
		                            <HasError form="createForm" field="sub_category_id" />
			                    </div>
			            	</div>
			            </div>
			            <div class="card mb-2" style="border-radius: 10px;">
			            	<div class="card-header pt-2 pb-2 bg-light text-dark">
			            		Image du produit
			            	</div>
			            	<div class="card-body">
			            		<!-- <div class="text-center" style="vertical-align: middle;" v-if="createForm.product_image">
									<img :src="getLogo()" width="25%" height="25%" />
									<p class="text-danger" @click="clearImage"><u>Supprimer cette image</u></p>
								</div> -->
			            		<!-- <input type="file" @change="uploadImage" id="logo" name="stationLogo" class="form-control" placeholder=""> -->
								<picture-input 
									ref="pictureInput"
									width="400" 
									height="200" 
									margin="0" 
									accept="image/jpeg,image/png" 
									size="10"
									z-index="0" 
									:prefill="getFilePath(createForm.product_image)"
									:prefill-options="{fileName: createForm.product_image, fileType:getFileType(createForm.product_image), mediaType: getMediaType(createForm.product_image)}"
									button-class="btn"
									:removable="true"
									:custom-strings="{
										upload: '<h1>Bummer!</h1>',
										drag: 'Selectionner une image',
										change: 'Changer',
										remove: 'Supprimer', 
									}"
									@change="onChange">
								</picture-input>
			            	</div>
			            </div>
			        </div>
				</div>
	        </form>
	    </modal>
    	<v-dialog />
	</div>
</template>
<script>
	import Form from 'vform';
	import { Button, HasError, AlertError } from 'vform/src/components/bootstrap5';
	import PictureInput from 'vue-picture-input';

	export default {
		components:{
			Button, HasError, AlertError, PictureInput
		},
		data: function() {

			return {
				products:[],
				categories:[],
				categoriesList:[],
				subCategories:[],
				subCategoriesList:[],
				filepath:'',
				basePath: Laravel.baseUrl+'medias/product_image/',
				search: new Form({
					product_type:'',
					per_page: 10,
					category:'',
					subcategory:'',
				}),
				createForm: new Form({
					id:'',
					product_name:'',
					product_type:'',
					category_id:'',
					sub_category_id:'',
					category_name:'',
					subcategory_name:'',
					description:'',
					product_image:'',
				}),
				pageCounts: [1,5,10,15,20,30,40,50,60,70,80,90,100],
				editorConfig:{}
			}
		},

		methods: {
			getFilePath: function(file) {
				return this.basePath+file;
			},
			getFileType: function(file) {
				var array = file.split('.');
				return array[array.length-1];
			},
			getMediaType: function(file) {
				var ext = this.getFileType(file);
				return "image/"+ext;
			},
			onChange (image) {
                console.log('New picture selected!')
                if (image) {
                    console.log('Picture loaded.')
                    this.createForm.product_image = image
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            },
			showModal: function(name, datas){
				this.createForm.reset();
				//console.table(datas);
				datas? this.createForm.fill(datas): this.createForm.reset();
				this.$modal.show(name);
			},
			closeModal: function(name){
				this.$modal.hide(name);
			},
			clearImage: function() {
				this.createForm.product_image = '';
				$('#logo').val('');
			},
			uploadImage(e){

              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                    //console.log('RESULT', reader.result)
                     this.createForm.product_image = reader.result;
                    }              
                     reader.readAsDataURL(file);
                }else{
                    alert('File size can not be bigger than 2 MB')
                }
            },
			getLogo(){
               return (this.createForm.product_image.length > 100)? this.createForm.product_image:this.basePath+this.createForm.product_image;
            },
            UpdateProduct: function(modal) {
            	let uri = "/admin/products/update/"+this.createForm.id;
				this.createForm.put(uri).then(response => {
					Fire.$emit('AfterAction');
					response.data.success? this.$modal.hide(modal):null;
					response.data.success? this.createForm.reset():null;
					response.data.success? this.createForm.product_image = '' :null;
					
					Toast.fire({
	                    icon: response.data.success? 'success':'error',
	                    title: response.data.message
	                  });
				});
            },
            deleteProduct: function(item) {

				let uri = "/admin/products/delete/"+item.id;
				//this.form.fill(item);

				this.$modal.show('dialog', {
				  title: 'Suppression de produit',
				  text: 'Êtes-vous sûr de bien vouloir supprimer cet produit?',
				  buttons: [
				    {
				      title: 'Non',
				      handler: () => {
				        this.$modal.hide('dialog')
				      }
				    },
				    {
				      title: 'Oui',
				      handler: () => {
				        axios.delete(uri).then(response => {
				        	Fire.$emit('AfterAction');
				        	Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                });
			                this.$modal.hide('dialog'); 

				        });
				      }
				    }
				  ]
				});
			},
			loadProducts: function(page = 1) {
				let uri = "/admin/products/list?page=" +page;
				this.search.get(uri).then(response => {
					this.products = response.data;
				});
			},
			loadCategories: function() {
				this.loadProducts();
				let uri = '/admin/categories/by-product-type/'+this.search.product_type;
				axios.get(uri).then(response => {
					this.categories = response.data;
					//this.categoriesList = response.data;
				});
			},
			CategoriesList: function() {
				let uri = '/admin/categories/categories';
				axios.get(uri).then(response => {
					this.categoriesList = response.data;
				});
			},
			loadSubCategories: function() {
				this.loadProducts();
				let uri = "/admin/categories/subcategories/by-category-id/"+this.search.category;
				axios.get(uri).then(response => {
					this.subCategories = response.data;
					//this.subCategoriesList = response.data;
				});
			},
			SubCategoriesList: function() {
				let uri = '/admin/categories/subcategories';
				axios.get(uri).then(response => {
					this.subCategoriesList = response.data;
				});
			},
			loadCategoriesList: function() {
				let uri = '/admin/categories/by-product-type/'+this.createForm.product_type;
				axios.get(uri).then(response => {
					this.categoriesList = response.data;
				});
			},

			loadSubCategoriesList: function() {
				let uri = "/admin/categories/subcategories/by-category-id/"+this.createForm.category_id;
				axios.get(uri).then(response => {
					this.subCategoriesList = response.data;
				});
			},
		},

		created: function() {
			this.loadProducts();
			this.CategoriesList();
			this.SubCategoriesList();

			Fire.$on("AfterAction", () => {
				this.loadProducts();
			});
		}
	}
</script>