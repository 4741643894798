<template>
	<div>
		<div class="row starter-main">
			<form @submit.prevent="configFood">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-sm-4">
								<div class="form-group">
			                    	<label for="name">Choix du partenaire</label>
			                    	<multiselect v-model="account_id" :options="Partners" :hide-selected="true" placeholder="Selectionner le partenaire" label="name" track-by="id"></multiselect>
			                    </div>
							</div>
						</div>
						<div class="card" style="border-radius: 10px;">
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12">
										<div class="table-responsive">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th></th>
														<th>Produit</th>
														<th>Prix</th>
														<th colspan="2">Image</th>
														<!-- <th class="align-middle">
															<span style="cursor: pointer;" class="addRow text-success" @click="addRow"><feather type="plus-circle" size="20"></feather></span>
														</th> -->
													</tr>
												</thead>
												<tbody>
													<tr v-for="(addTd, index) in addRows" :key="index">
														<td class="align-middle"><b>{{ index }}</b></td>
														<td class="align-middle" style="width: 35%;">
															<multiselect v-model="addTd.product" :options="Products" :hide-selected="true" placeholder="Produit" label="product_name" track-by="id"></multiselect>
														</td>
														<td class="align-middle" style="width: 15%;">
															<input type="number" class="form-control form-control-lg" v-model="addTd.price">
														</td>
														<td class="align-middle" style="width: 40%;">
															<input type="file" name="image" :id="index" class="form-control form-control-lg" @change="onFileChange($event,addTd)">
														</td>
														<td class="align-middle">
															<span style="cursor: pointer;" class="cursor-pointer text-danger" v-on:click="deleteRow(index,addTd)"><feather type="trash-2" size="20"></feather></span>
														</td>
													</tr>
													<tr>
														<td></td>
														<td colspan="4"><button class="btn btn-outline-light addRow" type="button" @click="addRow"><feather type="plus" size="20" class="align-middle"></feather> Nouvelle ligne</button></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer p-3 text-end">
                      <button class="btn btn-primary" type="submit">Enregistrer les plats</button>
                      <input class="btn btn-light" type="reset" value="Annuler">
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
	
	export default {
		props: {
			Partners: Array,
			Products: Array,
		},
		data: function() {

			return {
				addRows:[],
				account_id:'',
			}
		},
		methods: {
			addRow() {
		        this.addRows.push({
		            product:null, price:null, image:null
		        });
	        },
	        deleteRow(index, addTd) {
	            var idx = this.addRows.indexOf(addTd);
	            if (idx > -1) {
	                this.addRows.splice(idx, 1);
	            }
	        },

	        configFood: function() {

	        	if(this.account_id) {
	        		let uri = "/admin/configurations/store";

		        	var addRows = _.map(
		        		this.addRows, 
		        		function(num){ 
		        			return _.pick(num, 'product', 'price', 'image') 
		        		});

		        	axios.post(uri, {foods:addRows, account_id:this.account_id}).then(response => {
		        			if(response.data.success) {
		        				this.addRows = []; this.addRow(); this.account_id = '';
		        			}
					
							Toast.fire({
			                    icon: response.data.success? 'success':'error',
			                    title: response.data.message
			                 });
		        	});
	        	} else {

	        		Toast.fire({
	                    icon: 'error',
	                    title: 'Vous devez selectionner un compte partenaire'
	                 });
	        	}
	        },
	        onFileChange(e, row){
	        	//e.default();
	        	console.log(e);

              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                     row.image = reader.result;
                    }              
                     reader.readAsDataURL(file);
                } else {
                    alert('File size can not be bigger than 2 MB')
                }
            },
		},
		created: function() {

		},
		mounted: function() {
			this.addRow();
			console.log(this.Partners);
		}
	}
</script>